/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { DeviceStatus } from "./device-status.model";
import { DeviceConfig } from "./device-config.model";
import { DeviceSystemInfo } from "./device-system-info.model";
import { Location } from "../location/location.model";
import { User } from "../users/user.model";
import { Team } from "../teams/team.model";

export class Device {
  public friendlyName: string;
  public thingName: string;
  public userId: string;
  public macAddress: string;
  public productFamily: string;
  public productFamilyKey: string;
  public productType: string;
  public lastSeen: Date;
  public errorMessage: string;
  public status: DeviceStatus[];
  public config: DeviceConfig[];
  public system: DeviceSystemInfo[];
  public location: Location;
  public teams: Team[];
  public sku: string;
}
