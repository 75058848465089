/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";

import { DevicesService } from "./devices.service";
import { DevicesListComponent } from "./components/devices-list.component";

import { DevicesComponent } from "./devices.component";
import { DeviceDetailsComponent } from "./device-details.component";
import { DeviceDetailsCopyComponent } from "./components/device-detail-copy";
import { ConfirmCommandDialogComponent } from "./components/confirm-command.dialog";
import { DeviceLogComponent } from "./components/device-log.component";
import { DeviceLogCommandsComponent } from "./components/device-log-commands.component";
import { DeviceLogErrorsComponent } from "./components/device-log-errors.component";
import { DeviceLogRunsComponent } from "./components/device-log-runs.component";
import { DeviceLogTelemetryComponent } from "./components/device-log-telemetry.component";
import { EditDeviceComponent } from "./edit-device.component";
import { DownloadCertsDialogComponent } from "./components/download-certs.dialog";
import { DeviceOverviewComponent } from "./components/device-overview.component";
import { ShadowEditorComponent } from "./components/shadow-editor.component";
import { ShadowComponent } from "./components/shadow.component";
import { DeviceMqttComponent } from "./components/device-mqtt.component";
import { DeviceDocumentsComponent } from "./components/device-documents.component";

@NgModule({
  declarations: [
    DevicesComponent,
    DeviceDetailsComponent,
    ConfirmCommandDialogComponent,
    DevicesListComponent,
    DeviceLogComponent,
    DeviceLogCommandsComponent,
    DeviceLogErrorsComponent,
    DeviceLogRunsComponent,
    DeviceLogTelemetryComponent,
    EditDeviceComponent,
    DownloadCertsDialogComponent,
    DeviceOverviewComponent,
    DeviceMqttComponent,
    ShadowEditorComponent,
    ShadowComponent,
    DeviceDetailsCopyComponent,
    DeviceDocumentsComponent,
  ],
  imports: [SharedModule],
  exports: [],
  providers: [DevicesService],
  entryComponents: [
    ConfirmCommandDialogComponent,
    DownloadCertsDialogComponent,
  ],
})
export class DevicesModule {}
