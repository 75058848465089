/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { LanguagesService } from "./languages.service";

import { ActivatedRoute, Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatSnackBar,
} from "@angular/material";
import { CountriesService } from "../countries.service";

export interface DialogData {
  refreshGridFn: any;
  language: any;
}

@Component({
  selector: "app-languages",
  templateUrl: "./languages.component.html",
})
export class CmsLanguagesComponent implements OnInit {
  entity;

  isDisabled = true;

  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  constructor(
    public dialog: MatDialog,
    private languagesService: LanguagesService,
    public router: Router,
    public activeRoute: ActivatedRoute
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    // set table columns
    this.displayColumns = [
      "contentLanguageId",
      "contentLanguageTitle",
      "supported",
      "actions",
    ];

    // get data from server
    this.getData();
  }

  getData() {
    let errors;

    this.languagesService.getAllLanguages().subscribe(
      (result) => {
        // Handle result
        const items: any = result as any;
        this.dataSource = new MatTableDataSource(items);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );
  }

  delete(event, language) {
    const dialogRef = this.dialog.open(CmsDeleteLngDialogComponent, {
      width: "500px",
      data: {
        refreshGridFn: this.getData.bind(this), // refresh data.
        language: language,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  onStatusChange(entity) {
    entity.status = entity.status;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

@Component({
  selector: "app-cms-delete-lng-dialog",
  templateUrl: "delete-language-dialog.component.html",
})
export class CmsDeleteLngDialogComponent {
  language: any;

  constructor(
    public dialogRef: MatDialogRef<CmsDeleteLngDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private languagesService: LanguagesService,
    public snackBar: MatSnackBar
  ) {
    this.language = this.data.language;
  }

  cancel() {
    this.dialogRef.close();
  }

  delete() {
    this.languagesService.delete(this.language.contentLanguageId).subscribe(
      (data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          // show notification
          this.snackBar.open("Language was deleted successfully.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });

          // refresh grid
          this.data.refreshGridFn();
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to delete: " + data.reason
          );
        }
      },
      (error) => {
        // show notification
        this.snackBar.open(error.statusText, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });
        console.error(
          "An error occurred while attempting to delete: " + error.statusText
        );
      }
    );

    this.dialogRef.close();
  }
}
