/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LabelsService } from "./labels.service";
import { LanguagesService } from "../languages/languages.service";
import { LabelElement } from "./labels.component";
import { MatSnackBar } from "@angular/material";

@Component({
  selector: "app-cms-edit-label",
  templateUrl: "./edit-label.component.html",
})
export class CmsEditLabelComponent implements OnInit {
  isNew: boolean;
  label: any;
  placeholderLabel: string;

  // populate language dropdown
  languages: string[];

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public labelsService: LabelsService,
    public languagesService: LanguagesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      // determine if creating a new label or editing an existing one
      const token = params["token"];
      const defaultLanguage = params["language"];
      this.isNew = token ? false : true;

      // get languages
      this.getLanguages();

      if (this.isNew) {
        this.label = {
          id: "",
          token: "",
          languageCode: defaultLanguage, // set default dropdown value
          "en-us": "",
          nonEnglishStringValue: "",
        };
      } else {
        this.setExistingLabel(token, defaultLanguage);
      }
    });
  }

  getLanguages() {
    let errors;

    this.languagesService.getAllLanguages().subscribe(
      (result) => {
        // populate languages dropdown
        this.languages = result as any;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      }
    );
  }

  setExistingLabel(token: string, defaultLanguage: string) {
    this.labelsService.getLabel(token).subscribe((data: any) => {
      /* Expected format
          {
            id: id,
            token: '',
            languageCode: '',
            en-us: '',
            nonEnglishStringValue: '',
          }
        */

      // do some transformation
      this.label = data;
      this.label.languageCode = defaultLanguage;
      this.label.nonEnglishStringValue = data[defaultLanguage];
      this.label["en-us"] = data["en-us"];
    });
  }

  setNonEnglishStringValue(language) {
    if (this.isNew) {
      if (language === "en-us") {
        this.label.nonEnglishStringValue = ""; // if going back to just english - clear field
      }
    } else {
      // set non english value
      this.label.nonEnglishStringValue = this.label[language]
        ? this.label[language]
        : "";
    }
  }

  selectedLanguageIsEnglish() {
    return this.label.languageCode === "en-us";
  }

  isValid(label) {
    return label && label.token && label.languageCode && label["en-us"];
  }

  save(close) {
    // check required fields
    if (!this.isValid(this.label)) {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });

      return;
    }

    if (this.label.languageCode === "en-us") {
      this.label[this.label.languageCode] = this.label[this.label.languageCode];
    } else {
      this.label[this.label.languageCode] = this.label.nonEnglishStringValue;
    }
    this.labelsService
      .addLabel(this.label, this.isNew)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          const msg = this.isNew
            ? "Label was created successfully"
            : "Label updated successfully";

          // show notification
          this.snackBar.open(msg, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });
          if (close) {
            this.router.navigate(["dashboard/cms/labels"]);
          } else {
            if (this.isNew) {
              this.isNew = false; // After saving a new lable, negate the setting
            }
          }
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to save: " + this.label.token
          );
        }
      });
  }

  cancel() {
    this.router.navigate(["dashboard/cms/labels"]);
  }
}
