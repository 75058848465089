/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";

import { JobsComponent } from "./jobs.component";
import { JobsService } from "./jobs.service";

@NgModule({
  declarations: [JobsComponent],
  imports: [BrowserModule, SharedModule],
  exports: [],
  providers: [Title, JobsService],
  entryComponents: [],
})
export class JobsModule {}
