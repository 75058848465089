/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatTableDataSource,
  MatSort,
  MatDialog,
  MatPaginator,
} from "@angular/material";
import { DeleteConfirmDialogComponent } from "../../shared/dialogs/delete-confirm.dialog";
import { EditWhitelistDeviceComponent } from "./edit-whitelist-device-dialog/edit-whitelist-device.dialog";
import { WhitelistDeviceService } from "./whitelist-devices.service";
import { WhitelistDevice } from "../../core/models/whitelist-devices/whitelist-device.model";
import { ToastHelperService } from "../../shared/utility/toast-helper.service";
import { UserService } from "../users/users.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "whitelist-devices",
  templateUrl: "./whitelist-devices.component.html",
})
export class WhitelistDevicesComponent implements OnInit {
  public whitelistDevices: WhitelistDevice[] = [];
  public whitelistDevicesSource: MatTableDataSource<WhitelistDevice>;
  public columnsToDisplay: string[] = [
    "duid",
    "serialNumber",
    "macAddress",
    // "duidPrefixOverride",
    "sku",
    // "ssid",
    "receivedOn",
    // "isManufacturingRelease",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public lastEvaluatedKey: string;
  public defaultPageSize: string = "50";
  public searchString: string;

  constructor(
    private dialog: MatDialog,
    private toastHelper: ToastHelperService,
    private userService: UserService,
    private whitelistDeviceService: WhitelistDeviceService,
    private titleService: Title
  ) {}

  public ngOnInit() {
    this.userService.getSelf().subscribe(() => {
      if (this.userService.curentUserHasPermissions(["WhitelistEdit"]))
        this.columnsToDisplay.push("actions");
    });

    this.titleService.setTitle("Whitelist Devices");

    this._reloadDeviceWhitelist();
  }

  public addOrEdit(device: WhitelistDevice = null) {
    const dialogRef = this.dialog.open(EditWhitelistDeviceComponent, {
      data: {
        device: device,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.duid) {
        // Edit the entry in our dataset
        const idxToEdit = this.whitelistDevices.findIndex(
          (clientDevice: WhitelistDevice) =>
            clientDevice.duid && clientDevice.duid === result.duid
        );

        if (idxToEdit > -1) this.whitelistDevices[idxToEdit] = result;
        else this.whitelistDevices.push(result);

        this._resetDataSource();
      }
    });
  }

  public applyFilter(filterValue: string) {
    this.whitelistDevicesSource.filter = filterValue.toLowerCase();
    if (this.whitelistDevicesSource.paginator) {
      this.whitelistDevicesSource.paginator.firstPage();
    }
  }

  public clearFilter() {
    this.whitelistDevicesSource.filter = null;
  }

  public delete(device: WhitelistDevice) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {
        message:
          "Are you sure you want to delete this device? This action cannot be undone.",
        title: "Confirm deletion",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._onConfirmDelete(device);
      }
    });
  }

  private _comparitor(data: WhitelistDevice, filter: string): boolean {
    return (
      (data.duid && data.duid.toLowerCase().includes(filter)) ||
      (data.serialNumber && data.serialNumber.toLowerCase().includes(filter)) ||
      (data.macAddress && data.macAddress.toLowerCase().includes(filter)) ||
      (data.duidPrefixOverride &&
        data.duidPrefixOverride.toLowerCase().includes(filter)) ||
      (data.sku && data.sku.toLowerCase().includes(filter)) ||
      (data.ssid && data.ssid.toLowerCase().includes(filter))
    );
  }

  /**
   * Finalizes the delete action for the specified device.
   * @param device Device to delete
   */
  private _onConfirmDelete(device: WhitelistDevice) {
    this.whitelistDeviceService.delete(device.duid).subscribe(
      (data) => {
        this.toastHelper.showSuccess(
          `Device with duid ${device.duid} has been deleted.`
        );

        // Delete the item from our local list
        const idxToDelete = this.whitelistDevices.findIndex(
          (clientDevice: WhitelistDevice) =>
            clientDevice.duid && clientDevice.duid === device.duid
        );

        if (idxToDelete > -1) {
          this.whitelistDevices.splice(idxToDelete, 1);
          this._resetDataSource();
        }
      },
      (error) =>
        this.toastHelper.showError(
          error.message,
          `Failed to delete device with duid ${device.duid}.`
        )
    );
  }

  /**
   * Reloads the list of whitelist devices and resets the filter.
   */
  private _reloadDeviceWhitelist() {
    this.whitelistDeviceService
      .getAll(this.lastEvaluatedKey, this.defaultPageSize, null)
      .subscribe((result) => {
        this.whitelistDevices = result.response;
        this.lastEvaluatedKey = result.lastEvaluatedKey;
        this._resetDataSource();
      });
  }

  /**
   * Resets the whitelist data source to its original state using a current list of devices.
   * @param whitelistDevices Devices to display in the table
   */
  private _resetDataSource() {
    this.whitelistDevicesSource = new MatTableDataSource(this.whitelistDevices);
    this.whitelistDevicesSource.paginator = this.paginator;
    this.whitelistDevicesSource.sort = this.sort;
    this.whitelistDevicesSource.filter = null;
    this.whitelistDevicesSource.filterPredicate = this._comparitor;
  }

  public loadMoreResults() {
    this._reloadDeviceWhitelist();
  }
  public resetResults() {
    this.lastEvaluatedKey = null;
    this._reloadDeviceWhitelist();
  }

  public fetchedFilteredData(filterValue: string) {
    this.searchString = filterValue;
    this.whitelistDeviceService.getAll(null, null, this.searchString).subscribe(
      (result) => {
        this.whitelistDevices = result.response;
        this.lastEvaluatedKey = result.lastEvaluatedKey;
        this._resetDataSource();
      },
      (err) => {
        this.toastHelper.showError("Error", err.statusText);
      }
    );
  }
}
