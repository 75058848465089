/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { SearchAttribute } from "./search-attribute.model";

export class SearchTerm {
  public attribute: SearchAttribute;
  public value: string;

  constructor(attribute?, value?) {
    this.attribute = attribute;
    this.value = value;
  }
}
