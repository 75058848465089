/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Routes } from "@angular/router";

import { ConsumerCareComponent } from "./consumercare.component";
import { ConsumerCarePairingErrorComponent } from "./pairingerrors/pairingerrors.component";
import { ConsumerCareSupportComponent } from "./support-contact/support-contact.component";
import { DevicesRoutes } from "../devices/devices.routes";

export const ConsumerCareRoutes: Routes = [
  {
    path: "consumercare",
    component: ConsumerCareComponent,
    children: [
      { path: "pairingerrors", component: ConsumerCarePairingErrorComponent },
      { path: "intnlsupport", component: ConsumerCareSupportComponent },
      ...DevicesRoutes,
    ],
  },
];
