/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ReleaseService } from "./release.service";
import { Release } from "../../core/models/buildmatrix/release.model";
import { MatPaginator, MatTableDataSource, MatSort } from "@angular/material";

enum PipelineTypes {
  PLATFORM = "platform",
  MOBILE = "mobile",
  FIRMWARE = "firmware",
}

@Component({
  selector: "release",
  templateUrl: "./release.component.html",
  // styleUrls: [ './release.component.scss' ]
})
export class ReleaseComponent implements OnInit {
  public release: Release[] = [];
  public dataSource: any;
  public displayColumns: string[];

  public searchableAttributes = [
    { displayName: "Type", name: "type" },
    { displayName: "Sub Type", name: "subtype" },
  ];
  public lastEvaluatedKey: string;

  constructor(
    private releaseService: ReleaseService,
    private titleService: Title
  ) {}
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public ngOnInit() {
    this.titleService.setTitle("Release");
    this.displayColumns = [
      "type",
      "subtype",
      "versionNumber",
      "buildNumber",
      "releaseDate",
      "isReleased",
      "isForceUpdateRequired",
      "actions",
    ];
    this.releaseService.getAll().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
