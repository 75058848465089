/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { ToastHelperService } from "../../shared/utility/toast-helper.service";
import { TeamService } from "./teams.service";
import { DevicesService } from "../devices/devices.service";
import { CreateTeam } from "../../core/models/teams/createTeam.model";

@Component({
  selector: "edit-team",
  templateUrl: "./edit-team.component.html",
})
export class EditTeamComponent implements OnInit {
  public model: CreateTeam = new CreateTeam();
  public isEditing: boolean;
  public submitting: boolean;

  private errorMessage;
  private lastUrl;
  private thingName: string;
  private userId: string;

  constructor(
    private teamService: TeamService,
    private devicesService: DevicesService,
    private toastHelper: ToastHelperService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    this.model.users = [];
    this.model.devices = [];
  }

  public ngOnInit() {
    this.titleService.setTitle("Edit Team");
    this.lastUrl = this.route.snapshot.queryParams["lastUrl"];
    this.userId = this.route.snapshot.queryParams["userId"];
    this.isEditing = !!this.model.teamId;
    this.thingName = this.route.snapshot.queryParams["thingName"];

    if (this.thingName) {
      this.teamService.getDevice(this.thingName).subscribe((device) => {
        this.model.devices.push(device);
      });
    }

    if (this.userId) {
      this.teamService.getUser(this.userId).subscribe((user) => {
        this.model.users.push(user);
      });
    }
  }

  public submit() {
    this.submitting = true;
    this.createTeam();
  }

  private createTeam() {
    if (!this.model.devices || this.model.devices.length <= 0) {
      this.onError("Incorrect input", "You must select at least one device");
      return;
    }

    if (!this.model.users || this.model.users.length <= 0) {
      this.onError("Incorrect input", "You must select at least one user");
      return;
    }

    return this.teamService.register(this.model).subscribe((result) => {
      this.onSuccess("Successfully created team");
    });
  }

  private onSuccess(message: string) {
    this.router.navigate([this.lastUrl]);
    this.toastHelper.showSuccess(message);
  }

  private onError(error: any, message: string) {
    this.toastHelper.showError(error, message);
    this.errorMessage = <any>error;
    this.submitting = false;
  }

  private getErrorMessage(error: any) {
    if (error.status === 409) {
      return `A team named '${this.model.teamName}' already exists`;
    }
    return "There was an error registering the team";
  }
}
