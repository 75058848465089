/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BhiProductsService } from "./bhi_products.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { DeleteConfirmDialogComponent } from "../../../shared/dialogs/delete-confirm.dialog";

@Component({
  selector: "app-cms-copy-product",
  templateUrl: "product-copy-sku-dialog.component.html",
})
export class CmsCopyProductComponent {
  product = {
    sku: "",
  };
  loadedData: any[];

  public sourceSku: string;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CmsCopyProductComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public bhiProductsService: BhiProductsService
  ) {
    this.sourceSku = this.data.sourceSku;
  }

  add() {
    // Input sku validation
    if (!this.product.sku) {
      console.error("SKU required.");
      return;
    }

    //check if sku already exist
    this.bhiProductsService.getBhiProduct(this.product.sku).subscribe(
      (result) => {
        //If sku exist: Show confirmation dialog
        const confirmDialogRef = this.dialog.open(
          DeleteConfirmDialogComponent,
          {
            data: {
              confirmButtonText: "Confirm",
              message:
                "Do you want to update existing SKU with this information?",
              title: "Confirm sku",
            },
          }
        );
        confirmDialogRef.afterClosed().subscribe((res) => {
          if (res) {
            //Update sku
            this.copy();
          }
        });
      },
      (err) => {
        if (err && err.error && err.error.code === 404) {
          //Sku does not exist, create new
          this.copy();
        }
      },
      () => {
        /** Callback */
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  copy() {
    this.bhiProductsService
      .copyBhiProduct(this.data.sourceSku, this.product.sku, true)
      .subscribe(
        (result) => {
          // Handle result
          if (result.status === "Error") {
            console.error("There was an error: " + result.reason);
          } else {
            // go to sku page
            this.router.navigate([
              "dashboard/cms/products/" + this.product.sku,
            ]);
          }
        },
        (error) => {
          error = error;
          console.log("There was an error in copy: " + error.message);
        },
        () => {
          /** Callback */
        }
      );
    // close modal
    this.dialogRef.close();
  }
}
