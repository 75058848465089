/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ReleaseService } from "./release.service";
import { Release } from "../../core/models/buildmatrix/release.model";
import { MatDialog, MatSnackBar } from "@angular/material";
import { ReleaseTypeOption, ReleaseTypeOptions } from "./release-type";
import { DeleteConfirmDialogComponent } from "../../shared/dialogs/delete-confirm.dialog";

@Component({
  selector: "edit-release",
  templateUrl: "./edit-release.component.html",
  styleUrls: ["./edit-release.component.scss"],
})
export class EditReleaseComponent implements OnInit {
  public availableJobManifests: string[];
  public fileName: string;
  public isEditing: boolean;
  public model: Release = new Release();
  public submitting: boolean;

  public id: string;
  public isNew: boolean;
  public isView: boolean;
  public localReleaseDate: Date = null;

  constructor(
    private releaseService: ReleaseService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}
  releaseTypes: ReleaseTypeOption[] = ReleaseTypeOptions;

  public ngOnInit() {
    this.id = this.route.snapshot.params["id"];
    this.isNew = this.id ? false : true;
    const operation = this.route.snapshot.params["op"];
    this.isView = operation == "view" ? true : false;
    this.isEditing = this.id ? true : false;

    if (this.id) {
      this.releaseService.getRelease(this.id).subscribe(
        (data: any) => {
          this.model = data;
          this.localReleaseDate = new Date(this.model.releaseDate);
        },
        (err) => {
          const msg =
            "An error occurred while attempting to load release: " + err.reason;
          this.snackBar.open(msg, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });
          console.error(msg);
        }
      );
    } else {
      this.model = new Release();
    }
  }

  submit() {
    if (this.model.isForceUpdateRequired) {
      const confirmDialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
        data: {
          confirmButtonText: "Confirm",
          message: `The change you are about to make will affect all mobile app users for ${this.model.subtype}. 
             This will force them to update to app version ${this.model.versionNumber} the next time they open it. 
             This is intrusive and should only be done in an emergency. 
             Are you sure you want to force ALL USERS to upgrade to this version?`,
          title: "Warning!",
        },
      });
      confirmDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.submitRelease();
        }
      });
    } else {
      this.submitRelease();
    }
  }

  submitRelease() {
    this.model.releaseDate = this.localReleaseDate.getTime();
    this.releaseService.addRelease(this.model).subscribe(
      (data: any) => {
        const msg = this.isNew
          ? "Release was saved successfully."
          : "Release was updated successfully.";
        this.snackBar.open(msg, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["success-snackbar"],
        });
        this.router.navigate(["/dashboard/release"]);
      },
      (err) => {
        let msg = this.isNew
          ? "Release could not be saved"
          : "Release could not be updated";
        msg += ":" + err.reason;
        this.snackBar.open(msg, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });
        console.error(msg);
      }
    );
  }
}
