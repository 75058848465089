/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { AppConfigService } from "../../../app.config.service";

const uuid = require("uuid");

/* CONSTANTS */
const SECONDS_IN_HOUR: number = 3600;
const SECONDS_IN_MINUTE: number = 60;

@Injectable()
export class OutageService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getAllOutageNotifications(): Observable<any> {
    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL + "/outage"}`
    );
  }

  public getOutageNotificationById(id: string): Observable<any> {
    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL + "/outage"}/${id}`
    );
  }

  public putOutageNotification(entity: any): Observable<any> {
    const [outageHour, outageMinute] = entity.outageTime
      ? entity.outageTime.split(":")
      : [0, 0];

    let outageEpoch =
      (outageHour * SECONDS_IN_HOUR + outageMinute * SECONDS_IN_MINUTE) * 1000;
    outageEpoch += new Date(entity.outageDate).getTime();

    // set outageId if this is not present it must be new entry
    const body = {
      outageId: entity.outageId ? entity.outageId : uuid(),
      outageDate: outageEpoch,
      outageResolvedDate: entity.outageResolvedDate
        ? entity.outageResolvedDate
        : null,
      description: entity.description ? entity.description : null,
      cCMessaging: entity.cCMessaging ? entity.cCMessaging : null,
      impact: entity.impact ? entity.impact : null,
      relatedTasks: entity.relatedTasks ? entity.relatedTasks : null,
      incidentStatus: entity.incidentStatus ? entity.incidentStatus : null,
    };
    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/outage"}`,
      body
    );
  }

  public sendOutageNotificationByEmail(entity: any): Observable<any> {
    const body = {
      cCMessaging: entity.cCMessaging ? entity.cCMessaging : null,
      description: entity.description ? entity.description : null,
      impact: entity.impact ? entity.impact : null,
      incidentStatus: entity.incidentStatus ? entity.incidentStatus : null,
      outageDate: entity.outageDate ? entity.outageDate : null,
      outageResolvedDate: entity.outageResolvedDate
        ? entity.outageResolvedDate
        : null,
      relatedTasks: entity.relatedTasks ? entity.relatedTasks : null,
    };
    return this.http.post<any>(
      `${this.appConfig.getConfig().portalApiURL + "/outage"}`,
      body
    );
  }
}
