/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { ToastHelperService } from "../../shared/utility/toast-helper.service";
import { FirmwareService } from "./firmware.service";
import {
  FirmwareUploadResponse,
  FwValidationResponse,
} from "../../core/models/firmware/firmware.model";
import { finalize } from "rxjs/operators";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "create-firmware",
  templateUrl: "./create-firmware.component.html",
  styleUrls: ["./create-firmware.component.scss"],
})
export class CreateFirmwareComponent implements OnInit {
  public availableJobManifests: string[];
  private errorMessage: string;
  public fileName: string;
  public isEditing: boolean;
  public isLoadingManifests: boolean = false;
  public projects: {
    baseSku: string;
    projectNumber: string;
    displayName: string;
  }[] = [];
  public selectedProjectNumber: string = "";
  public submitting: boolean;
  public error: string;
  public dragAreaClass: string;
  public model: Map<
    File,
    {
      validationResponses: FwValidationResponse[];
      uploadResponses: FirmwareUploadResponse[];
    }
  > = new Map();
  public filesSaved: boolean = false;
  public firmwareInvalid: boolean = true;

  constructor(
    private firmwareService: FirmwareService,
    private titleService: Title,
    private toastHelper: ToastHelperService
  ) {}

  public getFileNames(): string[] {
    return Array.from(this.model.keys()).map((f) => {
      return f.name;
    });
  }

  public getFiles(): File[] {
    return Array.from(this.model.keys());
  }

  public ngOnInit() {
    this.firmwareService.getProjects().subscribe((data) => {
      this.projects = data;
    });
    this.titleService.setTitle("Create Firmware");
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  public onDropSuccess(event: DragEvent) {
    event.preventDefault();
    this.onNewFiles(Array.from(event.dataTransfer.files));
  }

  public onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = Array.from(target.files);
    this.onNewFiles(files);
  }

  public onNewFiles(files: File[]) {
    this.error = "";
    console.log(files[0].size, files[0].name, files[0].type);
    for (const file of files) {
      this.model.set(file, { validationResponses: [], uploadResponses: [] });
    }
    console.log(files);
    this.filesSaved = true;

    this.validateFirmware();
  }

  public submit() {
    this.errorMessage = "";
    this.submitting = true;
    this.createFirmware();
  }

  public onClear() {
    this.model.clear();
  }

  private handleUploadResponse(
    file: File,
    responses: FirmwareUploadResponse[]
  ) {
    this.model.get(file).validationResponses = [];
    this.model.get(file).uploadResponses = responses;

    for (const response of responses) {
      if (!response.success && response.errorMessage) {
        this.firmwareInvalid = true;
      }
    }
  }

  private async createFirmware() {
    for (const firmwareFile of Array.from(this.model.keys())) {
      this.firmwareService
        .save(firmwareFile.name, this.selectedProjectNumber)
        .pipe(finalize(() => (this.submitting = false)))
        .subscribe(
          (data) => {
            this.handleUploadResponse(firmwareFile, data);
          },
          (err) => {
            let errorMessage: string = "Firmware save failed.";
            let toastMessage: string =
              "There was an error creating the firmware.";
            this.onError(errorMessage, toastMessage);
          }
        );
    }
  }

  public onProjectNumberChange(event: Event) {
    this.validateFirmware();
  }

  private handleValidationResponse(
    file: File,
    responses: FwValidationResponse[]
  ) {
    this.model.get(file).validationResponses = responses;

    for (const response of responses) {
      if (!response.isValid) {
        this.firmwareInvalid = true;
      }
    }
  }

  private validateFirmware() {
    if (!this.selectedProjectNumber) {
      this.onError("Please select a product", "No Product selected");
      return;
    }
    const files = Array.from(this.model.keys());
    if (files.length === 0) {
      return;
    }
    this.firmwareInvalid = false;

    for (const firmwareFile of files) {
      if (!firmwareFile) {
        continue;
      }
      this.model.get(firmwareFile).validationResponses = [];
      this.errorMessage = "";
      // 1. Obtain a presigned URL for each file and upload to the validation folder
      this.firmwareService
        .getUploadUrlForValidation(firmwareFile)
        .subscribe((url) => {
          console.log("DEBUG S3 presigned URL: " + url);
          this.firmwareService
            .uploadFileForValidation(firmwareFile, url)
            .subscribe(
              (response) => {
                // 2. Validate the firmware
                this.firmwareService
                  .validate(firmwareFile.name, this.selectedProjectNumber)
                  .pipe(finalize(() => (this.submitting = false)))
                  .subscribe(
                    (data) => {
                      this.handleValidationResponse(firmwareFile, data);
                    },
                    (err) => {
                      this.firmwareInvalid = true;
                      let errorMessage: string = err.message
                        ? err.message
                        : "Failed validating firmware, Error: " +
                          err.statusCode;
                      let toastMessage: string = err.message
                        ? err.message
                        : "Failed validating firmware, Error: " +
                          err.statusCode;
                      this.onError(errorMessage, toastMessage);
                    }
                  );
              },
              (err) => {
                this.firmwareInvalid = true;
                let errorMessage: string = err.message
                  ? err.message
                  : "Failed to upload the firmware for validation, Error: " +
                    err.statusCode;
                let toastMessage: string = err.message
                  ? err.message
                  : "Failed to upload the firmware for validation, Error: " +
                    err.statusCode;
                this.onError(errorMessage, toastMessage);
              }
            );
        });
    }
  }

  private onSuccess(data: any, message: string) {
    this.toastHelper.showSuccess(message);
    this.model = data;
  }

  private onError(error: string, message: string) {
    this.toastHelper.showError(error, message);
    this.errorMessage = error;
  }
}
