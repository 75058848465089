/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

// Libraries
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Title } from "@angular/platform-browser";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

// Components
import { DeleteConfirmDialogComponent } from "../../shared/dialogs/delete-confirm.dialog";
import { ForceConfirmDialogComponent } from "../../shared/dialogs/force-confirm.dialog";

// Models
import { Firmware } from "../../core/models/firmware/firmware.model";
import { FirmwareSkuListModel } from "../../core/models/firmware/firmware-sku-list.model";
import { Job } from "../../core/models/jobs/job.model";
import { SearchTerm } from "../../core/models/search/search-term.model";

// Services
import { FirmwareService } from "../firmware/firmware.service";
import { JobsService } from "./jobs.service";
import { ToastHelperService } from "../../shared/utility/toast-helper.service";

@Component({
  selector: "jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class JobsComponent implements OnInit {
  public currentSearch: string;
  public firmwaresForOneOffJob: Firmware[] = [];
  public firmwaresForSku: FirmwareSkuListModel[] = [];
  public isCreateOneOff: boolean = true;
  public isJobCreateLoading: boolean = false;
  public isJobsLoading: boolean = false;
  public jobs: Job[] = [];
  public lastEvaluatedKeyJobs: string;
  public nextTokenJobs: string;
  public oneOffInputMode: string = "skus";
  public oneOffThingNameInput: string;
  public selectedFirmware: Firmware;
  public selectedSkus: string[] = [];
  public selectedThingNames: string[] = [];
  public columnsToDisplay = [
    "jobId",
    "targetSelection",
    "status",
    "createdAt",
    "lastUpdatedAt",
    "completedAt",
    "cancel",
    "delete",
    "details",
  ];
  public jobDetailsColumn = [
    "numberOfQueuedThings",
    "numberOfSucceededThings",
    "numberOfFailedThings",
    "numberOfCanceledThings",
    "numberOfInProgressThings",
    "numberOfRemovedThings",
    "numberOfTimedOutThings",
    "numberOfRejectedThings",
  ];
  public jobDetailsTitle = [
    "Queued Things",
    "Successful",
    "Failed",
    "Cancelled",
    "In Progress",
    "Removed",
    "Timed Out",
    "Rejected",
  ];
  public dataSource = [];
  public expandedElement = null;
  private get hasSnapshotItemsSelected() {
    return (
      (this.selectedSkus && this.selectedSkus.length) ||
      (this.selectedThingNames && this.selectedThingNames.length)
    );
  }

  constructor(
    private dialog: MatDialog,
    private firmwareService: FirmwareService,
    private jobsService: JobsService,
    private toastHelper: ToastHelperService,
    private titleService: Title
  ) {}

  public ngOnInit() {
    this.titleService.setTitle("Jobs");
    this.loadJobs();
    this.loadOneOffFirmwares();
  }

  private _buildVersionNumber(major, minor, build) {
    return `${major}.${minor}.${build}`;
  }

  /**
   * Function input for list date sort.
   */
  private _sortDates(dateA, dateB) {
    return dateB.getTime() - dateA.getTime();
  }

  /**
   * Function input for list number sort.
   */
  private _sortNumbers(numberA, numberB) {
    return numberB - numberA;
  }

  /**
   * Merge a firmware and a firmware product for a specific SKU to a display model.
   */
  private _mapFirmwareToFirmwareSkuList(
    sku,
    firmwareApiResult
  ): FirmwareSkuListModel {
    const product = firmwareApiResult.products.find((prod) => prod.productId);
    if (!product) return null;

    let model = new FirmwareSkuListModel();
    model.created = new Date(firmwareApiResult.created);
    model.firmwareId = firmwareApiResult.firmwareId;
    model.isActive = product.isActive;
    model.name = firmwareApiResult.name || firmwareApiResult.firmwareId;
    model.released = firmwareApiResult.released;
    model.sku = product.productId;
    model.versionNumber =
      firmwareApiResult.versionNumber ||
      this._buildVersionNumber(
        firmwareApiResult.major,
        firmwareApiResult.minor,
        firmwareApiResult.build
      );

    return model;
  }

  /**
   * Map the AWS IoT job model to our job model
   */
  private _mapIoTJobToJob(iotJob) {
    let model = new Job();
    model.jobArn = iotJob.jobArn;
    model.jobId = iotJob.jobId;
    model.thingGroupId = iotJob.thingGroupId;
    model.targetSelection = iotJob.targetSelection;
    model.status = iotJob.status;
    model.createdAt = new Date(iotJob.createdAt);
    if (iotJob.lastUpdatedAt)
      model.lastUpdatedAt = new Date(iotJob.lastUpdatedAt);
    if (iotJob.completedAt) model.completedAt = new Date(iotJob.completedAt);
    model.jobProcessDetails = iotJob.jobProcessDetails;

    return model;
  }

  /**
   * Send a request to AWS to activate a SKU, creating a continuous firmware delivery job.
   */
  public activateFirmwareForSku(firmwareId: string, sku: string) {
    this.isJobCreateLoading = true;
    this.jobsService
      .postContinuousJob(firmwareId, sku)
      .finally(() => {
        this.isJobCreateLoading = false;
      })
      .subscribe(
        (jobResult: any) => {
          this.toastHelper.showSuccess(
            `Job created with ID '${jobResult.jobId}'.`
          );
          this.reloadJobs();

          if (this.currentSearch) {
            this.searchFirmwaresBySku(this.currentSearch);
          }
        },
        (err) => {
          let msg = "Failed to activate firmware.";
          if (err && err.error && [404, 422].indexOf(err.error.code) > -1) {
            msg = err.error.message;
          }

          this.toastHelper.showError(err, msg);
        }
      );
  }

  /**
   * Adds a string to the list of specified thing names.
   * @param thingName String to add
   */
  public addToSelectedThingNames(thingName: string) {
    if (thingName && this.selectedThingNames.indexOf(thingName) === -1)
      this.selectedThingNames.push(thingName);

    this.oneOffThingNameInput = "";
  }

  /**
   * Send a request to AWS to cancel a job, forcing if requested.
   */
  public cancelJob(jobId: string, force: boolean) {
    this.jobsService.cancelJob(jobId, force).subscribe(
      (jobId) => {
        this.toastHelper.showSuccess("Job canceled");
        this.reloadJobs();
      },
      (err) => {
        let msg = "Failed to cancel job.";
        if (!force) msg += " You may need to force cancel.";
        this.toastHelper.showError(err, msg);
      }
    );
  }

  /**
   * Pop up a "confirm" dialog, requesting confirmation that the user wants to create a continous firmware job for a specified SKU.
   */
  public confirmActivateFirmware(firmwareId: string, sku: string) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {
        confirmButtonText: "Confirm",
        message: `Are you sure you want to activate this firmware for SKU ${sku}? Doing so will force cancel any in-progress jobs for this SKU and create a new automated firmware deployment.`,
        title: "Confirm activation",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.activateFirmwareForSku(firmwareId, sku);
      }
    });
  }

  /**
   * Pop up a "confirm" dialog, requesting confirmation that the user wants to cancel a specified job.
   */
  public confirmCancelJob(jobId: string) {
    const dialogRef = this.dialog.open(ForceConfirmDialogComponent, {
      data: {
        confirmButtonText: "Confirm",
        message: `Are you sure you want to cancel this Job? Warning: cancellation will fail if any robots are currently updating. Forcing cancellation will cancel any in-progress jobs.`,
        title: "Confirm cancellation",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const force = !!result.force;
        this.cancelJob(jobId, force);
      }
    });
  }

  /**
   * Pop up a "confirm" dialog, requesting confirmation that the user wants to create a snapshot firmware job for a specified set of SKUs.
   */
  public confirmCreateSnapshotJob() {
    if (!this.selectedFirmware) {
      this.toastHelper.showError(null, "Please select a firmware.");
      return;
    }

    if (!this.hasSnapshotItemsSelected) {
      this.toastHelper.showError(
        null,
        "Please select at least one SKU or at least one Thing."
      );
      return;
    }

    let message;
    if (this.selectedSkus && this.selectedSkus.length)
      message = `Are you sure you want to create a firmware job for these SKUs: ${this.selectedSkus.join(
        ", "
      )}?`;
    else if (this.selectedSkus)
      message = `Are you sure you want to create a firmware job for these Things: ${this.selectedThingNames.join(
        ", "
      )}?`;
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {
        confirmButtonText: "Confirm",
        message,
        title: "Confirm creation",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.createSnapshotJob(this.selectedFirmware.name);
      }
    });
  }

  /**
   * Pop up a "confirm" dialog, requesting confirmation that the user wants to delete a specified job.
   */
  public confirmDeleteJob(jobId: string) {
    const dialogRef = this.dialog.open(ForceConfirmDialogComponent, {
      data: {
        confirmButtonText: "Confirm",
        message:
          "Are you sure you want to delete this Job? Warning: Deleteing a job will remove all its historical data, and will fail if any robots are currently updating. Forcing deletion will also cancel any in-progress jobs.",
        title: "Confirm deletion",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const force = !!result.force;
        this.deleteJob(jobId, force);
      }
    });
  }

  /**
   * Send a request to AWS to activate a SKU, creating a snapshot firmware delivery job.
   */
  public createSnapshotJob(firmwareId: string) {
    this.isJobCreateLoading = true;
    this.jobsService
      .postSnapshotJob(firmwareId, this.selectedSkus, this.selectedThingNames)
      .finally(() => {
        this.isJobCreateLoading = false;
      })
      .subscribe(
        (jobResult: any) => {
          this.selectedFirmware = null;
          this.selectedSkus = [];
          this.selectedThingNames = [];
          this.toastHelper.showSuccess(
            `Job created with ID '${jobResult.jobId}'.`
          );
          this.reloadJobs();
        },
        (err) => {
          let msg = "Failed to create job.";
          if (
            err &&
            err.error &&
            [404, 422].indexOf(err.error.code) > -1 &&
            err.error.message !== "Not Found"
          ) {
            msg = err.error.message;
          } else if (err && err.error && err.error.code === 404) {
            let thingType = this.oneOffInputMode === "skus" ? "SKUs" : "things";
            msg += ` Unable to find one or more of your selected ${thingType}.`;
          } else if (err && err.error && err.error.code === 400) {
            // Special case: Job manifest is *likely* malformed - comes back as a permissions issue
            if (err.error.message.startsWith("InvalidRequestException")) {
              msg +=
                " Check that Job Manifest URLs are pointing to the correct firmware files.";
            }
          }

          this.toastHelper.showError(err, msg);
        }
      );
  }

  /**
   * Send a request to AWS to delete a job, forcing if requested.
   */
  public deleteJob(jobId: string, force: boolean) {
    this.jobsService.deleteJob(jobId, force).subscribe(
      (jobId) => {
        this.toastHelper.showSuccess("Job deleted");
        this.reloadJobs();
      },
      (err) => {
        let msg = "Failed to delete job.";
        if (!force) msg += " You may need to force delete.";
        this.toastHelper.showError(err, msg);
      }
    );
  }

  /**
   * Gets the tooltip text for the Activate button.
   */
  public getActivateButtonToolTip(firmware: FirmwareSkuListModel): string {
    if (!firmware.released) return "Firmware must be released.";

    if (firmware.isActive) return "Firmware is already active.";

    return null;
  }

  /**
   * Gets the display date for a particular firmware
   */
  public getFirmwareDate(firmware: Firmware) {
    return null;
  }

  /**
   * Gets the display title for a particular firmware
   */
  public getFirmwareTitle(firmware: Firmware) {
    let title = firmware.name;
    let vn = firmware.getVersionNumber();
    if (vn) title += ` (${vn})`;

    return title;
  }

  /**
   * Load IoT jobs
   */
  public loadJobs() {
    this.isJobsLoading = true;
    this.jobsService.getAll(this.nextTokenJobs).subscribe(
      (jobsResult) => {
        this.nextTokenJobs = jobsResult.nextToken;

        if (jobsResult.jobs && jobsResult.jobs.length) {
          this.jobs = this.jobs
            .concat(jobsResult.jobs)
            .map(this._mapIoTJobToJob)
            .filter((job) => job)
            .sort((jobA, jobB) =>
              this._sortDates(jobA.createdAt, jobB.createdAt)
            );

          this.dataSource = this.jobs;
        }
      },
      (err) => {
        this.toastHelper.showError(err, "Failed to load jobs.");
      },
      () => {
        this.isJobsLoading = false;
      }
    );
  }

  /**
   * Load Firmares for use in Manual Job creation
   */
  public loadOneOffFirmwares() {
    this.firmwareService.getAll().subscribe(
      (res) => {
        if (res && res.items && res.items.length > 0) {
          this.firmwaresForOneOffJob = this.firmwaresForOneOffJob.concat(
            res.items
              .filter(
                (item) =>
                  item &&
                  item.released &&
                  item.path &&
                  !item.path.includes("ota-ready")
              )
              .map((item) => new Firmware(item))
          );
        }
      },
      (err) => {
        this.toastHelper.showError(
          err,
          "Failed to load firmware for manual job creation."
        );
      }
    );
  }

  /**
   * Handles a type change for one off input type, clearing out existing values.
   * @param event
   */
  public onOneOffInputTypeChange(event: any) {
    this.oneOffInputMode = event.value;
    this.selectedSkus = [];
    this.selectedThingNames = [];
  }

  /**
   * Removes a thing name from the list of selected thing names, if existing.
   * @param thingName Thingname to remove
   */
  public onRemoveSelectedThingName(thingName: string) {
    const idx = this.selectedThingNames.indexOf(thingName);
    if (idx !== -1) this.selectedThingNames.splice(idx, 1);
  }

  /**
   * Clear existing IoT Jobs and reload them.
   */
  public reloadJobs() {
    this.jobs = [];
    this.nextTokenJobs = null;
    this.loadJobs();
  }

  public searchFirmwaresBySku(sku: string) {
    this.currentSearch = sku;
    this.firmwaresForSku = [];

    const searchTerm = new SearchTerm({ name: "productId" }, sku);
    this.firmwareService
      .search([searchTerm], this.lastEvaluatedKeyJobs)
      .subscribe(
        (res) => {
          if (res && res.items && res.items.length > 0) {
            this.firmwaresForSku = this.firmwaresForSku
              .concat(
                res.items.map((item) =>
                  this._mapFirmwareToFirmwareSkuList(sku, item)
                )
              )
              .filter((item) => item)
              .sort((itemA, itemB) =>
                this._sortNumbers(
                  itemA.updated || itemA.created,
                  itemB.updated || itemB.created
                )
              );
          }

          this.lastEvaluatedKeyJobs = res.lastEvaluatedKey;
        },
        (err) => {
          let msg = "Firmware search failed.";
          if (err && err.error && err.error.message) {
            msg = err.error.message;
          }

          this.toastHelper.showError(err, msg);
        }
      );
  }

  /**
   * Computes whether or not the activate button should be disabled for a given firmware.
   */
  public shouldDisableActivateButton(firmware: FirmwareSkuListModel) {
    return this.isJobCreateLoading || firmware.isActive || !firmware.released;
  }

  /**
   * Computes whether or not to disable the "Create Snapshot Job" button
   */
  public shouldDisableSnapshotCreateButton() {
    return (
      this.isJobCreateLoading ||
      !this.selectedFirmware ||
      !this.hasSnapshotItemsSelected
    );
  }

  /**
   * Determines whether or not the cancel button should be shown for a given job.
   */
  public shouldShowCancel(job: any) {
    // For now, users must cancel CONTINUOUS jobs via IoT core. When doing so, they should also
    // deactivate any related SKUs located in the firmware_products table.
    return (
      job &&
      ["IN_PROGRESS"].indexOf(job.status) > -1 &&
      job.targetSelection !== "CONTINUOUS"
    );
  }

  /**
   * Determines whether or not the delete button should be shown for a given job.
   */
  public shouldShowDelete(job: any) {
    // For now, users must delete CONTINUOUS jobs via IoT core. When doing so, they should also
    // deactivate any related SKUs located in the firmware_products table.
    return (
      job &&
      job.status !== "DELETION_IN_PROGRESS" &&
      !(job.status === "IN_PROGRESS" && job.targetSelection === "CONTINUOUS")
    );
  }

  public getDetails(job) {
    if (this.expandedElement) {
      this.expandedElement = null;
    } else {
      this.expandedElement = job;
      this.expandedElement.jobDetails = [];
      this.expandedElement.jobDetails.push(job.jobProcessDetails);
    }
  }
}
