/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { BhiProductsService } from "../bhi_products.service";
import { ProductType } from "./models";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
} from "@angular/material";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { find, map as _map, reduce } from "lodash";
import { MatSnackBar } from "@angular/material";
import { ProductCatsAndSubcats } from "../../product-categories";
import { ActiveTabService } from "../active-tab.service";
import { ConfigService } from "../../config/config.service";

export interface Code {
  name: string;
}

const PRODUCT_FAMILY_NAME: string = "family";
const PRODUCT_FAMILY_KEY_NAME: string = "cms";

@Component({
  selector: "app-product-attributes-tab",
  templateUrl: "./attributes.component.html",
  styleUrls: ["./product-tabs.scss"],
  providers: [BhiProductsService, ConfigService],
})
export class AttributesComponent implements OnInit {
  product: ProductType;
  hazardCodes: Code[] = [];
  msdsCodes: Code[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  tagCtrl = new FormControl();
  filteredTags: Observable<any[]>;
  tags = []; // all actively selected tags
  allTags = []; // tag options from server

  typeOptions = [];

  relatedProductCtrl = new FormControl();
  filteredRelatedProducts: Observable<any[]>;
  relatedProducts = []; // all actively selected related products
  allRelatedProducts = []; // product options from server

  productCategories = _map(ProductCatsAndSubcats, "category").sort();
  productSubcategories = [];
  productFamilies: string[] = [];

  @ViewChild("chips") chips;
  @ViewChild("relatedProductsInput") relatedProductInput: ElementRef;
  @ViewChild("tagInput") tagInput: ElementRef;

  constructor(
    private bhiProductsService: BhiProductsService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private activeTabService: ActiveTabService,
    private configService: ConfigService
  ) {}

  getProductFamilies() {
    const configurationName: string = PRODUCT_FAMILY_NAME;
    const key: string = PRODUCT_FAMILY_KEY_NAME;
    this.configService.getConfiguration(configurationName, key).subscribe(
      (result) => {
        this.productFamilies = result[0].family;
      },
      (error) => {},
      () => {}
    );
  }

  ngOnInit() {
    // get tag chips
    this.setTags();

    this.activeRoute.params.subscribe((params) => {
      const sku = params["sku"];

      // load product info
      this.getData(sku);
      this.typeOptions = this.populateTypes();
    });
    // Get the product families here
    this.getProductFamilies();
    this.activeTabService.setTab(this);
  }

  getData(sku) {
    let errors;

    this.bhiProductsService.getBhiProduct(sku).subscribe(
      (result) => {
        this.product = result;
        if (!this.product.hazards) {
          this.hazardCodes = [];
          this.msdsCodes = [];
        } else {
          // add codes from the server back as chips
          this.product.hazards.codes.forEach((x) => {
            this.hazardCodes.push({ name: x });
          });

          this.product.hazards.msds.forEach((x) => {
            this.msdsCodes.push({ name: x });
          });
        }
        console.log("THIS", this);
        // set subcategory
        if (this.product.category) {
          this.updateSubcategories({ value: this.product.category });
        }
        // add related products from the server back as chips
        this.relatedProducts = this.product.relatedProducts
          ? this.product.relatedProducts
          : [];

        // add tags from the server back as chips
        this.tags = this.product.tags ? this.product.tags : [];
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );
  }

  cancel() {
    this.router.navigate(["dashboard/cms/products"]);
  }

  showRequiredFieldWarning() {
    this.snackBar.open("Missing required field", "Close", {
      duration: 4000,
      verticalPosition: "top",
      horizontalPosition: "right",
      panelClass: ["warn-snackbar"],
    });
  }

  validateProductAttributes(productAttributeBody) {
    // Currently validating the product family only
    if (!productAttributeBody.family) {
      return false;
    }
    return true;
  }

  save() {
    // Validate the product family else show the error notification
    if (!this.validateProductAttributes(this.product)) {
      this.showRequiredFieldWarning();
      return false;
    }
    this.product.hazards = {
      codes: [],
      msds: [],
    };

    // add code tags to product
    this.hazardCodes.forEach((x) => {
      this.product.hazards.codes.push(x.name);
    });

    this.msdsCodes.forEach((x) => {
      this.product.hazards.msds.push(x.name);
    });

    // add tags to products
    this.product.tags = this.tags;

    // add related products to produts
    this.product.relatedProducts = this.relatedProducts;

    // save product
    this.bhiProductsService
      .putBhiProduct(this.product, false)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          // show notification
          this.snackBar.open("Product updated successfully.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });
        }
      });
    return true;
  }

  hazardsAdd(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.hazardCodes.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  hazardsRemove(code: Code): void {
    const index = this.hazardCodes.indexOf(code);

    if (index >= 0) {
      this.hazardCodes.splice(index, 1);
    }
  }

  msdsAdd(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.msdsCodes.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  msdsRemove(code: Code): void {
    const index = this.msdsCodes.indexOf(code);

    if (index >= 0) {
      this.msdsCodes.splice(index, 1);
    }
  }

  relatedProductsRemove(code: Code): void {
    const index = this.relatedProducts.indexOf(code);

    if (index >= 0) {
      this.relatedProducts.splice(index, 1);
    }
  }

  relatedProductsFilter(name: string) {
    return this.allRelatedProducts.filter(
      (tag) => tag.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  relatedProductsSelected(event: MatAutocompleteSelectedEvent): void {
    this.relatedProducts.push(event.option.viewValue);
    this.relatedProductInput.nativeElement.value = "";
    this.relatedProductCtrl.setValue(null);
  }

  removeTag(tag: any): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.value);
    this.tagInput.nativeElement.value = "";
    this.tagCtrl.setValue(null);
  }

  filterTags(name: string) {
    return this.allTags.filter((tag) =>
      tag.contentTag
        ? tag.contentTag.toLowerCase().indexOf(name.toLowerCase()) === 0
        : false
    );
  }

  setTags() {
    this.bhiProductsService.getAllTags().subscribe((res: any) => {
      // generate correct data structure
      this.allTags = reduce(
        res.item.Items,
        function (arr, elm) {
          const tagElm = {
            contentTag: elm.contentTag,
            title: elm.title,
            description: elm.description,
          };
          arr.push(tagElm);
          return arr;
        },
        []
      );

      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string | null) =>
          tag ? this.filterTags(tag) : this.allTags.slice()
        )
      );
    });
  }

  tagDisplayFn(tag: any): string {
    return tag && tag.contentTag ? tag.contentTag : "";
  }

  updateSubcategories(event) {
    const { subcategories } = find(ProductCatsAndSubcats, {
      category: event.value,
    }) as any;
    this.productSubcategories = subcategories;
    if (this.productSubcategories) {
      this.productSubcategories.sort();
    }
  }

  queryForSku(inputValue) {
    if (inputValue.length >= 3) {
      this.searchRelatedProducts(inputValue);
      return;
    }

    if (inputValue.length === 0) {
      this.allRelatedProducts = [];
    }
  }

  searchRelatedProducts(skuSearch) {
    this.bhiProductsService
      .getRelatedProducts(skuSearch)
      .subscribe((res: any) => {
        if (res.status === "Error") {
          console.error(res.reason.message);
          return;
        }

        // generate correct data structure
        this.allRelatedProducts = reduce(
          res.item.Items,
          function (arr, elm) {
            arr.push(elm.sku);
            return arr;
          },
          []
        );
      });
  }

  /* types are currnently coded here based on existing data from table. additional types should be added Herzegovina
    when needed OR move to dynamo table in future
  */
  populateTypes() {
    const types = [
      { name: null, value: "None" },
      { name: "Formula", value: "Formula" },
      { name: "Filter", value: "Filter" },
      { name: "BrushRoll", value: "BrushRoll" },
      { name: "odor_remover", value: "Hard Floor Tough Mess" },
      { name: "wood", value: "Wood Floor" },
      { name: "rug", value: "Area Rug" },
      { name: "multisurface", value: "Multi-Surface" },
      { name: "pet", value: "Pet Multi-Surface" },
      { name: "upright", value: "Upright" },
    ];
    return types;
  }
}
