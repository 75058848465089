/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Label } from "../../../core/models/labels/labels.model";
import { Observable } from "rxjs";
import { TemplateFieldElement } from "./template-field.component";
import { AppConfigService } from "../../../app.config.service";

const uuid = require("uuid");

@Injectable()
export class TemplateFieldsService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getTemplateFields(): Observable<Label> {
    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/notifications"
      }/template-fields`
    );
  }

  public getTemplateField(id): Observable<Label> {
    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/notifications"
      }/template-fields/${id}`
    );
  }

  public addTemplateField(
    field: TemplateFieldElement,
    isNew: boolean
  ): Observable<Label> {
    // set id
    field.id = field.id ? field.id : uuid();

    const body = {
      Item: field,
    };

    return this.http.put<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/notifications"
      }/template-fields`,
      body
    );
  }
}
