/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { FirmwareListModel } from "../../core/models/firmware/firmware-list.model";
import {
  Firmware,
  FirmwareUploadResponse,
  FwValidationResponse,
} from "../../core/models/firmware/firmware.model";
import { FirmwareReleaseModel } from "../../core/models/firmware/firmware-release.model";
import { SearchTerm } from "../../core/models/search/search-term.model";
import { AppConfigService } from "../../app.config.service";

@Injectable()
export class FirmwareService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  /**
   * Gets all existing firmware manifests.
   */
  public getAll(): Observable<FirmwareListModel> {
    let params = new HttpParams();
    params = params.append("includeAll", "true");
    return this.http.get<FirmwareListModel>(
      this.appConfig.getConfig().portalApiURL + "/firmware",
      { params }
    );
  }

  public get(id: string): Observable<Firmware> {
    return this.http.get<Firmware>(
      `${this.appConfig.getConfig().portalApiURL + "/firmware"}/${id}`
    );
  }

  public clean(): Observable<FirmwareListModel> {
    return this.http.post<FirmwareListModel>(
      `${this.appConfig.getConfig().portalApiURL + "/firmware/clean"}`,
      {}
    );
  }

  public getProjects(): Observable<
    Array<{ baseSku: string; projectNumber: string; displayName: string }>
  > {
    return this.http.get<
      Array<{ baseSku: string; projectNumber: string; displayName: string }>
    >(`${this.appConfig.getConfig().portalApiURL + "/products"}/projects`);
  }

  /**
   * Gets all firmware job manifests from S3.
   */
  public getS3JobManifests(): Observable<Array<any>> {
    return this.http.get<Array<string>>(
      `${this.appConfig.getConfig().portalApiURL + "/firmware"}/job-manifests`
    );
  }

  public create(firmware: Firmware): Observable<Firmware> {
    return this.http.post<Firmware>(
      this.appConfig.getConfig().portalApiURL + "/firmware",
      firmware
    );
  }

  public save(
    fileName: string,
    projectNumber: string
  ): Observable<FirmwareUploadResponse[]> {
    const firmwareItemBody = {
      name: fileName,
      projectNumber: projectNumber,
    };
    return this.http.post<FirmwareUploadResponse[]>(
      `${this.appConfig.getConfig().portalApiURL + "/firmware"}/files/save`,
      firmwareItemBody
    );
  }

  public getUploadUrlForValidation(file: File): Observable<string> {
    return this.http.get<string>(
      `${
        this.appConfig.getConfig().portalApiURL + "/firmware"
      }/files/validateFirmwareUrl/${file.name}`
    );
  }

  public uploadFileForValidation(file: File, presignedUrl: string) {
    const headers = { "Content-Type": "application/octet-stream" };
    console.log(
      `HTTP Put request for uploading ${file.name} to ${presignedUrl}`
    );
    return this.http.put(presignedUrl, file, { headers: headers });
  }

  public validate(
    filename: string,
    projectNumber: string
  ): Observable<FwValidationResponse[]> {
    const firmwareItemBody = {
      name: filename,
      projectNumber: projectNumber,
    };
    return this.http.post<FwValidationResponse[]>(
      `${this.appConfig.getConfig().portalApiURL + "/firmware"}/files/validate`,
      firmwareItemBody
    );
  }

  public update(firmware: Firmware): Observable<Firmware> {
    return this.http.put<Firmware>(
      `${this.appConfig.getConfig().portalApiURL + "/firmware"}/${
        firmware.name
      }`,
      firmware
    );
  }

  public delete(firmware: Firmware): Observable<Firmware> {
    return this.http.delete<Firmware>(
      `${this.appConfig.getConfig().portalApiURL + "/firmware"}/${
        firmware.name
      }`
    );
  }

  public search(
    searchTerms: SearchTerm[],
    lastEvaluatedKey?: string
  ): Observable<FirmwareListModel> {
    let params = new HttpParams();
    searchTerms = searchTerms || [];
    if (searchTerms && searchTerms.length > 0) {
      // Make sure there are search terms
      for (let searchTerm of searchTerms) {
        params = params.set(searchTerm.attribute.name, searchTerm.value);
      }
    }

    if (lastEvaluatedKey) {
      params = params.append("lastEvaluatedKey", lastEvaluatedKey);
    }

    return this.http.get<FirmwareListModel>(
      this.appConfig.getConfig().portalApiURL + "/firmware",
      { params }
    );
  }

  public getVersionDetailsForManifest(
    filePath: string
  ): Observable<FirmwareReleaseModel> {
    let params = new HttpParams();
    if (filePath) {
      params = params.append("file", filePath);
    }
    return this.http.get<FirmwareReleaseModel>(
      `${
        this.appConfig.getConfig().portalApiURL + "/firmware"
      }/manifest-details`,
      { params }
    );
  }
}
