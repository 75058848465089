/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material";
import { BhiProductsService } from "../bhi_products.service";
import { LanguagesService } from "../../languages/languages.service";
import { CountriesService } from "../../countries.service";
import {
  UploadDocumentType,
  UploadDocumentTypes,
} from "../uploadDocumentTypes";
import { ActivatedRoute, Router } from "@angular/router";
import { DocumentType } from "./models";
import { ActiveTabService } from "../active-tab.service";
import { set, reduce } from "lodash";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FormControl } from "@angular/forms";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatDialogConfig,
  MatSnackBar,
  MatSort,
} from "@angular/material";

export interface DialogData {
  refreshGridFn: any;
  sku: string;
  languages?: string;
  document: any;
}

export interface DeleteDialogData {
  sku: string;
  id: string;
  documentName: string;
}

@Component({
  selector: "app-product-documents-tab",
  templateUrl: "./documents.component.html",
  styleUrls: ["./product-tabs.scss"],
  providers: [BhiProductsService],
})
export class DocumentsComponent implements OnInit {
  // global variables
  documents: DocumentType;

  // default to English
  selectedLanguage: string;

  // populate language dropdown
  languages: string[];

  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  sku: string;

  constructor(
    private bhiProductsService: BhiProductsService,
    public languagesService: LanguagesService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private activeTabService: ActiveTabService
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.sku = params["sku"];
      this.selectedLanguage = "";

      // set table columns
      this.displayColumns = [
        "name",
        "type",
        "thumbnail",
        "altTag",
        "region",
        "languageCode",
        "url",
        "actions",
      ];

      // get languages
      this.getLanguages();

      // load product info
      this.getData();
    });
    this.activeTabService.setTab(this);
  }

  getLanguages() {
    let errors;

    this.languagesService.getAllLanguages().subscribe(
      (result) => {
        // populate languages dropdown
        this.languages = result as any;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** callback */
      }
    );
  }

  getData() {
    let errors;

    this.bhiProductsService
      .getBhiProductDocuments(this.sku, this.selectedLanguage)
      .subscribe(
        (result) => {
          const items: any = result as any;
          this.documents = items;
          this.dataSource = new MatTableDataSource(items);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        (error) => {
          errors = error;
          console.log("There was an error: " + error.message);
        },
        () => {
          /** Callback */
        }
      );
  }

  updateGridData(event: any) {
    this.getData();
  }

  showFileUploader() {
    const dialogRef = this.dialog.open(CmsProductDocumentDialogComponent, {
      width: "500px",
      data: {
        refreshGridFn: () => {
          this.getData(); // refresh data
        },
        sku: this.sku,
        languages: this.languages,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  editProductDocument(doc) {
    const dialogRef = this.dialog.open(CmsProductDocumentDialogComponent, {
      width: "500px",
      data: {
        refreshGridFn: () => {
          this.getData(); // refresh data
        },
        sku: this.sku,
        document: doc,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  deleteProductDocument(doc) {
    const dialogRef = this.dialog.open(
      CmsProductDeleteDocumentDialogComponent,
      {
        width: "500px",
        data: {
          id: doc.id,
          sku: doc.sku,
          documentName: doc.name,
        },
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }
}

// delete confirmation modal
@Component({
  selector: "app-cms-product-document-delete-dialog",
  templateUrl: "product-document-delete.component.html",
  styleUrls: ["./product-tabs.scss"],
})
export class CmsProductDeleteDocumentDialogComponent {
  documentName: string;

  constructor(
    public dialogRef: MatDialogRef<CmsProductDeleteDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogData,
    private bhiProductsService: BhiProductsService,
    private snackBar: MatSnackBar
  ) {
    this.documentName = this.data.documentName;
  }

  cancel() {
    this.dialogRef.close();
  }

  deleteDocument() {
    this.bhiProductsService
      .deleteBhiProductDocuments(this.data.sku, this.data.id)
      .subscribe((data: any) => {
        console.log(data);

        // show toast

        // close modal
        this.dialogRef.close();
      });
  }
}

// file uploader
@Component({
  selector: "app-cms-product-document-uploader-dialog",
  templateUrl: "product-document-uploader-dialog.component.html",
  styleUrls: ["./product-tabs.scss"],
})
export class CmsProductDocumentDialogComponent implements OnInit {
  selectedFile: any;
  selectedThumbnameFile: any;
  uploaderLanguages: any[];
  uploaderRegions: any;
  upladerDocumentTypes: UploadDocumentType[] = UploadDocumentTypes;
  document: any;
  errorMessage: string;
  showErrorMessage: boolean;
  selectedUrlOption: string;
  selectedThumbnameUrlOption: string;

  tagCtrl = new FormControl();
  filteredTags: Observable<any[]>;
  tags = []; // all actively selected tags
  allTags = []; // tag options from server

  upladerUrlTypes = [
    { value: "manual", text: "Input File URL" },
    { value: "upload", text: "Upload Local File" },
  ];

  thumbnameUrlTypes = [
    { value: "none", text: "None" },
    { value: "manual", text: "Input File URL" },
    { value: "upload", text: "Upload Local File" },
  ];

  displayPriority = [
    { value: 100, text: "Highest" },
    { value: 75, text: "High" },
    { value: 50, text: "Normal" },
    { value: 25, text: "Low" },
  ];

  @ViewChild("chips") chips;
  @ViewChild("tagInput") tagInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<CmsProductDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private bhiProductsService: BhiProductsService,
    private snackBar: MatSnackBar,
    private languagesService: LanguagesService,
    private countriesService: CountriesService
  ) {
    this.document = {};
    this.showErrorMessage = false;
    this.selectedUrlOption = "manual"; // default
    this.selectedThumbnameUrlOption = "none"; // default
  }

  ngOnInit() {
    // get tag chips
    this.setTags();

    if (this.data && this.data.document && this.data.document.id) {
      // set existing document details
      this.document = this.data.document;

      // if document has tags, then initialize , else tags array is empty
      if (this.document.tags) {
        this.tags = this.document.tags;
      }
      if (this.document.thumbname) {
        this.selectedThumbnameUrlOption = "manual";
      }
    }

    // get Region List
    this.countriesService.getCountries().then((countries) => {
      this.uploaderRegions = countries;
    });

    // get languages
    this.getLanguages();
  }

  getLanguages() {
    let errors;

    this.languagesService.getAllLanguages().subscribe(
      (result) => {
        // populate languages dropdown
        this.uploaderLanguages = result as any;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** callback */
      }
    );
  }

  handleFileInput(files: FileList) {
    // for now only handling one at a time
    this.selectedFile = files[0];
  }

  handleThumbnameFileInput(files: FileList) {
    // for now only handling one at a time
    this.selectedThumbnameFile = files[0];
  }

  async saveFile(sku, fileObj) {
    const result = await this.bhiProductsService.uploadDocumentToS3(
      sku,
      fileObj
    );
    return result.item;
  }

  async upload() {
    // make sure a file was selected or that a url was entered
    if (this.selectedUrlOption === "upload" && !this.selectedFile) {
      this.showErrorMessage = true;
      this.errorMessage = "No file selected.";
      console.error(this.errorMessage);
      return;
    }

    if (this.selectedUrlOption === "manual" && !this.document.url) {
      this.showErrorMessage = true;
      this.errorMessage = "No URL entered";
      console.error(this.errorMessage);
      return;
    }

    this.showErrorMessage = false;

    // set sku
    set(this.document, "sku", this.data.sku);

    // check required fields
    if (
      !(
        this.document.name &&
        this.document.type &&
        this.document.region &&
        this.document.languageCode
      )
    ) {
      this.showErrorMessage = true;
      this.errorMessage = "Not all required fields were provided.";
      console.error(this.errorMessage);
      return;
    }

    // upload any uploaded files
    await this.processFiles();

    // dd another field called title that would have the same value as name
    // (we don't need to display it to the user to set up, just copying a field to help mobile out
    this.document.title = this.document.name;

    // add tags to product document
    this.document.tags = this.tags;

    // save document with urls
    this.bhiProductsService
      .putBhiProductDocuments(this.document)
      .subscribe((data: any) => {
        this.showNotification(data, "Product updated successfully.");
        this.data.refreshGridFn();
      });

    // close modal
    this.dialogRef.close();
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  }

  async processFiles() {
    try {
      if (this.selectedFile) {
        const obj = await this.readFileAsync(this.selectedFile);
        const fileObj = {
          fileContent: obj,
          fileName: this.selectedFile.name,
          type: this.document.type,
          fileContentType: this.selectedFile.type,
        };
        this.document.url = await this.saveFile(this.document.sku, fileObj);
      }

      if (this.selectedThumbnameFile) {
        const obj = await this.readFileAsync(this.selectedThumbnameFile);
        const fileObj = {
          fileContent: obj,
          fileName: this.selectedThumbnameFile.name,
          type: this.document.type,
          fileContentType: this.selectedThumbnameFile.type,
        };
        this.document.thumbnail = await this.saveFile(
          this.document.sku,
          fileObj
        );
      }
    } catch (err) {
      console.error(err);

      // show notification
      const errMsg =
        "An error occurred attempting to upload the selected file. Please try again.";
      this.snackBar.open(errMsg, "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });
    }
  }

  removeTag(tag: any): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = "";
    this.tagCtrl.setValue(null);
  }

  filterTags(name: string) {
    return this.allTags.filter(
      (tag) => tag.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  setTags() {
    this.bhiProductsService.getAllTags().subscribe((res: any) => {
      // generate correct data structure
      this.allTags = reduce(
        res.item.Items,
        function (arr, elm) {
          arr.push(elm.contentTag);
          return arr;
        },
        []
      );

      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string | null) =>
          tag ? this.filterTags(tag) : this.allTags.slice()
        )
      );
    });
  }

  showNotification(data, msg) {
    if (data.status === "Success") {
      // show notification
      this.snackBar.open(msg, "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["success-snackbar"],
      });
    } else {
      // show notification
      const errMsg =
        "An error occurred while attempting to save: " + this.document.sku;
      this.snackBar.open(errMsg, "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });

      console.error(
        "An error occurred while attempting to save: " + data.reason.message
      );
    }
  }

  cancel() {
    this.data.refreshGridFn();
    this.dialogRef.close();
  }

  // clear input on change
  urlOptionChange(event) {}

  // clear input on change if none is selected
  thumbnameUrlOptionChange(event) {
    // reset the thumbName
    if (event.value === "none") {
      this.document.thumbname = null;
    }
  }
}
