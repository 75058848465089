/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { AppConfigService } from "../../../app.config.service";

const uuid = require("uuid");

@Injectable()
export class BhiProductsService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getBhiProducts(): Observable<any> {
    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL + "/products"}`
    );
  }

  public getRelatedProducts(search): Observable<any> {
    let params = new HttpParams();
    params = params.append("search", search);

    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL + "/products"}`,
      { params }
    );
  }

  public getBhiProduct(sku: string, languageCode?: string): Observable<any> {
    const prefix = `${this.getBhiProduct.name}(${sku}, ${languageCode})`;
    let params = new HttpParams();

    if (languageCode) {
      params = params.append("languageCode", languageCode);
    }

    const url = `${this.appConfig.getConfig().portalApiURL}/products/${sku}`;
    console.log(
      `${prefix}: ${this.http.get.name}(${url}, ${JSON.stringify({ params })})`
    );
    return this.http.get<any>(url, { params });
  }

  public getBhiProductDocuments(sku, languageCode): Observable<any> {
    let params = new HttpParams();
    if (languageCode) params = params.append("languageCode", languageCode);

    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/products"
      }/${sku}/documents`,
      {
        params,
      }
    );
  }

  public getBhiProductConsumables(sku, region): Observable<any> {
    let params = new HttpParams();
    params = params.append("region", region.toLowerCase());

    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL}/products/${sku}/consumables`,
      { params }
    );
  }

  public getBhiProductEcommerce(sku: string, region: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("region", region.toLowerCase());

    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL}/products/${sku}/ecommerce`,
      { params }
    );
  }

  public putBhiProduct(product, isNew): Observable<any> {
    const body = {
      isNew: isNew,
      Item: product,
    };

    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/products"}/${product.sku}`,
      body
    );
  }

  public copyBhiProduct(sourceSku, newSku, isNew): Observable<any> {
    let params = new HttpParams();
    params = params.append("sourceSku", sourceSku);

    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/products"}/${newSku}/copy`,
      {},
      { params }
    );
  }

  public putBhiProductEcommerce(product): Observable<any> {
    const productObj = product;

    if (!productObj.id) {
      productObj.id = uuid();
    }
    if (productObj.region) {
      productObj.region = productObj.region.toLowerCase();
    }

    const body = {
      Item: productObj,
    };

    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/products"}/${
        product.sku
      }/ecommerce`,
      body
    );
  }

  public putBhiProductDocuments(product): Observable<any> {
    const productObj = product;

    if (!productObj.id) {
      productObj.id = uuid();
    }

    const body = {
      Item: productObj,
    };

    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/products"}/${
        product.sku
      }/documents`,
      body
    );
  }

  public deleteBhiProductDocuments(sku, id): Observable<any> {
    return this.http.delete<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/products"
      }/${sku}/documents/${id}`
    );
  }

  async uploadDocumentToS3(sku, file): Promise<any> {
    const body = {
      Item: sku,
      File: file,
    };

    return this.http
      .put<any>(
        `${this.appConfig.getConfig().portalApiURL + "/products"}/${sku}/s3`,
        body
      )
      .toPromise();
  }

  public putBhiProductConsumables(product): Observable<any> {
    const productObj = product;
    productObj.id = uuid();

    if (productObj.region) {
      productObj.region = productObj.region.toLowerCase();
    }

    const body = {
      Item: productObj,
    };

    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/products"}/${
        product.partSku
      }/consumables`,
      body
    );
  }

  public getAllTags(): Observable<any> {
    return this.http.get<any>(
      this.appConfig.getConfig().portalApiURL +
        "/cms" +
        "/get-all-dynamic-content-tags"
    );
  }
}
