/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { set } from "lodash";
import { BhiProductsService } from "../bhi_products.service";
import { EcommerceType } from "./models";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatPaginator,
  MatTableDataSource,
  MatSnackBar,
  MatSort,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { find } from "lodash";
import { ActiveTabService } from "../active-tab.service";
import { CountriesService } from "../../countries.service";
import { ProductType, ProductUsageDetails } from "./models";

export interface DialogData {
  getProductCapabilityFn: any;
  sku: string;
  product: ProductType;
  productCapabilityData: any;
  isNew: boolean;
  capability: any;
}

@Component({
  selector: "app-product-capability-tab",
  templateUrl: "./capability.component.html",
  styleUrls: ["./product-tabs.scss"],
  providers: [BhiProductsService],
})
export class Capability implements OnInit {
  sku: string;
  product: ProductType;
  dataSource: any;
  capabilities: any; // this is array for table display
  capabilityData: any; // this is object as stored in product table
  displayColumns: string[];

  constructor(
    private bhiProductsService: BhiProductsService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private activeTabService: ActiveTabService,
    private countriesService: CountriesService,
    public dialog: MatDialog
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getProductData() {
    let errors;
    this.capabilities = [];
    this.bhiProductsService.getBhiProduct(this.sku).subscribe(
      (result) => {
        this.product = result;
        if (this.product.capabilities) {
          //const capabilities = {    "DRS" : true,     "Alexa" : false,     "Google Home": true,    "Command Control" : false};

          this.capabilityData = this.product.capabilities;
          this.capabilities = [];

          const capabilityKeys = Object.keys(this.capabilityData);
          for (var key of capabilityKeys) {
            const capability = {
              capability: key,
              status: this.capabilityData[key],
            };
            this.capabilities.push(capability);
          }

          // Sort array by display order to display correct view
          //  this.productUsageDetails.sort((a, b) => a.displayOrder - b.displayOrder);
          this.dataSource = new MatTableDataSource(this.capabilities);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        errors = error;
      },
      () => {
        /** Callback */
      }
    );
  }

  ngOnInit() {
    this.displayColumns = ["name", "status", "actions"];
    this.activeRoute.params.subscribe((params) => {
      this.sku = params["sku"];

      // load product info
      this.getProductData();
    });
    this.activeTabService.setTab(this);
  }

  openProductCapabilityDialog(isNew, capability?) {
    const dialogRef = this.dialog.open(CmsProductNewCapabilityDialogComponent, {
      width: "500px",
      data: {
        getProductCapabilityFn: () => {
          this.getProductData();
        },
        product: this.product,
        sku: this.sku,
        productCapabilityData: this.capabilityData,
        capability: Object.assign({}, capability),
        isNew: isNew ? true : false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  editProductCapability(usage: any) {
    this.openProductCapabilityDialog(false, usage);
  }

  addProductCapability() {
    this.openProductCapabilityDialog(true);
  }
}

@Component({
  selector: "app-cms-product-capability-new-dialog",
  templateUrl: "product-capability-dialog-component.html",
  styleUrls: ["./product-tabs.scss"],
})
export class CmsProductNewCapabilityDialogComponent implements OnInit {
  capability: any;
  product: ProductType;
  isNew: boolean;
  productCapabilityData: any;

  constructor(
    public dialogRef: MatDialogRef<CmsProductNewCapabilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private bhiProductsService: BhiProductsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.product = this.data.product;
    this.isNew = this.data.isNew;
    this.capability = this.isNew ? { status: false } : this.data.capability;
    this.productCapabilityData = this.data.productCapabilityData;
  }

  cancel() {
    this.dialogRef.close();
  }

  isValid(cConfig) {
    return cConfig && cConfig.capability;
  }

  showNotification(status) {
    if (status === "Success") {
      // show success notification
      this.snackBar.open("Updated successfully", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["success-snackbar"],
      });
    } else {
      // show error notification
      const errMsg =
        "An error occurred while attempting to save: " + this.product.sku;
      this.snackBar.open(errMsg, "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });
    }
  }

  save() {
    if (this.isValid(this.capability)) {
      // add the new consumableConfig in the productCapabilityData
      if (!this.productCapabilityData) {
        this.productCapabilityData = {};
      }

      this.productCapabilityData[this.capability.capability] =
        this.capability.status;
      // Now save the data
      set(this.product, ["capabilities"], this.productCapabilityData);
      // save the product usage
      this.bhiProductsService
        .putBhiProduct(this.product, false)
        .subscribe((data: any) => {
          // notify user of save action
          this.showNotification(data.status);
          // refresh the table once success
          this.data.getProductCapabilityFn();
        });
      this.dialogRef.close();
    } else {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });
    }
  }
}
