/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Routes } from "@angular/router";
import { PublicGuard } from "../core/router-guards/public.guard";

import { PublicLayoutComponent } from "./public-layout.component";
import { LoginComponent } from "./login/login.component";

export const PublicRoutes: Routes = [
  {
    path: "",
    component: PublicLayoutComponent,
    canActivate: [PublicGuard],
    children: [
      { path: "", component: LoginComponent },
      { path: "login", component: LoginComponent },
    ],
  },
];
