/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { CreateTeam } from "../../core/models/teams/createTeam.model";
import { Device } from "../../core/models/devices/device.model";
import { User } from "../../core/models/users/user.model";
import { AppConfigService } from "../../app.config.service";

@Injectable()
export class TeamService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public search(query: string, forcePartialSearch: boolean): Observable<any> {
    const qs =
      `?teamName=${query}` +
      (forcePartialSearch ? `&forcePartialSearch=true` : "");
    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL + "/teams"}${qs}`
    );
  }

  public getDevice(thingName: string): Observable<Device> {
    return this.http.get<Device>(
      `${this.appConfig.getConfig().portalApiURL + "/things"}/${thingName}`
    );
  }

  public getUser(id: string): Observable<User> {
    return this.http.get<User>(
      `${this.appConfig.getConfig().portalApiURL + "/users"}/${id}`
    );
  }

  public addUser(teamId: string, userId: string): Observable<any> {
    return this.http.put<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/teams"
      }/${teamId}/users/${userId}`,
      null
    );
  }

  public addDevice(teamId: string, thingName: string): Observable<any> {
    return this.http.put<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/teams"
      }/${teamId}/things/${thingName}`,
      null
    );
  }

  // public removeUser(teamId: string, userId: string): Observable<any> {
  //   return this.http.delete<any>(
  //     `${
  //       this.appConfig.getConfig().portalApiURL + "/teams"
  //     }/${teamId}/users/${userId}`
  //   );
  // }

  public removeDevice(teamId: string, thingName: string): Observable<any> {
    return this.http.delete<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/teams"
      }/${teamId}/things/${thingName}`
    );
  }

  public register(createTeam: CreateTeam): Observable<any> {
    const first = {
      userId: "",
      thingName: "",
      teamName: "",
    };

    first.userId = createTeam.users[0].userId;
    first.thingName = createTeam.devices[0].thingName;
    first.teamName = createTeam.teamName;

    return this.http
      .post<any>(this.appConfig.getConfig().portalApiURL + "/teams", first)
      .map((result) => {
        createTeam.teamId = result.teamId;
        const addUsers = [];
        const addThings = [];

        if (createTeam.users.length > 1) {
          // add all the users
          let i = 0;
          while (i < createTeam.users.length) {
            addUsers.push(
              this.addUser(createTeam.teamId, createTeam.users[i].userId)
            );
            i++;
          }
        }

        if (createTeam.devices.length > 1) {
          // add all the things
          let i = 0;
          while (i < createTeam.devices.length) {
            addThings.push(
              this.addDevice(createTeam.teamId, createTeam.devices[i].thingName)
            );
            i++;
          }
        }

        return Observable.forkJoin(addUsers).subscribe((res) => {
          return Observable.forkJoin(addThings).subscribe((res2) => {});
        });
      });
  }
}
