/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable, ElementRef } from "@angular/core";

@Injectable()
export class HighlightService {
  constructor() {
    //
  }

  public highlight(el: ElementRef, selector: string) {
    let hljs: any = window["hljs"];
    let elements = el.nativeElement.querySelectorAll(selector);
    for (let element of elements) {
      hljs.highlightBlock(element);
    }
  }
}
