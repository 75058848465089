/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MAT_DIALOG_DATA,
  MatTabGroup,
  MatTabHeader,
  MatTab,
  MatDialogRef,
  MatSnackBar,
} from "@angular/material";
import { SupportContactService } from "./support-contact.service";
import { IntnlCountryRecord } from "../../../core/models/support/IntnlCountryRecord";
import { CountryOption } from "../../cms/countries";
import { MasterDataService } from "../../cms/masterdata.service";
import { LanguageOption } from "../../cms/languages";

@Component({
  selector: "app-edit-support-contact",
  templateUrl: "./edit-support.component.html",
})
export class ConsumerCareEditSupportComponent implements OnInit {
  countryRecord: IntnlCountryRecord;
  isNew: Boolean;
  countries: any;
  languages: any;
  support: any;
  @ViewChild("languagetabs") tabGroup: MatTabGroup;

  constructor(
    private snackBar: MatSnackBar,
    private supportService: SupportContactService,
    private masterService: MasterDataService,
    public dialogRef: MatDialogRef<ConsumerCareEditSupportComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.countryRecord = this.data.countryRecord;
    if (this.data && this.data.countryRecord) {
      this.isNew = false;

      this.support = [];
      const keys = Object.keys(this.countryRecord.support);

      keys.forEach((key) => {
        this.support.push(this.countryRecord.support[key]);
      });
    } else {
      this.isNew = true;
      this.countryRecord = new IntnlCountryRecord();
      this.countryRecord.support = {};
      this.support = [];
    }
  }

  ngOnInit(): void {
    this.masterService.getMasterCountriesList().then((countries) => {
      this.countries = countries;
    });

    this.masterService.getMasterLanguagesList().then((languages) => {
      this.languages = languages;
    });
  }

  isValid(record) {
    return record && record.isoCountryCode;
  }

  save() {
    console.log(this.countryRecord);

    this.countryRecord.support = {};
    this.support.forEach((record) => {
      this.countryRecord.support[record.isoLanguageCode] = record;
    });

    // check required fields
    if (!this.isValid(this.countryRecord)) {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });

      return;
    }

    this.supportService
      .saveSupportContact(this.countryRecord)
      .subscribe((data: any) => {
        // id is assigned means data is saved successfully
        if (data) {
          const msg = this.isNew
            ? "Record created successfully"
            : "Record updated successfully";

          // show notification
          this.snackBar.open(msg, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });

          this.dialogRef.close(true);
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });
          console.error(
            "An error occurred while attempting to save: " +
              this.countryRecord.country
          );
          this.dialogRef.close(false);
        }
      });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  populateCountryCode() {
    let record = this.countries.find(
      (country) => country.displayName === this.countryRecord.country
    );
    this.countryRecord.isoCountryCode = record.code.toUpperCase();
  }

  populateLanguageCode(itemRecord) {
    let record = this.languages.find(
      (language) => language.displayName === itemRecord.language
    );
    //this.support[index].isoLanguageCode = record.code;
    itemRecord.isoLanguageCode = record.code;
  }

  addLanguage() {
    this.support.push({ language: "new" });
    this.tabGroup.selectedIndex = this.support.length - 1;
  }
}
