/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";

import { UserService } from "./users.service";

import { UsersComponent } from "./users.component";
import { UserDetailsComponent } from "./user-details.component";
import { EditUserComponent } from "./edit-user.component";
import { UserLogsDialogComponent } from "./logs/user-logs.dialog";
import { MatGridListModule } from "@angular/material";

@NgModule({
  declarations: [
    UsersComponent,
    UserDetailsComponent,
    EditUserComponent,
    UserLogsDialogComponent,
  ],
  imports: [SharedModule, BrowserModule, MatGridListModule],
  exports: [UserLogsDialogComponent],
  providers: [UserService, Title],
  entryComponents: [UserLogsDialogComponent],
})
export class UsersModule {}
