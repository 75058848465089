/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DevicesService } from "../devices.service";
import { ToastHelperService } from "../../../shared/utility/toast-helper.service";
import "codemirror/mode/javascript/javascript";

@Component({
  selector: "shadow-editor",
  templateUrl: "./shadow-editor.component.html",
})
export class ShadowEditorComponent implements OnInit {
  public shadowJson: string;

  public codeMirrorConfig: any = {
    mode: { name: "javascript", json: true },
    viewportMargin: Infinity,
  };

  private thingName: string;

  constructor(
    private devicesService: DevicesService,
    private toastHelper: ToastHelperService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public ngOnInit() {
    this.thingName = this.route.parent.snapshot.params["thingName"];
    this.devicesService
      .getShadow(this.thingName)
      .subscribe((data) => this.initShadow(data));
  }

  public submit() {
    try {
      let shadow = JSON.parse(this.shadowJson);
      this.devicesService.updateShadow(this.thingName, shadow).subscribe(
        (data) => {
          this.toastHelper.showSuccess("Shadow updated successfully");
          this.router.navigate([
            "/dashboard/devices",
            this.thingName,
            "shadow",
          ]);
        },
        (error) => this.toastHelper.showError(error, "Failed to update shadow")
      );
    } catch (e) {
      this.toastHelper.showError(e, "Failed to parse shadow JSON");
    }
  }

  private initShadow(shadow: any) {
    this.shadowJson = JSON.stringify(shadow, null, 2);
  }
}
