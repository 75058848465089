/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../core/auth/auth.service";
import { AppState } from "../app.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  public localState = { value: "" };

  constructor(
    private titleService: Title,
    public appState: AppState,
    public router: Router,
    public authService: AuthService
  ) {}

  public ngOnInit() {
    this.titleService.setTitle("IoT Portal Dashboard");
  }

  public async logout() {
    this.authService.logout();
  }
}
