/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { WhitelistDeviceService } from "../whitelist-devices.service";
import { ToastHelperService } from "../../../shared/utility/toast-helper.service";
import { WhitelistDevice } from "../../../core/models/whitelist-devices/whitelist-device.model";

@Component({
  selector: "edit-whitelist-device",
  templateUrl: "./edit-whitelist-device.dialog.html",
})
export class EditWhitelistDeviceComponent {
  public device: WhitelistDevice;
  public errorMessage: string;
  public isDuidUserEdited: boolean = false;
  public isSSIDUserEdited: boolean = false;
  public isPasswordUserEdited: boolean = false;
  public loading: boolean = false;
  public recievedOn: Date = null;

  constructor(
    private dialogRef: MatDialogRef<EditWhitelistDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastHelper: ToastHelperService,
    private whitelistDeviceService: WhitelistDeviceService
  ) {
    // Setup the device
    if (data.device) {
      this.device = Object.assign({}, data.device);
      if (this.device.receivedOn) {
        this.recievedOn = new Date(this.device.receivedOn);
      }

      if (this.device.duid) {
        this.isDuidUserEdited = true;
      }

      if (this.device.ssid) {
        this.isSSIDUserEdited = true;
      }

      if (this.device.password) {
        this.isPasswordUserEdited = true;
      }
    } else {
      this.device = new WhitelistDevice();
    }
  }

  public onNoClick(): void {
    this.cancel();
  }

  public cancel() {
    this.dialogRef.close();
  }

  /**
   * Auto-fills the DUID property if the user has not previously edited it.
   */
  public autofillDuid() {
    if (!this.isDuidUserEdited) {
      this.device.duid = this._generateDuid();
    }
  }

  /**
   * Auto-fills the password property if the user has not previously edited it.
   */
  public autofillPassword() {
    if (!this.isPasswordUserEdited) {
      this.device.password = this._generatePassword();
    }
  }

  /**
   * Auto-fills the SSID  property
   */
  public autofillSSID() {
    if (!this.isSSIDUserEdited) {
      this.device.ssid = this._generateSSID();
    }
  }

  /**
   * Auto-fills certain properties if the user has not previously edited them.
   */
  public autofillProperties() {
    this.autofillDuid();
    this.autofillPassword();
    this.autofillSSID();
  }

  /**
   * Gets the text to title the modal based on the device's state.
   */
  public getTitleText() {
    if (this.data && this.data.device && this.data.device.macAddress) {
      return `Edit ${this.data.device.macAddress}`;
    } else if (this.data && this.data.device) {
      return "Edit Device";
    } else {
      return "Add New Device";
    }
  }

  /**
   * Submits the form, saving the device.
   */
  public submit() {
    // Pre-process - need to copy recieved on so that it's stored as a number, as expected
    if (this.recievedOn) {
      this.device.receivedOn = this.recievedOn.getTime();
    }

    this.errorMessage = null;
    this.loading = true;
    this.whitelistDeviceService
      .edit(this.device)
      .finally(() => {
        this.loading = false;
      })
      .subscribe(
        (data) => {
          this.toastHelper.showSuccess(`${this.device.duid} has been updated.`);
          this.dialogRef.close(Object.assign({}, this.device));
        },
        (error) => {
          this.errorMessage =
            error && error.error && error.error.message
              ? error.error.message
              : typeof error.error === "string"
              ? error
              : "Error: Unknown error occured while saving whitelist entry.";
          this.toastHelper.showError(
            error.message,
            `Failed to save ${this.device.duid}. MAC address can only have values A-F 0-9 and ':'`
          );
        }
      );
  }

  /**
   * Generates a duid from the current MAC Address and serial number.
   */
  private _generateDuid(): string {
    if (this.device && this.device.macAddress) {
      return `${this.device.macAddress}_${this.device.macAddress}`;
    }
  }

  /**
   * Generates a password from the current MAC Address.
   */
  private _generatePassword(): string {
    if (this.device && this.device.macAddress) {
      // Remove any colons
      let cleanMac = this.device.macAddress.replace(/[^\w\s]/gi, "");

      // Concat and pull last six
      if (!cleanMac || cleanMac.length < 6) return "";

      return `BB_${cleanMac.slice(-6)}`;
    }

    return "";
  }

  /**
   * Generates a password from the current MAC Address.
   */
  private _generateSSID(): string {
    if (this.device && this.device.macAddress) {
      // Remove any colons
      let cleanMac = this.device.macAddress.replace(/[^\w\s]/gi, "");

      return `BISSELL_${cleanMac}`;
    }

    return "";
  }
}
