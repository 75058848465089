/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { AppConfigService } from "../../../app.config.service";

const uuid = require("uuid");

@Injectable()
export class TermsService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getAllTermsAndConditions(): Observable<any> {
    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL + "/terms"}`
    );
  }
  public geTermsById(id: string): Observable<any> {
    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL + `/terms/${id}`}`
    );
  }
}
