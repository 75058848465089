/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MobileFeatureService } from "./mfeature.service";

import { ActivatedRoute, Router } from "@angular/router";
import {
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatSort,
} from "@angular/material";
import { CountriesService } from "../countries.service";

export interface DialogData {
  refreshGridFn: any;
  sku: string;
}

@Component({
  selector: "app-mobile-features",
  templateUrl: "./mfeature.component.html",
  providers: [MobileFeatureService],
})
export class CmsMobileFeaturesComponent implements OnInit {
  entity;

  isDisabled = true;

  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  constructor(
    private featureService: MobileFeatureService,
    public router: Router,
    public activeRoute: ActivatedRoute
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    // set table columns
    this.displayColumns = [
      "feature",
      "region",
      "sku",
      "appSpec",
      "status",
      "actions",
    ];

    // get data from server
    this.getData();
  }

  getData() {
    let errors;

    this.featureService.getAllMobileFeatures().subscribe(
      (result) => {
        // Handle result
        const items: any = (result as any).items;
        this.dataSource = new MatTableDataSource(items);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );
  }

  onStatusChange(entity) {
    entity.status = entity.status;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
