/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Routes } from "@angular/router";

import { FirmwareComponent } from "./firmware.component";
import { EditFirmwareComponent } from "./edit-firmware.component";
import { CreateFirmwareComponent } from "./create-firmware.component";

export const FirmwareRoutes: Routes = [
  { path: "firmware", component: FirmwareComponent },
  { path: "firmware/create", component: CreateFirmwareComponent },
  { path: "firmware/:id/edit", component: EditFirmwareComponent },
];
