/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject } from "@angular/core";
import { DatePipe } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { PairingError } from "../../../../core/models/pairing/pairing-error.model";

@Component({
  selector: "view-pairing-error",
  templateUrl: "./view-error.dialog.html",
  providers: [DatePipe],
})
export class ViewPairingErrorComponent {
  public errorRecord: PairingError;
  public dateStr: string;

  constructor(
    private dialogRef: MatDialogRef<ViewPairingErrorComponent>,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Setup the device
    if (data.errorRecord) {
      this.errorRecord = Object.assign({}, data.errorRecord);
      var dateObj = new Date(this.errorRecord.date);
      this.dateStr = this.datepipe.transform(dateObj, "medium");
    }
  }

  public onNoClick(): void {
    this.cancel();
  }

  public cancel() {
    this.dialogRef.close();
  }

  /**
   * Submits the form, saving the device.
   */
  public submit() {
    // Pre-process - need to copy recieved on so that it's stored as a number, as expected
  }
}
