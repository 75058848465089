/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";

import { DashboardRoutes } from "./dashboard.routes";

import { DevicesModule } from "./devices/devices.module";
import { MqttModule } from "./mqtt/mqtt.module";
import { UsersModule } from "./users/users.module";
import { TeamsModule } from "./teams/teams.module";
import { FirmwareModule } from "./firmware/firmware.module";
import { WhitelistDevicesModule } from "./whitelist/whitelist-devices.module";
import { BuildmatrixModule } from "./buildmatrix/buildmatrix.module";
import { ReleaseModule } from "./release/release.module";
import { CmsModule } from "./cms/cms.module";
import { ConsumerCareModule } from "./consumercare/consumercare.module";
import { JobsModule } from "./jobs/jobs.module";

import { DashboardComponent } from "./dashboard.component";
import { DashboardHomeComponent } from "./home/dashboard-home.component";
import { DashboardNavComponent } from "./dashboard-nav.component";
import { DashboardHealthComponent } from "./home/component/dashboard-health.component";
import { DashboardOutageComponent } from "./home/component/dashboard-outage.component";

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardHomeComponent,
    DashboardNavComponent,
    DashboardHealthComponent,
    DashboardOutageComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    RouterModule.forChild(DashboardRoutes),
    DevicesModule,
    UsersModule,
    MqttModule,
    TeamsModule,
    FirmwareModule,
    WhitelistDevicesModule,
    BuildmatrixModule,
    ReleaseModule,
    CmsModule,
    ConsumerCareModule,
    JobsModule,
  ],
  exports: [],
  providers: [Title],
})
export class DashboardModule {}
