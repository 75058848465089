/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { SharedModule } from "../../shared/shared.module";

import { ReleaseService } from "./release.service";

import { ReleaseComponent } from "./release.component";
import { EditReleaseComponent } from "./edit-release.component";
import { CKEditorModule } from "ng2-ckeditor";
import { MatPaginatorModule } from "@angular/material";

@NgModule({
  declarations: [ReleaseComponent, EditReleaseComponent],
  imports: [BrowserModule, SharedModule, CKEditorModule, MatPaginatorModule],
  exports: [],
  providers: [Title, ReleaseService],
  entryComponents: [],
})
export class ReleaseModule {}
