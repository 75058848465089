/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { UserService } from "./users.service";
import { User } from "../../core/models/users/user.model";
import { SearchTerm } from "../../core/models/search/search-term.model";

@Component({
  selector: "users",
  templateUrl: "./users.component.html",
})
// UsersComponent managing users list and search
export class UsersComponent implements OnInit {
  public users: User[] = [];
  public partialSearchUsers: User[];
  public searchableAttributes = [
    { displayName: "Name", name: "fullName" },
    { displayName: "Email", name: "email" },
  ];
  public partialSearch: boolean;
  public lastEvaluatedKey: string;
  private lastSearchTerms: SearchTerm[];

  constructor(private titleService: Title, private userService: UserService) {}

  public initSearch(
    searchTerms: SearchTerm[],
    forcePartialSearch?: boolean,
    lastEvaluatedKey?: string
  ) {
    this.lastSearchTerms = searchTerms;
    this.partialSearchUsers = undefined;

    this.userService
      .search(searchTerms, forcePartialSearch, true, lastEvaluatedKey)
      .subscribe((res) => {
        this.partialSearch = res.isPartialSearch;

        if (this.users === undefined || this.users === null) {
          this.users = res.items;
        } else {
          for (let item of res.items) {
            this.users.push(item);
          }
        }

        this.lastEvaluatedKey = res.lastEvaluatedKey;
      });
  }

  public ngOnInit() {
    this.initSearch([], false, undefined);
    this.titleService.setTitle("Users");
  }

  public loadMoreResults() {
    if (
      this.lastSearchTerms.length === 0 ||
      this.lastSearchTerms === undefined
    ) {
      this.initSearch([], false, this.lastEvaluatedKey); // search with lastEvaluatedKey in mind
    } else {
      this.search(this.lastSearchTerms, true);
    }
  }

  public onRemoveSearchTerm(searchTerms: SearchTerm[]) {
    if (!searchTerms.length) {
      this.users = undefined;
      this.partialSearchUsers = undefined;
    }
  }

  public search(searchTerms: SearchTerm[], forcePartialSearch?: boolean) {
    if (!forcePartialSearch) {
      this.users = undefined;
      this.lastSearchTerms = searchTerms;
    }
    this.partialSearchUsers = undefined;

    this.userService
      .search(searchTerms, forcePartialSearch)
      .subscribe((res) => {
        this.partialSearch = res.isPartialSearch;

        if (!forcePartialSearch) {
          this.users = res.items;
        } else {
          this.partialSearchUsers = res.items.filter((item) => {
            return this.users
              .map((user) => {
                return user.userId;
              })
              .indexOf(item.userId);
          });
        }
      });

    this.userService
      .search(searchTerms)
      .subscribe((data) => (this.users = data.items));
  }
}
