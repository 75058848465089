/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { MatPaginatorModule } from "@angular/material";

import { WhitelistDeviceService } from "./whitelist-devices.service";

import { WhitelistDevicesComponent } from "./whitelist-devices.component";
import { EditWhitelistDeviceComponent } from "./edit-whitelist-device-dialog/edit-whitelist-device.dialog";

@NgModule({
  declarations: [WhitelistDevicesComponent, EditWhitelistDeviceComponent],
  imports: [BrowserModule, SharedModule, MatPaginatorModule],
  exports: [],
  providers: [WhitelistDeviceService, Title],
  entryComponents: [EditWhitelistDeviceComponent],
})
export class WhitelistDevicesModule {}
