/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastHelperService } from "../../shared/utility/toast-helper.service";
import { finalize } from "rxjs/operators";
import { FirmwareService } from "./firmware.service";
import { Firmware } from "../../core/models/firmware/firmware.model";
import { FirmwareProduct } from "../../core/models/firmware/firmware-product.model";
import { DeleteConfirmDialogComponent } from "../../shared/dialogs/delete-confirm.dialog";
import { MatDialog } from "@angular/material";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "edit-firmware",
  templateUrl: "./edit-firmware.component.html",
  styleUrls: ["./edit-firmware.component.scss"],
})
export class EditFirmwareComponent implements OnInit {
  public availableJobManifests: string[];
  private errorMessage: string;
  public fileName: string;
  public isEditing: boolean;
  public isLoadingFirmware: boolean = false;
  public isLoadingManifests: boolean = false;
  public model: Firmware = new Firmware();
  public submitting: boolean;

  constructor(
    private firmwareService: FirmwareService,
    private titleService: Title,
    private toastHelper: ToastHelperService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  public ngOnInit() {
    this.titleService.setTitle("Edit Firmware");
    const id = this.route.snapshot.params["id"];
    if (id) {
      this.isLoadingFirmware = true;
      this.firmwareService.get(id).subscribe(
        (data) => {
          this.model = data;

          if (!this.model.products) {
            this.model.products = [];
          }

          // If a model without a process indicator comes back from the API, it's old, and thus V1.
          if (
            this.model.isV1Process === null ||
            this.model.isV1Process === undefined
          ) {
            this.model.isV1Process = true;
          }
        },
        (err) => {
          this.errorMessage = "Failed to load firmware.";
        },
        () => {
          this.isLoadingFirmware = false;
        }
      );
    } else {
      this.model = new Firmware();
      this.model.products = [];
      this.model.isV1Process = false;

      // Put in at least one product by default to force the user to at least think about adding one
      this.onAddProduct();
    }

    this.isEditing = !!id;

    this.isLoadingManifests = true;
    this.firmwareService.getS3JobManifests().subscribe(
      (s3Paths) => {
        const sortedS3Paths = s3Paths.sort((pathA, pathB) => {
          const dateA = new Date(pathA.LastModified);
          const dateB = new Date(pathB.LastModified);

          if (dateB < dateA) return -1;
          else if (dateA < dateB) return 1;
          else return 0;
        });
        this.availableJobManifests = sortedS3Paths;
      },
      (err) => {
        this.errorMessage = "Failed to load job manifests from S3.";
      },
      () => {
        this.isLoadingManifests = false;
      }
    );
  }

  public submit() {
    this.submitting = true;
    if (this.isEditing) {
      this.updateFirmware();
    } else {
      this.createFirmware();
    }
  }

  public delete() {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {
        delete: this.onConfirmDelete,
        message:
          "Are you sure you want to delete this firmware? This action cannot be undone.",
        title: "Confirm deletion",
        entity: this.model,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onConfirmDelete();
      }
    });
  }

  private onConfirmDelete() {
    this.isLoadingFirmware = true;
    this.firmwareService
      .delete(this.model)
      .pipe(finalize(() => (this.isLoadingFirmware = false)))
      .subscribe(
        () => this.onSuccess("The firmware has been deleted successfully"),
        (error) =>
          this.onError(error, "There was an error deleting the firmware")
      );
  }

  private updateFirmware() {
    this.firmwareService
      .update(this.model)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe(
        () => {
          this.onSuccess("The firmware has been saved successfully");
        },
        (err) => {
          let errorMessage: string = "Firmware save failed.";
          let toastMessage: string =
            "There was an error updating the firmware.";
          if (
            err.error &&
            err.error.data &&
            err.error.data.customErrorCode ===
              "FIRMWARE_UPDATE_MALFORMED_JOB_DOC"
          ) {
            errorMessage =
              "Job Document is malformed. Unable to update firmware with that Job Document.";
            toastMessage = "Firmware save failed.";
          }

          this.onError(errorMessage, toastMessage);
        }
      );
  }

  private cleanupStringSpaces() {
    if (this.model.name) {
      // remove the spaces pre and post the name
      this.model.name = this.model.name.trim();
    }
    if (this.model.products) {
      for (let i = 0; i < this.model.products.length; i++) {
        if (this.model.products[i].baseSku) {
          this.model.products[i].baseSku =
            this.model.products[i].baseSku.trim();
        }
      }
    }
  }

  private createFirmware() {
    this.cleanupStringSpaces();

    this.firmwareService
      .create(this.model)
      .pipe(finalize(() => (this.isLoadingFirmware = false)))
      .subscribe(
        (data) => {
          if (data.errorMessage) {
            this.onError(
              data.errorMessage,
              "There was an error creating the firmware"
            );
          } else {
            this.onSuccess("The firmware has been created successfully");
          }
        },
        (err) => {
          let errorMessage: string = "Firmware save failed.";
          let toastMessage: string =
            "There was an error creating the firmware.";
          if (
            err.error &&
            err.error.data &&
            err.error.data.customErrorCode ===
              "FIRMWARE_UPDATE_MALFORMED_JOB_DOC"
          ) {
            errorMessage =
              "Job Document is malformed. Unable to create firmware with that Job Document.";
            toastMessage = "Firmware save failed.";
          }

          this.onError(errorMessage, toastMessage);
        }
      );
  }

  public onFileSelect(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = file.name;

      reader.onloadend = () => {
        this.model.file = reader.result.toString().split(",")[1];
        this.model.path = file.name;
      };

      reader.readAsDataURL(file);
    }
  }

  public onAddProduct() {
    this.model.products.push(new FirmwareProduct());
  }

  public onRemoveProduct(index) {
    this.model.products.splice(index, 1);
  }

  private onSuccess(message: string) {
    this.router.navigate(["/dashboard/firmware"]);
    this.toastHelper.showSuccess(message);
  }

  private onError(error: string, message: string) {
    this.toastHelper.showError(error, message);
    this.errorMessage = error;
  }

  public onFileSelected() {
    this.firmwareService
      .getVersionDetailsForManifest(this.model.path)
      .subscribe((data) => {
        this.model.name = data.name;
        this.model.major = data.majorVersion;
        this.model.minor = data.minorVersion;
        this.model.build = data.buildVersion;
        this.model.type = data.type;
      });
  }
}
