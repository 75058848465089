/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  feature_to_scope_map: [
    {
      featureName: "CmsEdit",
      scopes: ["portal:cms:edit"],
    },
    {
      featureName: "CmsView",
      scopes: ["portal:cms:view"],
    },
    {
      featureName: "MQTT",
      scopes: ["portal:mqtt"],
    },
    {
      featureName: "FirmwareEdit",
      scopes: ["portal:firmware:edit"],
    },
    {
      featureName: "FirmwareView",
      scopes: ["portal:firmware:view"],
    },
    {
      featureName: "Health",
      scopes: ["portal:health:view"],
    },
    {
      featureName: "JobsEdit",
      scopes: ["portal:jobs:edit"],
    },
    {
      featureName: "JobsView",
      scopes: ["portal:jobs:view"],
    },
    {
      featureName: "MobileFeaturesView",
      scopes: ["portal:mobilefeatures:view"],
    },
    {
      featureName: "TeamsControl",
      scopes: ["portal:teams:control"],
    },
    {
      featureName: "TeamsEdit",
      scopes: ["portal:teams:edit"],
    },
    {
      featureName: "TeamsView",
      scopes: ["portal:teams:view"],
    },
    {
      featureName: "ThingsControl",
      scopes: ["portal:things:control"],
    },
    {
      featureName: "ThingsEdit",
      scopes: ["portal:things:edit"],
    },
    {
      featureName: "ThingsView",
      scopes: ["portal:things:view"],
    },
    {
      featureName: "UsersEdit",
      scopes: ["portal:user:edit"],
    },
    {
      featureName: "UsersView",
      scopes: ["portal:user:view"],
    },
    {
      featureName: "WhitelistView",
      scopes: ["portal:whitelist:view"],
    },
    {
      featureName: "WhitelistEdit",
      scopes: ["portal:whitelist:edit"],
    },
    {
      featureName: "CmsMarketingAdmin",
      scopes: ["portal:cms:marketing:admin"],
    },
    {
      featureName: "CmsMarketingAuthor",
      scopes: ["portal:cms:marketing:author"],
    },
    {
      featureName: "CmsMarketingApprover",
      scopes: ["portal:cms:marketing:approver"],
    },
    {
      featureName: "CmsMarketingView",
      scopes: [
        "portal:cms:marketing:view",
        "portal:cms:marketing:author",
        "portal:cms:marketing:approver",
        "portal:cms:marketing:release_agent",
      ],
    },
    {
      featureName: "CmsMarketingReleaseAgent",
      scopes: ["portal:cms:marketing:release_agent"],
    },
  ],
};
