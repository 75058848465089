/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SearchTerm } from "../../core/models/search/search-term.model";
import { SearchAttribute } from "../../core/models/search/search-attribute.model";

@Component({
  selector: "search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  @Input() public placeholder: string = "Search...";
  @Input() public searchableAttributes: SearchAttribute[] = [];

  @Output() public onSearch = new EventEmitter();

  @Output() public onSearchTermAdded = new EventEmitter();
  @Output() public onSearchTermRemoved = new EventEmitter();

  public searchCriteria: SearchTerm = new SearchTerm();
  public partialSearch: boolean;
  public searchTerms: SearchTerm[] = [];
  public queryString: string;

  constructor() {
    //
  }

  public ngOnInit() {
    this.searchCriteria.attribute = this.searchableAttributes[0];
  }

  public addSearchTerm(searchTerm: SearchTerm) {
    this.removeSearchTerm(searchTerm);
    this.searchTerms.push(searchTerm);

    // emit events
    this.onSearchTermAdded.emit(this.searchTerms);
    this.onSearch.emit(this.searchTerms);

    this.searchCriteria = new SearchTerm();
    this.searchCriteria.attribute = searchTerm.attribute;
  }

  public onRemoveSearchTerm(searchTerm: SearchTerm) {
    this.removeSearchTerm(searchTerm);

    // emit events
    this.onSearchTermRemoved.emit(this.searchTerms);
    if (this.searchTerms.length > 0) {
      this.onSearch.emit(this.searchTerms);
    } else {
      this.onSearch.emit();
    }
  }

  public search() {
    this.onSearch.emit(this.queryString);
  }

  private removeSearchTerm(searchTerm: SearchTerm) {
    this.searchTerms = this.searchTerms.filter((item) => {
      return item.attribute !== searchTerm.attribute;
    });
  }
}
