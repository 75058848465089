/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Routes } from "@angular/router";

import { UsersComponent } from "./users.component";
import { UserDetailsComponent } from "./user-details.component";
import { EditUserComponent } from "./edit-user.component";

export const UserRoutes: Routes = [
  { path: "users", component: UsersComponent },
  // { path: "users/create", component: EditUserComponent },
  { path: "users/:id", component: UserDetailsComponent },
  { path: "users/:id/edit", component: EditUserComponent },
];
