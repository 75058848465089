/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable, Injector } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AppState } from "../../app.service";
import { Store } from "@ngrx/store";
import { LoadingState } from "../../../store/modules/loading/loading.model";
import { Increment } from "../../../store/modules/loading/actions/increment.action";
import { Decrement } from "../../../store/modules/loading/actions/decrement.action";
import "rxjs/add/operator/do";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const store: Store<any> = this.injector.get(Store);
    store.dispatch(new Increment());

    return next.handle(request).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          store.dispatch(new Decrement());
        }
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
          store.dispatch(new Decrement());
        }
      }
    );
  }
}
