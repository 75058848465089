/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { LabelsService } from "./labels.service";
import { LanguagesService } from "../languages/languages.service";
import { LabelElement } from "./labels.component";
import { difference, keys, mapKeys, zipObject, lowerFirst } from "lodash";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatDialogConfig,
  MatSnackBar,
  MatSort,
} from "@angular/material";

export interface DatabaseResponse {
  Count: any;
  Items: any[];
  status: string;
}

export interface DialogData {
  refreshGridFn: any;
  languages: string[];
  uploadType: string;
  action: string;
}

export interface LabelElement {
  id: string;
  token: string;
  languageCode: string;
  "en-us": string;
  nonEnglishStringValue: string;
  stringValue?: string;
}

@Component({
  selector: "app-cms-labels",
  templateUrl: "./labels.component.html",
})
export class CmsLabelsComponent implements OnInit {
  name: string;
  displayColumns: string[];
  dataSource: any;
  selectedLanguage: string; // default to English
  selectedLanguageColName: any; // default to English
  loadedData: any[];
  dataArray: any[];
  languages: any[];

  constructor(
    public labelsService: LabelsService,
    public languagesService: LanguagesService,
    public dialog: MatDialog
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    // set default dropdown value
    this.selectedLanguage = "en-us";
    this.selectedLanguageColName = "English";

    // set table columns
    this.displayColumns = [
      "token",
      "en-us",
      "misingLanguages",
      "NonEnglishStringValue",
      "Actions",
    ];

    // get data from server
    this.getLanguages();
    this.getData();
  }

  getLanguages() {
    let errors;

    // get languages first?
    this.languagesService.getAllLanguages().subscribe(
      (result) => {
        // populate languages dropdown
        this.languages = result as any;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      }
    );
  }

  getData() {
    let errors;

    this.labelsService.getLabels().subscribe(
      (result) => {
        // Handle result

        const items: any = (result as any).item.Items;
        this.dataArray = [];

        // do some transformation
        items.forEach((obj) => {
          let misingLanguages = [];
          this.languages.forEach((language) => {
            if (!obj[language["contentLanguageId"]]) {
              misingLanguages.push(language["contentLanguageId"]);
            }
          });

          this.dataArray.push({
            id: obj.id,
            token: obj.Token,
            "en-us": obj["en-us"],
            nonEnglishStringValue: "",
            misingLanguages: misingLanguages.join(", "),
          });
        });

        this.loadedData = items; // keep a copy of original data
        this.dataSource = new MatTableDataSource(this.dataArray);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );
  }

  updateGridData(selectedLanguage) {
    const dataArray = [];

    // do some transformation
    this.loadedData.forEach((obj) => {
      const transformedObj: any = {
        id: obj.id,
        token: obj.token,
        "en-us": obj["en-us"],
        nonEnglishStringValue: "",
      };

      // add the non-english string
      transformedObj.NonEnglishStringValue =
        selectedLanguage === "en-us" ? "" : obj[selectedLanguage];

      // add transformed obj to data array
      dataArray.push(transformedObj);
    });

    // bind new data to table
    this.dataSource = new MatTableDataSource(dataArray);
    this.dataSource.paginator = this.paginator;

    // update column header
    const langObj = (this.languages as any).find(
      (obj) => obj.contentLanguageId === selectedLanguage
    );
    this.selectedLanguageColName = langObj.contentLanguageTitle;
  }

  showFileUploader() {
    const dialogRef = this.dialog.open(CmsFileUploaderDialogComponent, {
      width: "500px",
      data: {
        refreshGridFn: this.getData.bind(this), // refresh data
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  publishData() {
    const dialogRef = this.dialog.open(CmsPublisherDialogComponent, {
      width: "500px",
      data: {
        languages: this.languages,
        action: "publishtos3",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  downloadData() {
    const dialogRef = this.dialog.open(CmsPublisherDialogComponent, {
      width: "500px",
      data: {
        languages: this.languages,
        action: "download",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}

// file uploader
@Component({
  selector: "app-cms-file-uploader-dialog",
  templateUrl: "file-uploader-dialog.component.html",
})
export class CmsFileUploaderDialogComponent {
  selectedFile: any;
  title: string;
  errorMsg: string;

  constructor(
    public dialogRef: MatDialogRef<CmsFileUploaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public labelsService: LabelsService,
    private snackBar: MatSnackBar
  ) {
    this.errorMsg = "";
    this.title = "Select a CSV or JSON file to upload";
  }

  handleFileInput(files: FileList) {
    this.selectedFile = files[0]; // for now only handling one at a time

    // verify type
    if (this.isValidateFileType(this.selectedFile.type)) {
      this.errorMsg = "";
    } else {
      this.errorMsg = this.selectedFile.type + " file type is not supported.";
      this.selectedFile = undefined;
    }
  }

  isValidateFileType(fileType: string) {
    // list of valid types
    const validTypes: string[] = [
      "application/vnd.ms-excel",
      "text/csv",
      "application/json",
    ];

    return validTypes.includes(fileType);
  }

  csvHasValidColumnHeaders(fileText) {
    // check if headers are valid
    const headers = fileText[0].map((x) => lowerFirst(x));
    const validHeaders = ["languageCode", "id", "token", "stringValue"];

    // check to see if both arrays are the same
    return difference(headers, validHeaders).length === 0;
  }

  jsonHasValidColumnHeaders(fileText) {
    const obj = JSON.parse(fileText);

    // TokenRecords required
    if (obj && !obj.TokenRecords) {
      return false;
    }

    const validHeaders = ["LanguageCode", "Version", "TokenRecords"];
    const headers = Object.keys(obj);

    // first check if header values are correct
    if (difference(headers, validHeaders).length !== 0) {
      return false;
    }

    // just check the first ojb's token headers since its most likely a copy/paste
    const objTokenHeaders = Object.keys(obj.TokenRecords[0]);
    const validTokenHeaders = ["Id", "Token", "StringValue"];

    // check if headers are valid
    return difference(objTokenHeaders, validTokenHeaders).length === 0;
  }

  showInvalidColumnsMsg() {
    this.snackBar.open(
      "File does not contain the correct column headers.",
      "Close",
      {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      }
    );
  }

  import() {
    // make sure a file was selected
    if (!this.selectedFile) {
      console.error("No file selected");
      return;
    }

    // process file
    const reader = new FileReader();

    // get file content
    reader.onload = (e: any) => {
      const fileText = reader.result;
      let labelData: LabelElement[];

      // check if file is csv or json
      const fileType: String = this.selectedFile.type;

      if (fileType === "application/json") {
        // validatiion
        if (!this.jsonHasValidColumnHeaders(fileText)) {
          this.showInvalidColumnsMsg();
          return;
        }

        labelData = this.transformJSONText(fileText);
      } else if (
        fileType === "application/vnd.ms-excel" ||
        fileType === "text/csv"
      ) {
        const text = this.labelsService.readExcelFile(e.target.result);

        // validatiion
        if (!this.csvHasValidColumnHeaders(text)) {
          this.showInvalidColumnsMsg();
          return;
        }

        labelData = this.transformCSVText(text);
      } else {
        console.error("File type not supported." + fileType);
        return;
      }

      this.labelsService.batchAddLabel(labelData).subscribe((resp) => {
        // show notification
        this.snackBar.open("Import Complete.", "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["success-snackbar"],
        });

        this.data.refreshGridFn();
      });
    };

    reader.readAsText(this.selectedFile);

    // close modal
    this.dialogRef.close();
  }

  downloadCSVTemplate() {
    const headers = "Id,Token,StringValue,LanguageCode\n";
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(headers);
    hiddenElement.target = "_blank";
    hiddenElement.download = "csv_import_template.csv";
    hiddenElement.click();
  }

  downloadJSONTemplate() {
    const storageObj = {
      LanguageCode: "",
      Version: "",
      TokenRecords: [
        {
          Id: "",
          Token: "",
          StringValue: "",
        },
      ],
    };

    const hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(storageObj, null, "\t"));
    hiddenElement.target = "_blank";
    hiddenElement.download = "json_import_template.json";
    hiddenElement.click();
  }

  // process JSON text file and return array of label
  transformJSONText(fileText: any) {
    const obj = JSON.parse(fileText);
    const newObjArray = [];

    // make all keys lowercase
    obj.TokenRecords.forEach((tr) => {
      const lowerObj = mapKeys(tr, function (v, k) {
        return lowerFirst(k);
      });
      lowerObj.languageCode = obj.LanguageCode;
      newObjArray.push(lowerObj);
    });

    obj.TokenRecords = newObjArray;

    return obj.TokenRecords;
  }

  // process CSV text file and return array of label
  transformCSVText(fileText: any) {
    const result = [];
    const headers = fileText[0].map((x) => lowerFirst(x));

    // accepts two arrays, one of property identifiers and one of corresponding values.
    for (let i = 1; i < fileText.length; i++) {
      result.push(zipObject(headers, fileText[i]));
    }

    return result;
  }

  cancel() {
    this.dialogRef.close();
  }
}

// Publisher
@Component({
  selector: "app-cms-publisher-dialog",
  templateUrl: "publisher-dialog.component.html",
})
export class CmsPublisherDialogComponent {
  selectedLanguage: string; // language to export

  languages: string[];
  action: string;
  actionTitle: string;

  constructor(
    public dialogRef: MatDialogRef<CmsPublisherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public labelsService: LabelsService,
    public snackBar: MatSnackBar
  ) {
    this.languages = this.data.languages;
    this.action = this.data.action;
    console.log(this.action);
    if (this.action === "download") {
      this.actionTitle = "Download";
    } else {
      this.actionTitle = "Export to S3";
    }
  }

  actionClicked(selectedLanguage) {
    if (this.action === "download") {
      this.downloadJson();
    } else {
      // the selector allows only one language selecion
      // henc passing the 0th string from the languages array
      this.publishToS3(this.selectedLanguage);
    }
  }

  downloadJson() {
    this.labelsService.getLabels().subscribe((res: any) => {
      const objTokenRecords = [];

      res.item.Items.forEach((dbResult) => {
        objTokenRecords.push({
          Id: dbResult.id,
          Token: dbResult.token,
          StringValue: dbResult[this.selectedLanguage] || "",
        });
      });

      // today's date with time stamp
      const versionStamp = this.getVersionStamp();

      const parentObj = {
        LanguageCode: this.selectedLanguage,
        Version: versionStamp,
        TokenRecords: objTokenRecords,
      };

      const hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(parentObj, null, "\t"));
      hiddenElement.target = "_blank";
      hiddenElement.download = versionStamp + ".json";
      hiddenElement.click();
    });
  }

  publishToS3(languageCode) {
    this.labelsService.publishLabelsToS3(languageCode).subscribe((res: any) => {
      if (res.status === "Success") {
        this.snackBar.open(res.message, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["success-snackbar"],
        });
      } else {
        this.snackBar.open(res.message, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });
      }
    });
  }

  getVersionStamp(): string {
    const currentDate = new Date();
    const versionStamp =
      currentDate.getFullYear().toString() +
      ("0" + (currentDate.getMonth() + 1)).slice(-2).toString() +
      ("0" + currentDate.getDate()).slice(-2).toString() +
      ("0" + currentDate.getHours()).slice(-2).toString() +
      ("0" + currentDate.getMinutes()).slice(-2).toString();

    return versionStamp;
  }

  cancel() {
    this.dialogRef.close();
  }
}
