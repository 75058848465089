/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatDialog,
} from "@angular/material";
import { ToastHelperService } from "../../../shared/utility/toast-helper.service";
import { IntnlCountryRecord } from "../../../core/models/support/IntnlCountryRecord";
import { SupportContactService } from "./support-contact.service";
import { ConsumerCareEditSupportComponent } from "./edit-support.component";

export interface DialogData {
  refreshGridFn: any;
  countryCode: string;
}

@Component({
  selector: "app-support-contact",
  templateUrl: "./support-contact.component.html",
  providers: [SupportContactService],
  styleUrls: ["./support-contact.scss"],
})
export class ConsumerCareSupportComponent implements OnInit {
  public supportContact: IntnlCountryRecord[] = [];

  public lastEvaluatedKey: string;
  public defaultPageSize: string = "50";
  public searchString: string;

  constructor(
    private dialog: MatDialog,
    private toastHelper: ToastHelperService,
    private supportContactService: SupportContactService
  ) {}

  public ngOnInit() {
    this.loadData();
  }

  public applyFilter(filterValue: string) {
    console.log(filterValue);
    this.searchString = filterValue;
  }

  public clearFilter() {}

  /**
   * Reloads support contact details
   */
  private loadData() {
    this.supportContactService.getAll().subscribe((result) => {
      this.supportContact = result;

      this._resetDataSource();
    });
  }

  /**
   * Resets the support details source to its original state using a new data received.
   */
  private _resetDataSource() {}

  public addNewRecord() {
    const dialogRef = this.dialog.open(ConsumerCareEditSupportComponent, {
      data: {
        countryRecord: null,
      },
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadData();
    });
  }

  public editRecord(countryRecord) {
    const copyrecord = { ...countryRecord };
    const dialogRef = this.dialog.open(ConsumerCareEditSupportComponent, {
      data: {
        countryRecord: copyrecord,
      },
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadData();
    });
  }
}
