/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastHelperService } from "../../../shared/utility/toast-helper.service";

import { DevicesService } from "../devices.service";

@Component({
  selector: "confirm-command-dialog",
  templateUrl: "./confirm-command.dialog.html",
})
export class ConfirmCommandDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmCommandDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private toastHelper: ToastHelperService,
    private devicesService: DevicesService
  ) {}

  public ngOnInit() {
    //
  }

  public onNoClick(): void {
    this.cancel();
  }

  public cancel() {
    this.dialogRef.close();
  }

  public submit() {
    this.devicesService.issueCommand(this.data.id, this.data.command).subscribe(
      (data) => {
        this.toastHelper.showSuccess(
          "The command has been issued successfully"
        );
        this.dialogRef.close(this.data.id);
      },
      (error) =>
        this.toastHelper.showError(error.message, "Failed to issue the command")
    );
  }
}
