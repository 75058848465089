/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { InjectionToken } from "@angular/core";
import { LoadingStore } from "./modules/loading/loading.index";

export const REDUCER_TOKEN = new InjectionToken("Registered Reducers");

export function getReducers() {
  let reducers = {
    ...LoadingStore,
  };
  return reducers;
}

export const reducerProvider = {
  provide: REDUCER_TOKEN,
  useFactory: getReducers,
};
