/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Device } from "../../core/models/devices/device.model";
import { DeviceList } from "../../core/models/devices/device-list.model";
import { SearchTerm } from "../../core/models/search/search-term.model";
import { LogMessage } from "../../core/models/log/log-message.model";
import { DeviceListItem } from "../../core/models/devices/device-list-item.model";
import { AppConfigService } from "../../app.config.service";

@Injectable()
export class DevicesService {
  private sku: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getDeviceList(params: HttpParams): Observable<DeviceList> {
    let deviceListItem: DeviceListItem[] = [];

    let deviceListResult: Observable<DeviceList> = new Observable<DeviceList>(
      (observer) => {
        this.http
          .get<DeviceList>(
            this.appConfig.getConfig().portalApiURL + "/things",
            { params }
          )
          .subscribe((devices) => {
            const deviceList: DeviceList = {} as DeviceList;
            deviceList.isPartialSearch = devices.isPartialSearch;
            deviceList.lastEvaluatedKey = devices.lastEvaluatedKey;
            devices.items.map((device) => {
              const preptareUserData = Object.assign(device);
              // get user details by userId
              if (device.userId) {
                this.http
                  .get<any>(
                    `${this.appConfig.getConfig().portalApiURL + "/users"}/${
                      device.userId
                    }`
                  )
                  .subscribe((user) => {
                    preptareUserData.userName =
                      user && user.fullName
                        ? user.fullName
                        : "Unable to fetch user name";
                    preptareUserData.userEmail =
                      user && user.email
                        ? user.email
                        : "Unable to fetch user email";
                  });
              }
              deviceListItem.push(preptareUserData);
              deviceList.items = deviceListItem;
            });
            observer.next(deviceList);
            observer.complete();
          });
      }
    );
    return deviceListResult;
  }

  public getDevices(
    searchTerms: SearchTerm[],
    forcePartialSearch?: boolean,
    lastEvaluatedKey?: string
  ): Observable<DeviceList> {
    let params = new HttpParams();
    searchTerms =
      searchTerms !== undefined || searchTerms !== null ? searchTerms : [];
    if (searchTerms && searchTerms.length > 0) {
      // Make sure there are search terms
      for (let searchTerm of searchTerms) {
        params = params.set(searchTerm.attribute.name, searchTerm.value);
      }
    } else {
      params.set("searchTerms", null);
    }

    if (forcePartialSearch) {
      params = params.append("forcePartialSearch", "true");
    }

    if (lastEvaluatedKey) {
      params = params.append("lastEvaluatedKey", lastEvaluatedKey);
    }
    const deviceList: Observable<DeviceList> = this.getDeviceList(params);
    return deviceList;
  }

  public getDevicesBySearchTerm(
    searchTerms: SearchTerm[]
  ): Observable<DeviceList> {
    let params = new HttpParams();
    searchTerms =
      searchTerms !== undefined || searchTerms !== null ? searchTerms : [];
    if (searchTerms && searchTerms.length > 0) {
      // Make sure there are search terms
      for (let searchTerm of searchTerms) {
        params = params.set(searchTerm.attribute.name, searchTerm.value);
      }
    } else {
      return null;
    }
    params = params.append("searchByTerms", "true");

    const deviceList: Observable<DeviceList> = this.getDeviceList(params);
    return deviceList;
  }

  public get(thingName: string): Observable<Device> {
    return this.http.get<Device>(
      `${this.appConfig.getConfig().portalApiURL + "/things"}/${thingName}`
    );
  }

  public issueCommand(thingName: string, command: string): Observable<any> {
    return this.http.post<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/things"
      }/${thingName}/commands/${command}`,
      null
    );
  }

  public getLogs(thingName: string, logType: string): Observable<LogMessage[]> {
    return this.http.get<LogMessage[]>(
      `${
        this.appConfig.getConfig().portalApiURL + "/things"
      }/${thingName}/history/${logType}`
    );
  }

  public register(device: Device): Observable<Device> {
    return this.http.post<Device>(
      `${this.appConfig.getConfig().portalApiURL + "/things"}`,
      device
    );
  }

  public deregister(thingName: string): Observable<Device> {
    return this.http.delete<Device>(
      `${this.appConfig.getConfig().portalApiURL + "/things"}/${thingName}`
    );
  }

  public update(device: Device): Observable<any> {
    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/things"}/${
        device.thingName
      }`,
      device
    );
  }

  public unassociateUser(thingName: string): Observable<any> {
    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/things"}/${thingName}`,
      null
    );
  }

  public getShadow(thingName: string): Observable<any> {
    return this.http.get(
      `${
        this.appConfig.getConfig().portalApiURL + "/things"
      }/${thingName}/state`
    );
  }

  public updateShadow(thingName: string, shadow: any): Observable<any> {
    return this.http.put(
      `${
        this.appConfig.getConfig().portalApiURL + "/things"
      }/${thingName}/state`,
      shadow
    );
  }

  public search(
    searchTerms: SearchTerm[],
    forcePartialSearch?: boolean,
    genericSearch?: boolean,
    lastEvaluatedKey?: string
  ): Observable<any> {
    let params = new HttpParams();
    searchTerms =
      searchTerms !== undefined || searchTerms !== null ? searchTerms : [];
    if (searchTerms && searchTerms.length > 0) {
      // Make sure there are search terms
      for (let searchTerm of searchTerms) {
        params = params.set(searchTerm.attribute.name, searchTerm.value);
      }
    } else {
      params = params.set("searchTerms", null);
    }

    if (forcePartialSearch) {
      params = params.append("forcePartialSearch", "true");
    }

    if (genericSearch) {
      params = params.append("useOrConditional", "true");
    }

    if (lastEvaluatedKey) {
      params = params.append("lastEvaluatedKey", lastEvaluatedKey);
    }

    return this.http.get<any>(
      this.appConfig.getConfig().portalApiURL + "/things",
      { params }
    );
  }

  public getSku(): string {
    return this.sku;
  }

  public setSku(sku: string) {
    this.sku = sku;
  }
}
