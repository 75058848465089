/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import * as FileSaver from "file-saver";

@Component({
  selector: "download-certs-dialog",
  templateUrl: "./download-certs.dialog.html",
})
export class DownloadCertsDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DownloadCertsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  public ngOnInit() {
    //
  }

  public onNoClick(): void {
    //
  }

  public onContinue() {
    this.dialogRef.close();
  }

  public downloadCertificate() {
    this.saveFile(
      `${this.data.thingName}-certificate.pem.crt`,
      this.data.certificateData.certificate
    );
  }

  public downloadPrivateKey() {
    this.saveFile(
      `${this.data.thingName}-private.key`,
      this.data.certificateData.privateKey
    );
  }

  public downloadPublicKey() {
    this.saveFile(
      `${this.data.thingName}-public.key`,
      this.data.certificateData.publicKey
    );
  }

  private saveFile(filename: string, data: string) {
    let blob: Blob = new Blob([data], { type: "text/plain" });
    FileSaver(blob, filename);
  }
}
