/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CriteriaFieldsService } from "./criteria-field.service";
import { FieldElement } from "./criteria-field.component";
import { MatSnackBar } from "@angular/material";
import { TableKeyOption, TableKeyOptions } from "../table-key";
import { DataTypeOption, DataTypeOptions } from "../data-type";

@Component({
  selector: "app-cms-edit-field",
  templateUrl: "./edit-field.component.html",
})
export class CmsEditFieldsComponent implements OnInit {
  isNew: boolean;
  field: FieldElement;
  placeholderLabel: string;
  loadedLabelData: any;
  tableKeys: TableKeyOption[] = TableKeyOptions;
  dataTypes: DataTypeOption[] = DataTypeOptions;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public criteriaFieldsService: CriteriaFieldsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      // determine if creating a new field or editing an existing one
      const fieldId = params["fieldId"];
      this.isNew = fieldId ? false : true;

      if (this.isNew) {
        this.field = {
          fieldId: "",
          fieldDisplayName: "",
          table: "",
          field: "",
          key: "",
          type: "",
        };
      } else {
        this.setExistingField(fieldId);
      }
    });
  }

  setExistingField(fieldId: string) {
    this.criteriaFieldsService.getField(fieldId).subscribe((data: any) => {
      /* Expected format
          {
            fieldId: id,
            fieldDisplayName: '',
            table: '',
            field: ''
          }
        */

      // do some transformation
      const obj: FieldElement = {
        fieldId: data.fieldId,
        fieldDisplayName: data.fieldDisplayName,
        table: data.table,
        field: data.field,
        key: data.key,
        type: data.type,
      };

      this.field = obj;
      this.loadedLabelData = data;
    });
  }

  isValid(field) {
    return (
      field &&
      field.fieldDisplayName &&
      field.table &&
      field.field &&
      field.key &&
      field.type
    );
  }

  save() {
    // check required fields
    if (!this.isValid(this.field)) {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });

      return;
    }

    this.criteriaFieldsService
      .addField(this.field, this.isNew)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          const msg = this.isNew
            ? "Field was created successfully"
            : "Field updated successfully";

          // show notification
          this.snackBar.open(msg, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });

          this.router.navigate(["dashboard/cms/criteria-fields"]);
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to save: " + this.field.fieldId
          );
        }
      });
  }

  cancel() {
    this.router.navigate(["dashboard/cms/criteria-fields"]);
  }
}
