/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import {
  Directive,
  OnInit,
  ViewContainerRef,
  TemplateRef,
  ElementRef,
  Input,
} from "@angular/core";
import { UserService } from "../../dashboard/users/users.service";
import { User } from "../../core/models/users/user.model";
import { environment } from "../../../environments/environment";
import { Feature } from "../../core/models/features/feature.model";

@Directive({
  selector: "[showIfHasFeature]",
})
export class ShowIfHasFeatureComponent implements OnInit {
  private currentUser: User;
  private features = [];

  private _features: Array<Feature> = environment.feature_to_scope_map;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.getSelf().subscribe((user) => {
      this.currentUser = user;
      this.updateView();
    });
  }

  @Input()
  set showIfHasFeature(features) {
    this.features = typeof (features === "string") ? [features] : features;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    return this.userService.checkPermission(this.currentUser, this.features);
  }
}
