/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Label } from "../../../core/models/labels/labels.model";
import { Observable } from "rxjs";
import { AppConfigService } from "../../../app.config.service";

const uuid = require("uuid");

@Injectable()
export class DynamicContentService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getAllDynamicContent(): Observable<any> {
    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/cms"
      }/get-all-dynamic-content`
    );
  }

  public getDynamicContent(contentId): Observable<any> {
    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/cms"
      }/get-dynamic-content/${contentId}`
    );
  }

  public addDynamicContent(content): Observable<Label> {
    content.id = content.id ? content.id : uuid();
    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/cms"}/add-dynamic-content`,
      content
    );
  }
}
