/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { OutageService } from "./outage.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatSnackBar,
} from "@angular/material";

export interface DialogData {
  refreshGridFn: any;
  sku: string;
}

@Component({
  selector: "app-outage-notifications",
  templateUrl: "./outage.component.html",
  providers: [OutageService],
})
export class CmsOutageComponent implements OnInit {
  entity;

  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  constructor(
    private outageService: OutageService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    // set table columns
    this.displayColumns = [
      "outageDate",
      "description",
      "cCMessaging",
      "impact",
      "relatedTasks",
      "incidentStatus",
      "outageResolvedDate",
      "actions",
    ];
    // get data from server
    this.getData();
  }

  getData() {
    let errors;
    this.outageService.getAllOutageNotifications().subscribe(
      (result) => {
        // Handle result
        const items: any = result as any;
        this.dataSource = new MatTableDataSource(items);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        this.snackBar.open(error, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });
      },
      () => {
        /** Callback */
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
