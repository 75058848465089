/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import {
  MatPaginatorModule,
  MatDialogModule,
  MatSnackBarModule,
  MatChipsModule,
  MatSortModule,
  MatSlideToggleModule,
} from "@angular/material";
import { SharedModule } from "../../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";

import { ConsumerCareComponent } from "./consumercare.component";
import { ConsumerCarePairingErrorComponent } from "./pairingerrors/pairingerrors.component";
import { PairingErrorsService } from "./pairingerrors/pairingerrors.service";
import { ViewPairingErrorComponent } from "./pairingerrors/view-error-dialog/view-error.dialog";
import { ConsumerCareSupportComponent } from "./support-contact/support-contact.component";
import { ConsumerCareEditSupportComponent } from "./support-contact/edit-support.component";
import { SupportContactService } from "./support-contact/support-contact.service";
import { CountryFilterPipe } from "./support-contact/country.pipe";

@NgModule({
  declarations: [
    ConsumerCareComponent,
    ConsumerCarePairingErrorComponent,
    ViewPairingErrorComponent,
    ConsumerCareSupportComponent,
    ConsumerCareEditSupportComponent,
    CountryFilterPipe,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSnackBarModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatSlideToggleModule,
  ],
  exports: [],
  providers: [Title, PairingErrorsService, SupportContactService],
  entryComponents: [
    ViewPairingErrorComponent,
    ConsumerCareEditSupportComponent,
  ],
})
export class ConsumerCareModule {}
