/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Team } from "../../core/models/teams/team.model";
import { ToastHelperService } from "../utility/toast-helper.service";
import { TeamService } from "../../dashboard/teams/teams.service";
import { Router } from "@angular/router";
import * as _ from "lodash";

@Component({
  selector: "select-team-dialog",
  templateUrl: "./select-team.dialog.html",
})
export class SelectTeamDialogComponent {
  public teams: Team[] = [];
  public selectedTeam: Team;
  public submitting: boolean;
  public searchExecuted: boolean;

  constructor(
    private dialogRef: MatDialogRef<SelectTeamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private toastHelper: ToastHelperService,
    private router: Router,
    private teamService: TeamService
  ) {}

  public searchTeams(query: string) {
    this.submitting = true;
    this.selectedTeam = null;
    this.teamService
      .search(query, true)
      .finally(() => {
        this.submitting = false;
        this.searchExecuted = true;
      })
      .subscribe((data) => {
        const teamIds = _.map(this.data.selectedTeams, "teamId");
        this.teams = data.items.filter((team) => {
          return teamIds.indexOf(team.teamId) < 0;
        });
      });
  }

  public selectTeam(team: Team) {
    this.selectedTeam = team;
  }

  public onNoClick(): void {
    this.cancel();
  }

  public cancel() {
    this.dialogRef.close();
  }

  public createTeam() {
    this.dialogRef.close();
    const urlSplit = this.router.url.split("/");
    const name = urlSplit[urlSplit.length - 1];
    const type = urlSplit[urlSplit.length - 2];
    let queryParams: any = {};
    if (type == "devices") {
      queryParams = {
        thingName: name,
        lastUrl: this.router.url,
      };
    } else if (type == "users") {
      queryParams = {
        userId: name,
        lastUrl: this.router.url,
      };
    }
    // const queryParams = urlSplit[urlSplit.length-2] == 'devices' ?
    //   {'thingName': name} : // if the url is for a device
    //   {'userId': name}; // if the url is for a user
    this.router.navigate(["dashboard/teams/register"], {
      queryParams: queryParams,
    });
  }

  public submit() {
    this.teamService[this.data.saveFunction](
      this.selectedTeam.teamId,
      this.data.entity
    ).subscribe(
      (data) => {
        this.toastHelper.showSuccess(
          `${this.data.entity} has been added to the team`
        );
        this.dialogRef.close(this.selectedTeam);
      },
      (error) =>
        this.toastHelper.showError(
          error.message,
          `Failed to add ${this.data.entity} to the team`
        )
    );
  }
}
