/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component } from "@angular/core";

@Component({
  selector: "no-content",
  template: `
    <div>
      <h1>404: page missing</h1>
    </div>
  `,
})
export class NoContentComponent {}
