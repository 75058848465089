/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar, MatTableDataSource, MatSort } from "@angular/material";
import { HttpClient } from "@angular/common/http";
import { Health } from "../../../core/models/health/health.model";
import { Alarm } from "../../../core/models/health/alarm.model";
import { Observable } from "rxjs";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { OutageService } from "../../cms/outage/outage.service";
import { AppConfigService } from "../../../app.config.service";

@Component({
  selector: "dashboard-health",
  templateUrl: "./dashboard-health.component.html",
  providers: [OutageService],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class DashboardHealthComponent implements OnInit {
  dataSource: MatTableDataSource<Alarm>;
  outageDataSource: any;
  currentFilter: string;
  displayColumns: string[];
  expandedAlarm: Alarm;
  isOutageNotificationExist: boolean;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    "stateValue",
    "alarmName",
    // Other values included in payload
    // "alarmArn","alarmDescription","comparisonOperator","evaluationPeriods",
    // "metricName", "namespace", "period", "stateReason", "stateUpdatedTimestamp",
    // "statistic", "threshold", "treatMissingData"
  ];
  stateColors = {
    ALARM: "#f1432a",
    OK: "#1e88e5", //'#06bc5a',
    INACTIVE: "#f97a1f",
  };
  builtInFilters = {
    hideAutoScaling: "hideAutoScaling",
    alarm: "ALARM",
    insufficientData: "INACTIVE",
    ok: "OK",
  };
  today: number = Date.now();
  constructor(
    private http: HttpClient,
    private outageService: OutageService,
    private snackBar: MatSnackBar,
    private appConfig: AppConfigService
  ) {}

  critialFilters: string[] = [
    "iotcore health alarm",
    "kinesis health alarm",
    "lambda health alarm",
    "s3 health alarm",
    "dynamodb health alarm",
    "route53 health alarm",
    "apigateway health alarm",
    "get mqtt connection lambda alarm",
    "login lambda alarm",
    "thing state firehose alarm",
    "thing runs firehose alarm",
  ];

  displayNameMapping: string[][] = [
    ["iotcore health alarm", "AWS IoT Service"],
    ["kinesis health alarm", "AWS Kinesis Service"],
    ["lambda health alarm", "Bissell micro-services"],
    ["s3 health alarm", "AWS S3 Service"],
    ["dynamodb health alarm", "Database Service"],
    ["route53 health alarm", "AWS Route53 Service"],
    ["apigateway health alarm", "Bissell REST Service"],
    ["get mqtt connection lambda alarm", "Device Connection Service"],
    ["login lambda alarm", "Bissell Login Service"],
    ["thing state firehose alarm", "Bissell Device Communication Service"],
    ["thing runs firehose alarm", "Bissell Device Telemetry Service"],
  ];

  getDisplayName(alarmName: string): string {
    for (let row = 0; row < this.displayNameMapping.length; row++) {
      for (let col = 0; col < 1; col++) {
        if (
          alarmName.toLowerCase().includes(this.displayNameMapping[row][col])
        ) {
          return this.displayNameMapping[row][++col];
        }
      }
    }
  }

  getOutageData() {
    let errors;
    this.outageService.getAllOutageNotifications().subscribe(
      (result) => {
        // Filter Active incident only
        const items: any = result.filter(
          (outage) => outage.incidentStatus === "Active"
        );
        if (!items || !items.length) return;
        this.isOutageNotificationExist = true;
        this.outageDataSource = new MatTableDataSource(items);
      },
      (error) => {
        this.snackBar.open(error, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });
      },
      () => {
        /** Callback */
      }
    );
  }

  ngOnInit() {
    this.getHealth().subscribe((result) => {
      let critialAlarms = [];
      let filters = this.critialFilters;
      result.alarms.forEach((alarm) => {
        filters.forEach((filter) => {
          if (
            alarm.alarmName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
          ) {
            alarm.givenName = this.getDisplayName(alarm.alarmName);
            critialAlarms.push(alarm);
          }
        });
      });
      this.dataSource = new MatTableDataSource(critialAlarms); // init datasource
      this.dataSource.sort = this.sort; // apply sort
      this.today = Date.now(); // update time once we have result
    });
    this.displayColumns = [
      "outageDate",
      "description",
      "cCMessaging",
      "impact",
      "relatedTasks",
      "incidentStatus",
      "outageResolvedDate",
      "action",
    ];
    // Get the outage who's are still active
    this.isOutageNotificationExist = false; // making it false before calling
    // outage Service to hide the view
    this.getOutageData();
  }

  /*
   * Calls the getHealth endpoint
   */
  getHealth(): Observable<Health> {
    return this.http.get<Health>(
      this.appConfig.getConfig().portalApiURL + "/health"
    );
  }

  sendEmail(record: any) {
    this.outageService.sendOutageNotificationByEmail(record).subscribe(
      (result) => {
        this.snackBar.open("Email sent successfully.", "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["success-snackbar"],
        });
      },
      (error) => {
        this.snackBar.open(error, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });
      },
      () => {
        /** Callback */
      }
    );
  }
}
