/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Action } from "@ngrx/store";
import { LoadingState } from "./loading.model";
import { LoadingAction } from "./loading.actions";
import { INCREMENT } from "./actions/increment.action";
import { DECREMENT } from "./actions/decrement.action";

// see loading.index.ts

export function loadingReducer(state: number = 0, action: LoadingAction) {
  switch (action.type) {
    case INCREMENT:
      return state + 1;
    case DECREMENT:
      return Math.max(state - 1, 0);
    default:
      return state;
  }
}
