/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { TagService } from "./tags.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatDialogConfig,
  MatSnackBar,
  MatSort,
} from "@angular/material";

export interface DialogData {
  refreshGridFn: any;
  tag: any;
}

@Component({
  selector: "app-cms-tags",
  templateUrl: "./tags.component.html",
})
export class CmsTagsComponent implements OnInit {
  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  constructor(public dialog: MatDialog, public tagService: TagService) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    // set table columns
    this.displayColumns = ["tag", "title", "description", "actions"];

    // get data from server
    this.getData();
  }

  add() {
    const dialogRef = this.dialog.open(CmsAddTagDialogComponent, {
      width: "500px",
      data: {
        refreshGridFn: this.getData.bind(this), // refresh data
        tag: null,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  delete(event, tag) {
    const dialogRef = this.dialog.open(CmsDeleteTagDialogComponent, {
      width: "500px",
      data: {
        refreshGridFn: this.getData.bind(this), // refresh data.
        tag: tag,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getData() {
    this.tagService.getAllDynamicContentTags().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.item.Items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}

@Component({
  selector: "app-cms-add-tag-dialog",
  templateUrl: "add-tags-dialog.component.html",
})
export class CmsAddTagDialogComponent {
  tag: any;

  constructor(
    public dialogRef: MatDialogRef<CmsAddTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private tagService: TagService,
    public snackBar: MatSnackBar
  ) {
    //
    this.tag = this.data.tag
      ? this.data.tag
      : {
          contentTag: "",
          description: "",
        };
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    // tag required
    if (!this.tag.contentTag || !this.tag.description) {
      return;
    }

    this.tagService.addDynamicContentTag(this.tag).subscribe((data: any) => {
      // notify user of save action
      if (data.status === "Success") {
        // show notification
        this.snackBar.open("Tag was saved successfully.", "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["success-snackbar"],
        });

        // refresh grid
        this.data.refreshGridFn();
      } else {
        // show notification
        this.snackBar.open(data.reason, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });

        console.error(
          "An error occurred while attempting to save: " + data.reason
        );
      }
    });

    this.dialogRef.close();
  }
}

@Component({
  selector: "app-cms-delete-tag-dialog",
  templateUrl: "delete-tags-dialog.component.html",
})
export class CmsDeleteTagDialogComponent {
  tag: any;

  constructor(
    public dialogRef: MatDialogRef<CmsDeleteTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private tagService: TagService,
    public snackBar: MatSnackBar
  ) {
    this.tag = this.data.tag;
  }

  cancel() {
    this.dialogRef.close();
  }

  delete() {
    this.tagService
      .deleteDynamicContentTag(this.tag.contentTag)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          // show notification
          this.snackBar.open("Tag was deleted successfully.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });

          // refresh grid
          this.data.refreshGridFn();
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to delete: " + data.reason
          );
        }
      });

    this.dialogRef.close();
  }
}
