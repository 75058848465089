/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { BhiProductsService } from "../bhi_products.service";
import { ProductType, SpecsType } from "./models";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { ActiveTabService } from "../active-tab.service";

@Component({
  selector: "app-product-specs-tab",
  templateUrl: "./specs.component.html",
  styleUrls: ["./product-tabs.scss"],
  providers: [BhiProductsService],
})
export class SpecsComponent implements OnInit {
  // global variables
  product: ProductType;

  constructor(
    private bhiProductsService: BhiProductsService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private activeTabService: ActiveTabService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      const sku = params["sku"];

      // load product info
      this.getData(sku);
    });
    this.activeTabService.setTab(this);
  }

  getData(sku) {
    let errors;

    this.bhiProductsService.getBhiProduct(sku).subscribe(
      (result) => {
        this.product = result;

        if (!this.product.specs) {
          this.product.specs = {};
        }
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );
  }

  cancel() {
    this.router.navigate(["dashboard/cms/products"]);
  }

  save() {
    this.bhiProductsService
      .putBhiProduct(this.product, false)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          // show notification
          this.snackBar.open("Product updated successfully.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to save: " + this.product.sku
          );
        }
      });
  }
}
