/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable, Injector } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { some } from "lodash";
import URLParse from "url-parse";

const unAuthPaths = [
  // No auth needed for environment.json, and attempting to do so will access appConfig before it is
  // initialized
  /\/assets\//,
  // Same thing here, but this time it's auth0 config that's not initialized
  /\/auth0\/config/,
];

const unAuthHosts = [
  // Criteria added to not add the bearer token when using a presigned URL (s3.amazonaws.com) because
  // the request does not allow an Authorization header in addition to the Signature Query Param
  /s3\.amazonaws\.com/,
];

function isAuthRequest(requestUrl: string) {
  const parsed = new URLParse(requestUrl);

  const requestPath = parsed.pathname;
  const requestHost = parsed.hostname;

  const isAuthPath = !some(unAuthPaths, (path) => requestPath.match(path));
  const isAuthHost = !some(unAuthHosts, (host) => requestHost.match(host));

  return isAuthPath && isAuthHost;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private inj: Injector) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return new Observable<HttpEvent<any>>((observer) => {
      (async () => {
        if (isAuthRequest(request.url)) {
          const authService: AuthService = this.inj.get(AuthService);
          const accessToken = await authService.getToken();

          if (accessToken) {
            request = request.clone({
              setHeaders: { Authorization: `Bearer ${accessToken}` },
            });
          }
        }

        next.handle(request).subscribe(observer);
      })();
    });
  }
}
