/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { FirmwareProduct } from "./firmware-product.model";

export class Firmware {
  public name: string;
  public path: string;
  public version: string;
  public products: FirmwareProduct[];
  public errorMessage: string;
  public file: string;
  public released: boolean;
  public isLatestManufacturingFW: boolean;
  public major: number;
  public minor: number;
  public build: number;
  public isV1Process: boolean;
  public type: string;
  public baseSku: string;
  public checksum: string;
  public languages: string[];

  public getVersionNumber() {
    if (this.major && this.minor && this.build) {
      return `${this.major}.${this.minor}.${this.build}`;
    }

    return "";
  }

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }
}

export enum FirmwareType {
  FullRobot = "full_robot",
  Wifi = "wifi",
  Main = "main",
  Language = "language",
  Invalid = "invalid",
}

export interface FwFileInfo {
  filename: string;
  type: string;
  version: string;
  baseVersion?: string;
  locale?: string;
}

export interface FwUploadPart {
  addr?: Number;
  cs_sha1?: string;
  encrypt?: boolean;
  ptn?: string;
  size?: number;
  src?: string;
  type?: string;
  update?: boolean;
  fill?: Number;
}

export interface FwManifest {
  build_id?: string;
  build_timestamp?: string;
  name?: string;
  parts?: {
    app?: FwUploadPart;
    boot?: FwUploadPart;
    fs?: FwUploadPart;
    nvs?: FwUploadPart;
    otadata?: FwUploadPart;
    pt?: FwUploadPart;
  };
  platform?: string;
  version?: string;
  firmware?: FwFileInfo[];
}

export interface FirmwareUploadResponse {
  success: boolean;
  errorMessage?: string;
}

export interface FwValidationResponse {
  fileInfo: FwFileInfo;
  isValid: boolean;
  errorMessage?: string;
}
