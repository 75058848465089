/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";

import { PublicRoutes } from "./public.routes";

import { PublicLayoutComponent } from "./public-layout.component";
import { LoginComponent } from "./login/login.component";

@NgModule({
  declarations: [PublicLayoutComponent, LoginComponent],
  imports: [SharedModule, RouterModule.forChild(PublicRoutes)],
  exports: [LoginComponent],
  providers: [],
})
export class PublicModule {}
