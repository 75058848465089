/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ToastHelperService } from "../../shared/utility/toast-helper.service";
import { Observable } from "rxjs";
import { AppState } from "../../app.service";
import { Auth0ConfigResponse } from "../models/auth/auth0-config-response.model";
import { UserService } from "../../dashboard/users/users.service";
import { AppConfigService } from "../../app.config.service";
import { memoize, throttle } from "lodash";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";

// Copy-pasted from auth0 SDK
const dedupe = (arr: string[]) => Array.from(new Set(arr));
const getUniqueScopes = (...scopes: string[]) => {
  return dedupe(scopes.join(" ").trim().split(/\s+/)).join(" ");
};

@Injectable()
export class AuthService {
  constructor(
    public appState: AppState,
    public router: Router,
    private httpClient: HttpClient,
    private toastHelperService: ToastHelperService,
    private usersService: UserService,
    private appConfig: AppConfigService
  ) {}

  public getAuth0Config = memoize(() => {
    const config = this.appConfig.getConfig();
    if (!config) {
      throw "tried to access auth0 config before app config was initialized";
    }
    return this.httpClient
      .get<Auth0ConfigResponse>(config.portalApiURL + "/auth0/config")
      .toPromise();
  });

  public getAuth0Client = memoize(async () => {
    const auth0Config = await this.getAuth0Config();
    return await createAuth0Client({
      domain: auth0Config.domain,
      client_id: auth0Config.clientId,
      audience: auth0Config.audience, // add audience to obtain a JWT access token, must match Auth0 API
      useRefreshTokens: true, // automatically requests offline_access scope on loginWithRedirect, loginWithPopup, and getTokenSilently
      cacheLocation: "localstorage", // location of the cached refresh token
    });
  });

  /**
   * Handles post-login stuff
   *
   * @returns Oberservable that enables the application to progress to the next page
   */
  public onLogin() {
    this.appState.set("loggedIn", true);
    // refreshToken and tokenExpiration are handled implicitly by Auth0 so do not need to be set

    return new Observable((observer) => {
      this.usersService.getSelf(true).subscribe(
        (selfResult) => {
          observer.next();
          observer.complete();
        },
        (err) => {
          this.toastHelperService.showError(
            "Unable to fetch user profile",
            "User profile not found"
          );
          observer.next();
          observer.complete();
        }
      );
    });
  }

  /**
   * Logs us out
   */
  public async logout() {
    this.appState.set("loggedIn", false);
    this.usersService.destroySelf();
    const client = await this.getAuth0Client();
    client.logout({
      returnTo: window.location.origin,
    });
  }

  public isAuthenticated(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      (async () => {
        const client = await this.getAuth0Client();
        const authenticated = await client.isAuthenticated();
        observer.next(authenticated);
        observer.complete();
      })();
    });
  }

  // This method is kind of a mess, because we need a couple of features that are present in auth0's
  // implementation of getTokenSilently, but not getTokenWithPopup:
  // - Avoid making a new request if we already have a token cached
  // - Avoid making multiple concurrent requests in the case where we need to refresh the token
  public async getToken() {
    try {
      const client = await this.getAuth0Client();
      const cached = await this.getCachedToken(
        client,
        60 // consider a token expired 60 seconds before its actual expiration timestamp
      );
      if (cached) {
        return cached;
      }
      return await this.getTokenWithPopup(client);
    } catch (err) {
      // Concurrent requests can lead to this error. If it occurs despite our throttling, ignore it
      if (err && err.message === "Invalid state") {
        return null;
      }
      // Other errors mean that we legitimately failed to refresh the token
      this.refreshFailed(err);
      return null;
    }
  }

  // Wrapper, since _getEntryFromCache is private and so are the arguments we need to pass
  private async getCachedToken(
    client: Auth0Client,
    expiryAdjustmentSeconds: number
  ) {
    const cached: string | undefined = await client["_getEntryFromCache"](
      {
        scope: getUniqueScopes(client["defaultScope"], client["scope"]),
        audience: client["options"].audience,
        client_id: client["options"].client_id,
      },
      expiryAdjustmentSeconds
    );
    return cached;
  }

  // We need to throttle this call ourselves, since the auth0 SDK does not, and concurrent calls
  // lead to a race condition that throws an "Invalid state" error. We could use a lock instead, but
  // that adds extra boilerplate and complexity that doesn't seem worth it. But because we don't use
  // a lock, it is still possible to get the "Invalid state" error, so we check for it elsewhere.
  getTokenWithPopup = throttle((client) => client.getTokenWithPopup(), 1000, {
    trailing: false,
  });

  // If we fail to refresh our access token, it means either the browser is blocking pop-ups or our
  // refresh token is expired. Because this can be called rapidly, we throttle displaying the toast
  // messages.
  refreshFailed = throttle(
    (err) => {
      // When the browser blocks the pop-up, auth0 encounters an error like "t.popup is undefined"
      if (err && err.message && err.message.startsWith("t.popup")) {
        this.toastHelperService.showError(
          null,
          "Enable pop-ups to stay logged in!"
        );
      }
      this.toastHelperService.showError(
        null,
        "Session expired. Logging out..."
      );
      setTimeout(() => this.logout(), 8000);
    },
    1000,
    { trailing: false }
  );
}
