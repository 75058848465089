/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

export interface DurationTypeOption {
  viewValue: string;
  value: string;
}

export const DurationTypeOptions: DurationTypeOption[] = [
  { value: "hours", viewValue: "Hours" },
  { value: "days", viewValue: "Days" },
  { value: "months", viewValue: "Months" },
];
