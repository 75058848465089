/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BuildMatrixService } from "./buildmatrix.service";
import { Release } from "../../core/models/buildmatrix/release.model";

enum PipelineTypes {
  PLATFORM = "platform",
  MOBILE = "mobile",
  PRODUCT = "product",
}

@Component({
  selector: "buildmatrix",
  templateUrl: "./buildmatrix.component.html",
})
export class BuildmatrixComponent implements OnInit {
  public releases: Release[] = [];
  constructor(
    private buildMatrixService: BuildMatrixService,
    private titleService: Title
  ) {}

  public ngOnInit() {
    this.buildMatrixService.getReleases().subscribe((result) => {
      this.titleService.setTitle("Build Matrix");
      const allReleases = result.response;

      const mobileReleases = allReleases.filter(
        (r) => r.type.toLowerCase() === PipelineTypes.MOBILE
      );
      const platformReleases = allReleases.filter(
        (r) => r.type.toLowerCase() === PipelineTypes.PLATFORM
      );
      const productRelease = allReleases.filter(
        (r) => r.type.toLowerCase() === PipelineTypes.PRODUCT
      );

      if (mobileReleases.length)
        this.releases.push(this.getCurrentRelease(mobileReleases));
      if (platformReleases.length)
        this.releases.push(this.getCurrentRelease(platformReleases));
      if (productRelease.length)
        this.releases.push(this.getCurrentRelease(productRelease));
    });
  }

  private capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  private getCurrentRelease(releases): Release {
    const sortedReleases = releases.sort((a, b) =>
      a.releaseDate < b.releaseDate ? 1 : -1
    );
    return sortedReleases[0];
  }
}
