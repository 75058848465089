/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppConfigService } from "../../../../app.config.service";

@Injectable()
export class TagService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getAllDynamicContentTags(): Observable<any> {
    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/cms"
      }/get-all-dynamic-content-tags`
    );
  }

  public getDynamicContentTag(contentTag): Observable<any> {
    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/cms"
      }/get-tag/${contentTag}`
    );
  }

  public addDynamicContentTag(tag): Observable<any> {
    return this.http.put<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/cms"
      }/add-dynamic-content-tag`,
      tag
    );
  }

  public deleteDynamicContentTag(contentTag): Observable<any> {
    return this.http.delete<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/cms"
      }/delete-dynamic-content-tag/${contentTag}`
    );
  }
}
