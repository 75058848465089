/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppConfigService } from "../../app.config.service";

@Injectable()
export class CountriesService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public async getCountries() {
    return new Promise((resolve) => {
      this.http
        .get<any>(`${this.appConfig.getConfig().portalApiURL}/cms/countries`)
        .subscribe(
          (res) => {
            if (res.status === "Success") {
              resolve(res.items.Items);
            } else {
              resolve([]);
            }
          },
          (error) => {
            resolve([]);
          }
        );
    });
  }

  public saveCountry(country): Observable<any> {
    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL}/cms/countries`,
      country
    );
  }
}
