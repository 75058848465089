/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { BhiProductsService } from "../bhi_products.service";
import { CountriesService } from "../../countries.service";
import { ConsumablesType } from "./models";
import {
  ConsumablePartType,
  ConsumablePartTypes,
} from "../ConsumablePartTypes";
import { ActivatedRoute, Router } from "@angular/router";
import { filter } from "lodash";
import { ActiveTabService } from "../active-tab.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatDialogConfig,
  MatSnackBar,
  MatSort,
} from "@angular/material";

export interface DialogData {
  refreshGridFn: any;
  sku: string;
}

@Component({
  selector: "app-product-parts-tab",
  templateUrl: "./parts.component.html",
  styleUrls: ["./product-tabs.scss"],
  providers: [BhiProductsService],
})
export class PartsComponent implements OnInit {
  parts: ConsumablesType;
  selectedRegion: string;
  sku: string;

  // populate language dropdown
  regions: any;

  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  constructor(
    private bhiProductsService: BhiProductsService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private activeTabService: ActiveTabService,
    private countriesService: CountriesService
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    // default region
    this.selectedRegion = "";

    // get Region List
    this.countriesService.getCountries().then((countries) => {
      this.regions = countries;
    });

    this.activeRoute.params.subscribe((params) => {
      this.sku = params["sku"];

      // load product info
      this.getData();
    });
    this.activeTabService.setTab(this);
  }

  getData() {
    let errors;

    // set table columns
    this.displayColumns = ["sku", "type", "region"];

    this.bhiProductsService
      .getBhiProductConsumables(this.sku, this.selectedRegion)
      .subscribe(
        (result) => {
          const items: any = result as any;
          this.parts = items;
          this.dataSource = new MatTableDataSource(items);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        (error) => {
          errors = error;
          console.log("There was an error: " + error.message);
        },
        () => {
          /** Callback */
        }
      );
  }

  updateGridData(event) {
    // reload product info
    this.getData();
  }

  add() {
    const dialogRef = this.dialog.open(CmsAddEntityDialogComponent, {
      width: "500px",
      data: {
        refreshGridFn: () => {
          this.getData(); // refresh data
        },
        sku: this.sku,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}

@Component({
  selector: "app-cms-add-entity-dialog",
  templateUrl: "add-entity-dialog.component.html",
  styleUrls: ["./product-tabs.scss"],
})
export class CmsAddEntityDialogComponent {
  entity: any;
  regions: any;

  errorMessage: string;
  showErrorMessage: boolean;

  entityType: ConsumablePartType[] = ConsumablePartTypes;

  constructor(
    public dialogRef: MatDialogRef<CmsAddEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private bhiProductsService: BhiProductsService,
    public snackBar: MatSnackBar,
    private countriesService: CountriesService
  ) {
    this.showErrorMessage = false;

    this.entity = {
      sku: this.data.sku,
    };
  }

  ngOnInit() {
    // get Region List
    this.countriesService.getCountries().then((countries) => {
      this.regions = countries;
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    // check required fields
    if (!(this.entity.region && this.entity.type && this.entity.partSku)) {
      this.showErrorMessage = true;
      this.errorMessage = "Not all required fields were provided";
      console.error(this.errorMessage);
      return;
    }

    this.bhiProductsService
      .putBhiProductConsumables(this.entity)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          // show notification
          this.snackBar.open("Consumable was succesfully added.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });

          // refresh grid
          this.data.refreshGridFn();
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to save: " + data.reason.message
          );
        }
      });

    this.dialogRef.close();
  }
}
