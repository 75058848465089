/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule, Optional, SkipSelf } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Ng2Webstorage } from "ngx-webstorage";

import { AuthService } from "./auth/auth.service";

import { PrivateGuard } from "./router-guards/private.guard";
import { PublicGuard } from "./router-guards/public.guard";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { LoadingInterceptor } from "./http/loading.interceptor";

@NgModule({
  declarations: [],
  imports: [Ng2Webstorage],
  exports: [],
  providers: [
    AuthService,
    PrivateGuard,
    PublicGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded.
                Import Core modules in the AppModule only.`);
    }
  }
}
