/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LogMessage } from "../../core/models/log/log-message.model";

@Component({
  selector: "log-message-dialog",
  templateUrl: "./log-message.dialog.html",
})
export class LogMessageDialogComponent implements OnInit {
  public message: LogMessage;

  constructor(
    public dialogRef: MatDialogRef<LogMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit() {
    this.message = this.data.message;
  }

  public onNoClick(): void {
    this.close();
  }

  public close() {
    this.dialogRef.close();
  }
}
