/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationTemplateService } from "./notification-template.service";
import {
  NotificationTypeOption,
  NotificationTypeOptions,
} from "./notification-type";
import { ConditionTypeOption, ConditionTypeOptions } from "./condition-type";
import { DurationTypeOption, DurationTypeOptions } from "./duration-type";
import { CriteriaOption, CriteriaOptions } from "./criteria-name";
import { reduce } from "lodash";
import { FormControl } from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatSnackBar,
} from "@angular/material";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { CriteriaModel, CriteriaFieldModel } from "./criteria.model";
import { CriteriaFieldsService } from "../criteria-fields/criteria-field.service";
import { TemplateFieldsService } from "../template-fields/template-field.service";
import { CountriesService } from "../countries.service";

@Component({
  selector: "app-cms-edit-content",
  templateUrl: "./edit-notification-template.component.html",
})
export class CmsEditNotificationTemplateComponent implements OnInit {
  // content
  id: string;
  isNew: boolean;
  content: any;
  isView: boolean;

  @ViewChild("editor") ckeditor: any;
  showEditor: boolean;

  // imported static values
  notificationTypes: NotificationTypeOption[] = NotificationTypeOptions;
  conditionTypes: ConditionTypeOption[] = ConditionTypeOptions;
  durationTypes: DurationTypeOption[] = DurationTypeOptions;
  criteriaOptions: CriteriaOption[] = CriteriaOptions;
  languages: string[];

  // chips
  skuCtrl = new FormControl();
  filteredSKUs: Observable<any[]>;
  removable = true;
  skus = []; // all actively selected tags
  allSKUs = []; // tag options from server

  // country chips
  countryCtrl = new FormControl();
  filteredCountries: Observable<any[]>;
  countries = []; // all actively selected tags
  allCountries = []; // tag options from server
  messageFieldsList = [];

  criteriaFieldList = [];
  durationType = "hours";
  localPostDate: Date = null;
  localRemoveDate: Date = null;

  ckEditorConfig = {
    removeButtons:
      "Save,NewPage,Print,Templates,PasteFromWord,Find,Replace,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Flash,Iframe,About,CreatePlaceholder",
    disallowedContent: "script; *[on*]",
    extraPlugins: "placeholder_select",
    placeholder_select: {
      placeholders: ["name", "address", "productSKU"],
      format: "{{%placeholder%}}",
      title: "Parameters",
      label: "Parameters",
      voiceLabel: "Parameters",
    },
  };

  @ViewChild("chips") chips;
  @ViewChild("tagInput") tagInput: ElementRef;
  @ViewChild("tagCountryInput") tagCountryInput: ElementRef;
  @ViewChild("skuInput") skuInput: ElementRef;

  constructor(
    private activeRoute: ActivatedRoute,
    public router: Router,
    private localService: NotificationTemplateService,
    private snackBar: MatSnackBar,
    private criteriaFieldService: CriteriaFieldsService,
    private templateFieldsService: TemplateFieldsService,
    private countriesService: CountriesService
  ) {
    this.showEditor = false;
  }

  /** */

  removeCountry(tag: any): void {
    const index = this.countries.indexOf(tag);

    if (index >= 0) {
      this.countries.splice(index, 1);
    }
  }

  filterCountries(name: string) {
    return this.allCountries.filter(
      (tag) => tag.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  selectedCountries(event: MatAutocompleteSelectedEvent): void {
    this.countries.push(event.option.viewValue);
    this.tagCountryInput.nativeElement.value = "";
    this.countryCtrl.setValue(null);
  }

  //SKUs
  removeSKU(sku: any): void {
    const index = this.skus.indexOf(sku);
    if (index >= 0) {
      this.skus.splice(index, 1);
    }
  }

  filterSKUs(name: string) {
    return this.allSKUs.filter(
      (sku) => sku.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  selectedSKUs(event: MatAutocompleteSelectedEvent): void {
    this.skus.push(event.option.viewValue);
    this.skuInput.nativeElement.value = "";
    this.skuCtrl.setValue(null);
  }

  ngOnInit(): void {
    // get country chips
    this.setCountries();

    //get product skus
    this.setSKUs();

    //get the critier Fields
    this.setCriteriaField();

    // get the message template fields
    this.setMessageTempalteField();

    // get languages
    this.setLanguages();

    this.activeRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.isNew = this.id ? false : true;
      const operation = params["op"];
      this.isView = operation == "view" ? true : false;
      if (this.isNew) {
        this.content = {
          id: "",
          sku: "",
          title: "",
          isTemplate: false,
          notificationType: "",
          languageCode: "",
          countryAvailability: "",
          postDate: "",
          removeDate: "",
          rule: {},
          expiresIn: 0,
          callToActionText: "",
          callToActionURL: "",
          approved: false,
        };
        var criteriaArray: CriteriaModel[] = [];
        criteriaArray.push(new CriteriaModel());
        this.content.rule.criteria = criteriaArray;
      } else {
        // load existing content
        this.setExistingContent(this.id);
      }
    });
  }

  setCriteriaField() {
    this.criteriaFieldService.getFields().subscribe((result: any) => {
      this.criteriaFieldList = result.item.Items;
    });
  }

  setMessageTempalteField() {
    this.templateFieldsService.getTemplateFields().subscribe((result: any) => {
      this.messageFieldsList = [];
      result.item.Items.forEach((element) => {
        this.messageFieldsList.push(element.id);
      });

      let config = this.ckEditorConfig;

      config.placeholder_select.placeholders = this.messageFieldsList;
      this.ckEditorConfig = config;
      this.showEditor = true;
    });
  }

  addNewRule() {
    this.content.rule.criteria.push(new CriteriaModel());
  }

  criteriaFieldCompare(field1: CriteriaFieldModel, field2: CriteriaFieldModel) {
    return field1.fieldId === field2.fieldId;
  }

  onRuleChange(selectedItem: any) {
    var indexNo = this.criteriaFieldList.indexOf(selectedItem.value);
    var currIndex = this.content.rule.criteria.length - 1;
    this.content.rule.criteria[currIndex].type =
      this.criteriaFieldList[indexNo].type;
  }

  setCountries() {
    this.countriesService.getCountries().then((countries) => {
      // generate correct data structure
      this.allCountries = reduce(
        countries,
        function (arr, elm: any) {
          arr.push(elm.name);
          return arr;
        },
        []
      );

      this.filteredCountries = this.countryCtrl.valueChanges.pipe(
        startWith(null),
        map((country: string | null) =>
          country ? this.filterCountries(country) : this.allCountries.slice()
        )
      );
    });
  }

  setLanguages() {
    let errors;
    this.localService.getLanguages().subscribe(
      (result) => {
        this.languages = result as any;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      }
    );
  }

  setSKUs() {
    this.localService.getProducts().subscribe((res: any) => {
      this.allSKUs = reduce(
        res.item.Items,
        function (arr, elm) {
          arr.push(elm.sku);
          return arr;
        },
        []
      );

      this.filteredSKUs = this.skuCtrl.valueChanges.pipe(
        startWith(null),
        map((sku: string | null) =>
          sku ? this.filterSKUs(sku) : this.allSKUs.slice()
        )
      );
    });
  }

  setExistingContent(id: string) {
    this.localService.getNotificatonTemplate(id).subscribe((data: any) => {
      this.content = data;
      this.skus = data.sku;
      this.countries = data.countryAvailability;
      this.durationType =
        data.expiresIn > 30 * 24 * 3600
          ? "months"
          : data.expiresIn > 24 * 3600
          ? "days"
          : "hours";
      this.content.expiresIn = data.expiresIn / this.getDurationMultiplier();
      this.localPostDate = new Date(data.postDate);
      this.localRemoveDate = new Date(data.removeDate);
    });
  }

  getDurationMultiplier() {
    return this.durationType == "hours"
      ? 3600
      : this.durationType == "days"
      ? 3600 * 24
      : this.durationType == "months"
      ? 3600 * 24 * 30
      : 1;
  }

  isValid(content) {
    if (
      content.title &&
      content.notificationType &&
      content.languageCode &&
      content.message &&
      content.rule.criteria[0].field != undefined &&
      content.rule.criteria[0].operator != undefined &&
      content.rule.criteria[0].compareValue != undefined &&
      content.rule.criteria[0].compareValue != "" &&
      this.localPostDate &&
      this.localRemoveDate &&
      content.expiresIn &&
      content.callToActionText != "" &&
      content.callToActionURL != ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  save() {
    // check required fields
    if (!this.isValid(this.content)) {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });

      return;
    }

    // set list of selected SKUs
    this.content.sku = this.skus;
    // get list of selected countries
    this.content.countryAvailability = this.countries;
    this.content.approved = false;
    this.content.expiresIn =
      this.content.expiresIn * this.getDurationMultiplier();
    this.content.postDate = this.localPostDate.getTime();
    this.content.removeDate = this.localRemoveDate.getTime();
    this.localService
      .addNotificatonTemplate(this.content)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          const msg = this.isNew
            ? "Content was saved successfully."
            : "Content was updated successfully.";

          // show notification
          this.snackBar.open(msg, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });

          this.router.navigate(["dashboard/cms/notification-template"]);
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to save: " + data.reason
          );
        }
      });
  }

  approve() {
    // check required fields
    if (!this.isValid(this.content)) {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });

      return;
    }
    this.localService
      .approveNotificatonTemplate(this.content.id)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          const msg = "Notification Template was approved successfully.";

          // show notification
          this.snackBar.open(msg, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });

          this.router.navigate(["dashboard/cms/notification-template"]);
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to save: " + data.reason
          );
        }
      });
  }

  reject() {
    this.localService
      .rejectNotificatonTemplate(this.content.id)
      .subscribe((data: any) => {
        if (data.status === "Success") {
          const msg = "Notification Template was rejected successfully.";

          this.snackBar.open(msg, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });

          this.router.navigate(["dashboard/cms/notification-template"]);
        } else {
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to save: " + data.reason
          );
        }
      });
  }

  // take user back to search screen
  cancel() {
    this.router.navigate(["dashboard/cms/notification-template"]);
  }
}
