/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TeamService } from "../../dashboard/teams/teams.service";
import { ToastHelperService } from "../utility/toast-helper.service";

@Component({
  selector: "confirm-remove-team",
  templateUrl: "./confirm-remove-team.dialog.html",
})
export class ConfirmRemoveTeamDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmRemoveTeamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastHelper: ToastHelperService,
    private teamService: TeamService
  ) {}

  public onNoClick(): void {
    this.cancel();
  }

  public cancel() {
    this.dialogRef.close();
  }

  public submit() {
    this.teamService[this.data.removeFunction](
      this.data.teamId,
      this.data.entity
    ).subscribe(
      (data) => {
        this.toastHelper.showSuccess(
          `${this.data.entity} has been removed from the team`
        );
        this.dialogRef.close(this.data.teamId);
      },
      (error) =>
        this.toastHelper.showError(
          error.message,
          `Failed to remove ${this.data.entity} from the team`
        )
    );
  }
}
