/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EditTermsService } from "./edit-terms.service";
import { MatSnackBar } from "@angular/material";
import { LanguagesService } from "../../languages/languages.service";
import { CountriesService } from "../../countries.service";

@Component({
  selector: "app-cms-edit-terms",
  templateUrl: "./edit-terms.component.html",
})
export class CmsEditTermsComponent implements OnInit {
  isNew: boolean;
  entity: any;
  placeholderLabel: string;
  loadedLabelData: any;

  // populate language dropdown
  languages: any[];

  regions: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public service: EditTermsService,
    private snackBar: MatSnackBar,
    private languagesService: LanguagesService,
    private countriesService: CountriesService
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      // determine if creating a new label or editing an existing one
      const id = params["id"];
      this.isNew = id ? false : true;

      if (this.isNew) {
        this.entity = {};
      } else {
        this.loadTerms(id);
      }
    });

    this.getLanguages();

    this.countriesService.getCountries().then((countries) => {
      this.regions = countries;
    });
  }

  loadTerms(id: string) {
    this.service.getTerms(id).subscribe((data: any) => {
      this.entity = data;
    });
  }

  getLanguages() {
    let errors;

    this.languagesService.getAllLanguages().subscribe(
      (result) => {
        // populate languages dropdown
        this.languages = result as any;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      }
    );
  }

  isValid(entity) {
    // required fields
    return (
      entity.title &&
      entity.region &&
      entity.languageCode &&
      entity.terms &&
      entity.startDate &&
      entity.endDate
    );
  }

  save() {
    if (!this.isValid(this.entity)) {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });

      return;
    }

    this.service.saveTerms(this.entity).subscribe((data: any) => {
      // notify user of save action
      if (data.status === "Success") {
        // show notification
        this.snackBar.open(
          "Terms and Conditions were succesfully saved.",
          "Close",
          {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          }
        );

        // return to search screen
        this.router.navigate(["dashboard/cms/terms"]);
      } else {
        // show notification
        this.snackBar.open(data.reason, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });

        console.error(
          "An error occurred while attempting to save: " + data.reason.message
        );
      }
    });
  }

  cancel() {
    this.router.navigate(["dashboard/cms/terms"]);
  }
}
