/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { UserService } from "../../dashboard/users/users.service";
import { SearchTerm } from "../../core/models/search/search-term.model";
import { User } from "../../core/models/users/user.model";
import * as _ from "lodash";

@Component({
  selector: "user-picker-multiple",
  templateUrl: "./user-picker-multiple.component.html",
})
export class UserPickerMultipleComponent implements OnInit {
  @Input() public userSelection: User[];
  @Output() public userChange: EventEmitter<User> = new EventEmitter<User>();

  public userFilter: string;
  public users: Observable<User[]>;

  private searchUsersDebounced: any;
  private searchAttributes: any = {
    email: { displayName: "Email", name: "email" },
    name: { displayName: "Name", name: "fullName" },
  };

  constructor(private userService: UserService) {}

  public ngOnInit() {
    this.searchUsersDebounced = _.debounce(this.searchUsers, 300);
  }

  public onKeyUp(event: any) {
    if (this.userFilter && this.userFilter.length >= 3) {
      this.searchUsersDebounced();
    }
  }

  public setUser(user: User) {
    this.userSelection.push(user);
    this.userChange.emit(user);
  }

  public removeUser(user: User) {
    // remove user from list of users
    this.userSelection.splice(this.userSelection.indexOf(user), 1);
    this.userChange.emit(user);
  }

  private searchUsers() {
    const emailTerm = new SearchTerm(
      this.searchAttributes.email,
      this.userFilter
    );
    const nameTerm = new SearchTerm(
      this.searchAttributes.name,
      this.userFilter
    );

    const searchTerms = [emailTerm, nameTerm];
    this.users = this.userService
      .search(searchTerms, true, true)
      .map((data) => data.items);
  }
}
