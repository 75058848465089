/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { UserListModel } from "../../core/models/users/user-list.model";
import { User } from "../../core/models/users/user.model";
import { SearchTerm } from "../../core/models/search/search-term.model";
import { environment } from "../../../environments/environment";
import { Feature } from "../../core/models/features/feature.model";
import { AppConfigService } from "../../app.config.service";

@Injectable()
export class UserService {
  public self: User;
  public selfObservable: Observable<User>;
  private _features: Array<Feature> = environment.feature_to_scope_map;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  /**
   * Checks whether or not a given user has all of the specified permissions.
   * @param user User to evaluate
   * @param features Check if the user has all of these features
   * @returns Whether or not the user has permission
   */
  public checkPermission(user: User, features: any[]): boolean {
    let hasPermission = false;

    if (user && user.scopes) {
      for (const checkFeature of features) {
        const feature = this._features.find(
          (f) => f.featureName.toUpperCase() === checkFeature.toUpperCase()
        );

        if (!feature || !feature.scopes) {
          return;
        }

        const permissionFound = user.scopes.filter((s) => {
          return feature.scopes.indexOf(s) !== -1;
        });
        hasPermission = permissionFound && permissionFound.length > 0;
      }
    }

    return hasPermission;
  }

  /**
   * Checks whether or not the current user has all of the specified permissions.
   * @param user User to evaluate
   * @param features Check if the user has all of these features
   * @returns Whether or not the user has permission
   */
  public curentUserHasPermissions(features: any[]) {
    if (!this.self) return false;

    return this.checkPermission(this.self, features);
  }

  /* Get All users list */
  public getAll(): Observable<UserListModel> {
    return this.http.get<UserListModel>(
      this.appConfig.getConfig().portalApiURL + "/users"
    );
  }

  /* Get user for give id  */
  public get(id: string): Observable<User> {
    return this.http.get<User>(
      `${this.appConfig.getConfig().portalApiURL}/users/${id}`
    );
  }

  /* Get data for current logged in user */
  public getSelf(forceSelfRefresh: boolean = false): Observable<User> {
    if (!this.selfObservable || forceSelfRefresh) {
      this.selfObservable = this.http
        .get<User>(
          `${this.appConfig.getConfig().portalApiURL + "/user-profile"}/self`
        )
        .map((user) => {
          this.self = user;
          return user;
        })
        .publishReplay(1)
        .refCount();
    }

    return this.selfObservable;
  }

  public destroySelf() {
    this.self = undefined;
  }

  /* create new user */
  public create(user: User): Observable<User> {
    return this.http.post<User>(
      this.appConfig.getConfig().portalApiURL + "/users",
      user
    );
  }

  public update(user: User): Observable<User> {
    return this.http.put<User>(
      `${this.appConfig.getConfig().portalApiURL + "/users"}/${user.userId}`,
      user
    );
  }

  public delete(user: User): Observable<User> {
    return this.http.delete<User>(
      `${this.appConfig.getConfig().portalApiURL + "/users"}/${user.userId}`
    );
  }

  public search(
    searchTerms: SearchTerm[],
    forcePartialSearch?: boolean,
    genericSearch?: boolean,
    lastEvaluatedKey?: string
  ): Observable<UserListModel> {
    let params = new HttpParams();
    searchTerms =
      searchTerms !== undefined || searchTerms !== null ? searchTerms : [];
    if (searchTerms && searchTerms.length > 0) {
      // Make sure there are search terms
      for (let searchTerm of searchTerms) {
        params = params.set(searchTerm.attribute.name, searchTerm.value);
      }
    } else {
      params = params.set("searchTerms", null);
    }

    if (forcePartialSearch) {
      params = params.append("forcePartialSearch", "true");
    }

    if (genericSearch) {
      params = params.append("useOrConditional", "true");
    }

    if (lastEvaluatedKey) {
      params = params.append("lastEvaluatedKey", lastEvaluatedKey);
    }

    return this.http.get<UserListModel>(
      this.appConfig.getConfig().portalApiURL + "/users",
      { params }
    );
  }

  public searchByTerms(searchTerms: SearchTerm[]): Observable<UserListModel> {
    let params = new HttpParams();
    searchTerms =
      searchTerms !== undefined || searchTerms !== null ? searchTerms : [];
    if (searchTerms && searchTerms.length > 0) {
      // Make sure there are search terms
      for (let searchTerm of searchTerms) {
        params = params.set(searchTerm.attribute.name, searchTerm.value);
      }
    } else {
      return null;
    }
    params = params.append("searchByTerms", "true");
    return this.http.get<UserListModel>(
      this.appConfig.getConfig().portalApiURL + "/users",
      { params }
    );
  }

  public resetPasswordRequest(resetBody) {
    return this.http.post<any>(
      this.appConfig.getConfig().portalApiURL + "/users/passwordReset",
      resetBody
    );
  }

  /* Get user log files  */
  public getUserLogFiles(id: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.appConfig.getConfig().portalApiURL + "/users"}/${id}/files`
    );
  }

  /* Get user file logs  */
  public getFileLogs(id: string, fileName: string): Observable<any> {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "text/plain; charset=utf-8"
    );
    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/users"
      }/${id}/logs?fileName=${fileName}`,
      { headers, responseType: "text" as "json" }
    );
  }
}
