/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { startCase } from "lodash";

@Pipe({ name: "BISSELLStartCase" })
export class BISSELLStartCasePipe implements PipeTransform {
  transform(value: any) {
    return startCase(value);
  }
}
