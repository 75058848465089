/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DynamicContentService } from "./dynamic-content.service";
import { TagService } from "./tags/tags.service";
import { LanguagesService } from "../languages/languages.service";
import { CountriesService } from "../countries.service";
import { ContentTypeOption, ContentTypeOptions } from "./content-type";
import { reduce } from "lodash";
import { FormControl } from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatSnackBar,
} from "@angular/material";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-cms-edit-content",
  templateUrl: "./edit-content.component.html",
})
export class CmsEditDynamicContentComponent implements OnInit {
  // content
  id: string;
  isNew: boolean;
  content: any;

  // imported static values
  contentTypes: ContentTypeOption[] = ContentTypeOptions;
  languages: string[];

  // chips
  tagCtrl = new FormControl();
  filteredTags: Observable<any[]>;
  removable = true;
  tags = []; // all actively selected tags
  allTags = []; // tag options from server

  // country chips
  countryCtrl = new FormControl();
  filteredCountries: Observable<any[]>;
  countries = []; // all actively selected tags
  allCountries = []; // tag options from server

  // editor options
  editorConfig =
    "{removeButtons: 'Save,NewPage,Print,Templates,PasteFromWord,Find,Replace,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Flash,Iframe,About'}";

  @ViewChild("chips") chips;
  @ViewChild("tagInput") tagInput: ElementRef;
  @ViewChild("tagCountryInput") tagCountryInput: ElementRef;

  constructor(
    private activeRoute: ActivatedRoute,
    public router: Router,
    private localService: DynamicContentService,
    private tagService: TagService,
    private languagesService: LanguagesService,
    private countriesService: CountriesService,
    private snackBar: MatSnackBar
  ) {}

  remove(tag: any): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  filter(name: string) {
    return this.allTags.filter(
      (tag) => tag.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = "";
    this.tagCtrl.setValue(null);
  }
  /** */

  removeCountry(tag: any): void {
    const index = this.countries.indexOf(tag);

    if (index >= 0) {
      this.countries.splice(index, 1);
    }
  }

  filterCountries(name: string) {
    return this.allCountries.filter(
      (tag) => tag.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  selectedCountries(event: MatAutocompleteSelectedEvent): void {
    this.countries.push(event.option.viewValue);
    this.tagCountryInput.nativeElement.value = "";
    this.countryCtrl.setValue(null);
  }

  ngOnInit(): void {
    // get languages
    this.getLanguages();

    // get tag chips
    this.setTags();

    // get country chips
    this.setCountries();

    this.activeRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.isNew = this.id ? false : true;

      if (this.isNew) {
        this.content = {
          id: "",
          title: "",
          type: "",
          languageCode: "",
          sku: "",
          titleImage: "",
          titleImageAlt: "",
          body: "",
          abstract: "",
          headerImage: "",
          headerImageAlt: "",
          tags: "",
          categories: "",
          countryAvailability: "",
          postDate: "",
          removeDate: "",
        };
      } else {
        // load existing content
        this.setExistingContent(this.id);
      }
    });
  }

  getLanguages() {
    let errors;

    // get languages first
    this.languagesService.getAllLanguages().subscribe(
      (result) => {
        // populate languages dropdown
        this.languages = result as any;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      }
    );
  }

  setTags() {
    this.tagService.getAllDynamicContentTags().subscribe((res: any) => {
      // generate correct data structure
      this.allTags = reduce(
        res.item.Items,
        function (arr, elm) {
          arr.push(elm.contentTag);
          return arr;
        },
        []
      );

      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string | null) =>
          tag ? this.filter(tag) : this.allTags.slice()
        )
      );
    });
  }

  setCountries() {
    this.countriesService.getCountries().then((countries) => {
      // generate correct data structure
      this.allCountries = reduce(
        countries,
        function (arr, elm: any) {
          arr.push(elm.name);
          return arr;
        },
        []
      );

      this.filteredCountries = this.countryCtrl.valueChanges.pipe(
        startWith(null),
        map((country: string | null) =>
          country ? this.filterCountries(country) : this.allCountries.slice()
        )
      );
    });
  }

  setExistingContent(id: string) {
    this.localService.getDynamicContent(id).subscribe((data: any) => {
      this.content = data[0];
      this.tags = data[0].tags;
      this.countries = data[0].countryAvailability;
    });
  }

  isValid(content) {
    return content.type && content.sku && content.title && content.languageCode;
  }

  save() {
    // check required fields
    if (!this.isValid(this.content)) {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });

      return;
    }

    // get list of selected tags
    this.content.tags = this.tags;

    // get list of selected countries
    this.content.countryAvailability = this.countries;

    this.localService.addDynamicContent(this.content).subscribe((data: any) => {
      // notify user of save action
      console.log(data);
      if (data.status === "Success") {
        const msg = this.isNew
          ? "Content was saved successfully."
          : "Content was updated successfully.";

        // show notification
        this.snackBar.open(msg, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["success-snackbar"],
        });

        this.router.navigate(["dashboard/cms/dynamic-content"]);
      } else {
        // show notification
        this.snackBar.open(data.reason, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });

        console.error(
          "An error occurred while attempting to save: " + data.reason
        );
      }
    });
  }

  // take user back to search screen
  cancel() {
    this.router.navigate(["dashboard/cms/dynamic-content"]);
  }
}
