/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

export class FirmwareSkuListModel {
  public created: Date;
  public updated: Date;
  public firmwareId: string;
  public isActive: boolean;
  public name: string;
  public released: boolean;
  public sku: string;
  public versionNumber: string;
}
