/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import {
  Component,
  Input,
  AfterViewChecked,
  OnChanges,
  ElementRef,
} from "@angular/core";
import { HighlightService } from "../../../shared/utility/highlight.service";
import { LogMessage } from "../../../core/models/log/log-message.model";

@Component({
  selector: "device-log",
  templateUrl: "./device-log.component.html",
})
export class DeviceLogComponent implements OnChanges, AfterViewChecked {
  @Input() public messages: LogMessage[] = [];

  constructor(
    private el: ElementRef,
    private highlightService: HighlightService
  ) {}

  public ngAfterViewChecked() {
    this.highlightService.highlight(this.el, ".json");
  }

  public ngOnChanges(changes: any) {
    // re-apply highlighting if the data changes
    if (!changes.messages.firstChange) {
      this.highlightService.highlight(this.el, ".json");
    }
  }
}
