/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Routes } from "@angular/router";
import { PrivateGuard } from "../core/router-guards/private.guard";

import { DevicesRoutes } from "./devices/devices.routes";
import { UserRoutes } from "./users/users.routes";
import { MqttRoutes } from "./mqtt/mqtt.routes";
import { TeamsRoutes } from "./teams/teams.routes";
import { FirmwareRoutes } from "./firmware/firmware.routes";
import { JobsRoutes } from "./jobs/jobs.routes";
import { WhitelistDeviceRoutes } from "./whitelist/whitelist-devices.routes";
import { BuildmatrixRoutes } from "./buildmatrix/buildmatrix.routes";
import { ReleaseRoutes } from "./release/release.routes";
import { CmsRoutes } from "./cms/cms.routes";
import { ConsumerCareRoutes } from "./consumercare/consumercare.routes";

import { DashboardComponent } from "./dashboard.component";
import { DashboardHomeComponent } from "./home/dashboard-home.component";
import { DashboardHealthComponent } from "./home/component/dashboard-health.component";
import { DashboardOutageComponent } from "./home/component/dashboard-outage.component";

export const DashboardRoutes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [PrivateGuard],
    children: [
      {
        path: "",
        component: DashboardHomeComponent,
        children: [
          { path: "", component: DashboardHealthComponent },
          { path: "health", component: DashboardHealthComponent },
          { path: "outage", component: DashboardOutageComponent },
        ],
      },
      ...DevicesRoutes,
      ...UserRoutes,
      ...MqttRoutes,
      ...TeamsRoutes,
      ...FirmwareRoutes,
      ...JobsRoutes,
      ...WhitelistDeviceRoutes,
      ...BuildmatrixRoutes,
      ...ReleaseRoutes,
      ...CmsRoutes,
      ...ConsumerCareRoutes,
    ],
  },
];
