/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { set } from "lodash";
import { BhiProductsService } from "../bhi_products.service";
import { EcommerceType } from "./models";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatPaginator,
  MatTableDataSource,
  MatSnackBar,
  MatSort,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { find } from "lodash";
import { ActiveTabService } from "../active-tab.service";
import { CountriesService } from "../../countries.service";
import { ProductType, ProductUsageDetails } from "./models";

export interface DialogData {
  getProductUsageFn: any;
  sku: string;
  product: ProductType;
  productUsageData: any; // holds object of product.usage
  isNew: boolean;
  usage: any;
}

@Component({
  selector: "app-product-usage-tab",
  templateUrl: "./usage.component.html",
  styleUrls: ["./product-tabs.scss"],
  providers: [BhiProductsService],
})
export class UsageComponent implements OnInit {
  sku: string;
  product: ProductType;
  productUsageData: any; // holds object of product.usage
  productUsageDetails: ProductUsageDetails[];
  dataSource: any;
  displayColumns: string[];

  constructor(
    private bhiProductsService: BhiProductsService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private activeTabService: ActiveTabService,
    private countriesService: CountriesService,
    public dialog: MatDialog
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getProductData() {
    let errors;
    this.productUsageDetails = [];
    this.bhiProductsService.getBhiProduct(this.sku).subscribe(
      (result) => {
        this.product = result;
        if (this.product.usage) {
          this.productUsageData = this.product.usage;
          const productUsageKeys = Object.keys(this.productUsageData);
          for (var usageKey of productUsageKeys) {
            this.productUsageDetails.push(this.productUsageData[usageKey]);
          }
          // Sort array by display order to display correct view
          this.productUsageDetails.sort(
            (a, b) => a.displayOrder - b.displayOrder
          );
          this.dataSource = new MatTableDataSource(this.productUsageDetails);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        errors = error;
      },
      () => {
        /** Callback */
      }
    );
  }

  ngOnInit() {
    this.displayColumns = [
      "name",
      "calculatedUnit",
      "displayOrder",
      "field",
      "maxLife",
      "fieldCompare",
      "unitDisplay",
      "defaultValue",
      "actions",
    ];
    this.activeRoute.params.subscribe((params) => {
      this.sku = params["sku"];

      // load product info
      this.getProductData();
    });
    this.activeTabService.setTab(this);
  }

  openProductUsageDialog(isNew, usage?) {
    const dialogRef = this.dialog.open(CmsProductNewUsageDialogComponent, {
      width: "500px",
      data: {
        getProductUsageFn: () => {
          this.getProductData();
        },
        product: this.product,
        sku: this.sku,
        productUsageData: this.productUsageData,
        isNew: isNew ? true : false,
        usage: usage ? usage : undefined,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  editProductUsage(usage: any) {
    this.openProductUsageDialog(false, usage);
  }

  addProductUsage() {
    this.openProductUsageDialog(true);
  }
}

@Component({
  selector: "app-cms-product-usage-new-dialog",
  templateUrl: "product-usage-new-dialog.component.html",
  styleUrls: ["./product-tabs.scss"],
})
export class CmsProductNewUsageDialogComponent implements OnInit {
  consumableConfig: any;
  product: ProductType;
  productUsageData: any; // holds object of product.usage
  timeUnit: string[] = ["milliseconds", "seconds", "minutes", "hours", "days"];
  volumeUnit: string[] = ["ounces", "milliliters", "liters"];
  weightUnit: string[] = ["ounces", "pounds", "grams", "kilograms"];
  calculatedUnit: string[];
  displayUnit: string[];
  isNew: boolean;

  constructor(
    public dialogRef: MatDialogRef<CmsProductNewUsageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private bhiProductsService: BhiProductsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.product = this.data.product;
    this.productUsageData = this.data.productUsageData;
    this.isNew = this.data.isNew;
    this.consumableConfig = this.isNew ? {} : this.data.usage;
    this.calculatedUnit = this.timeUnit.concat(this.volumeUnit);
    this.calculatedUnit = this.calculatedUnit.concat(this.weightUnit);
    this.displayUnit = this.calculatedUnit;
  }

  cancel() {
    this.dialogRef.close();
  }

  isValid(cConfig) {
    return (
      cConfig &&
      cConfig.name &&
      cConfig.calculatedUnit &&
      cConfig.field &&
      cConfig.unitDisplay &&
      (cConfig.maxLife || cConfig.fieldCompare)
    );
  }

  showNotification(status) {
    if (status === "Success") {
      // show success notification
      this.snackBar.open("Updated successfully", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["success-snackbar"],
      });
    } else {
      // show error notification
      const errMsg =
        "An error occurred while attempting to save: " + this.product.sku;
      this.snackBar.open(errMsg, "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });
    }
  }

  getConfigId(str) {
    str = str.toLowerCase();
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  save() {
    const usageKey = this.consumableConfig.name
      ? this.getConfigId(this.consumableConfig.name)
      : undefined;
    if (usageKey && this.isValid(this.consumableConfig)) {
      // add the new consumableConfig in the productUsageData
      if (!this.productUsageData) {
        this.productUsageData = {};
      }
      set(this.productUsageData, [usageKey], this.consumableConfig);

      // Now save the data
      set(this.product, ["usage"], this.productUsageData);
      // save the product usage
      this.bhiProductsService
        .putBhiProduct(this.product, false)
        .subscribe((data: any) => {
          // notify user of save action
          this.showNotification(data.status);
          // refresh the table once success
          this.data.getProductUsageFn();
        });
      this.dialogRef.close();
    } else {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });
    }
  }

  populateDisplayUnit(givenUnit) {
    if (this.timeUnit.find((unit) => unit === givenUnit)) {
      this.displayUnit = this.timeUnit;
    } else if (this.volumeUnit.find((unit) => unit === givenUnit)) {
      this.displayUnit = this.volumeUnit;
    } else {
      this.displayUnit = this.weightUnit;
    }
  }
}
