/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";

@Injectable()
export class ActiveTabService {
  private tab: any;

  constructor() {}
  //sets tab to active tab of mat-tab-group
  public setTab(tab) {
    this.tab = tab;
  }

  //Calls save method of active mat-tab
  public save() {
    if (this.tab && this.tab.save) {
      this.tab.save();
      return true;
    }
    return false;
  }
}
