/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatTableDataSource,
  MatSort,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatChipInputEvent,
  MatSnackBar,
} from "@angular/material";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ConfigService } from "./config.service";

export interface DialogData {
  getConfigurationsFn: any;
  configName: string;
  key: string;
  data: string[];
  addedConfigList: string[];
  removedConfigList: string[];
}

@Component({
  selector: "app-cms-config",
  templateUrl: "./config.component.html",
  providers: [ConfigService],
})
export class CmsConfigComponent implements OnInit {
  configName: string[];
  displayColumns: string[];
  dataSource: any;
  constructor(
    private configService: ConfigService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.displayColumns = ["configuration", "type", "actions"];
    this.getConfigurations();
  }

  getConfigurations() {
    this.configService.getAllConfigurations().subscribe(
      (result) => {
        this.configName = result.filter((record) => record.config);
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.sort = this.sort;
      },
      (error) => {},
      () => {
        /** Callback */
      }
    );
  }

  updateConfig(record: any) {
    const configName: string = record.config;
    const dialogRef = this.dialog.open(CmsEditConfigComponent, {
      width: "500px",
      data: {
        getConfigurationsFn: () => {
          this.getConfigurations();
        },
        configName: record.config,
        key: record.rangeKey,
        data: record[configName],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  removeConfig(record: any) {
    const configurationname: string = record.config;
    const key: string = record.rangeKey;
    this.configService.removeConfiguration(configurationname, key).subscribe(
      (result) => {
        // notify user of save action
        if (result) {
          // show notification
          this.snackBar.open("Configuration removed successfully.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });
        } else {
          // show notification
          this.snackBar.open("Error occured", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });
        }
        this.getConfigurations();
      },
      (error) => {},
      () => {
        /** Callback */
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addNewConfiguration() {
    const addNewDialog = this.dialog.open(CmsAddConfigComponent, {
      width: "500px",
      data: {
        getConfigurationsFn: () => {
          this.getConfigurations();
        },
      },
    });
    addNewDialog.afterClosed().subscribe((result) => {});
  }
}

@Component({
  selector: "app-add-config",
  templateUrl: "./edit/add-config.component.html",
})
export class CmsAddConfigComponent {
  configurationName: string;
  configurationKey: string;

  constructor(
    public dialogRef: MatDialogRef<CmsAddConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public router: Router,
    public dialog: MatDialog
  ) {}

  addConfiguration() {
    if (!this.configurationName || !this.configurationKey) {
      return;
    }
    const dialogRef = this.dialog.open(CmsEditConfigComponent, {
      width: "500px",
      data: {
        getConfigurationsFn: this.data.getConfigurationsFn,
        configName: this.configurationName,
        key: this.configurationKey,
        data: [],
      },
    });
    // close modal
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}

@Component({
  selector: "app-edit-config",
  templateUrl: "./edit/edit-config.component.html",
  providers: [ConfigService],
})
export class CmsEditConfigComponent implements OnInit {
  dataSource: any;
  configurationName: string;
  displayColumns: string[];
  configurationKey: string;
  configurationValues: any;
  addedConfigList: string[];
  removedConfigList: string[];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private configService: ConfigService,
    public dialogRef: MatDialogRef<CmsEditConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.displayColumns = ["configuration"];
    this.configurationName = this.data.configName;
    this.configurationKey = this.data.key;
    this.configurationValues = this.data.data;
    this.addedConfigList = [];
    this.removedConfigList = [];
  }

  addConfig(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    if (value.trim()) {
      this.configurationValues = Array.from(
        new Set([...this.configurationValues, value.trim()])
      );
      this.addedConfigList = Array.from(
        new Set([...this.addedConfigList, value.trim()])
      );
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  removeConfig(configValue: string) {
    const index = this.configurationValues.indexOf(configValue);
    if (index >= 0) {
      this.configurationValues.splice(index, 1);
      this.removedConfigList = Array.from(
        new Set([...this.removedConfigList, configValue])
      );
    }
  }

  saveConfiguration() {
    // If there is no change in the configuration, return back
    if (
      (!this.addedConfigList || !this.addedConfigList.length) &&
      (!this.removedConfigList || !this.removedConfigList.length)
    ) {
      // show notification
      this.snackBar.open("No change in configuration.", "Close", {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["success-snackbar"],
      });
      this.dialogRef.close(false);
      return;
    }
    const dialogRef = this.dialog.open(CmsConfigDiffComponent, {
      width: "500px",
      data: {
        configName: this.configurationName,
        key: this.configurationKey,
        data: this.configurationValues,
        addedConfigList: this.addedConfigList,
        removedConfigList: this.removedConfigList,
        getConfigurationsFn: this.data.getConfigurationsFn,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(false);
    this.data.getConfigurationsFn();
  }
}

@Component({
  selector: "app-diff-config",
  templateUrl: "./edit/diff-config.component.html",
  providers: [ConfigService],
})
export class CmsConfigDiffComponent implements OnInit {
  configurationName: string;
  configurationKey: string;
  configurationValues: string[];
  addedConfigList: string[];
  removedConfigList: string[];
  finalConfigurationList: string[];

  constructor(
    private configService: ConfigService,
    public dialogRef: MatDialogRef<CmsEditConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public router: Router,
    private snackBar: MatSnackBar
  ) {}

  getCurrentConfigurations(addedValues, removedValues): string[] {
    let configurationValues: string[] = [];
    if (addedValues.length > 0) {
      let nonMatchingValues: string[];
      nonMatchingValues = this.configurationValues.filter((configValue) => {
        const match = addedValues.some(
          (listValue) => listValue === configValue
        );
        if (!match) return configValue;
      });
      configurationValues = nonMatchingValues;
    } else {
      configurationValues = this.configurationValues;
    }
    if (removedValues.length > 0) {
      configurationValues = Array.from(
        new Set([...configurationValues, ...removedValues])
      );
    }
    return configurationValues;
  }

  ngOnInit(): void {
    this.configurationName = this.data.configName;
    this.configurationKey = this.data.key;
    this.configurationValues = this.data.data;
    this.addedConfigList =
      this.data.addedConfigList && this.data.addedConfigList.length
        ? this.data.addedConfigList
        : [];
    this.removedConfigList =
      this.data.removedConfigList && this.data.removedConfigList.length
        ? this.data.removedConfigList
        : [];

    // Remove the newly added values from config values
    this.configurationValues = this.getCurrentConfigurations(
      this.addedConfigList,
      this.removedConfigList
    );
  }

  removeConfig(configValue: string) {
    let index = this.addedConfigList.indexOf(configValue);
    if (index >= 0) {
      this.addedConfigList.splice(index, 1);
    } else {
      // If there is no match with addedConfig must be removedConfig then
      index = this.removedConfigList.indexOf(configValue);
      this.removedConfigList.splice(index, 1);
    }
  }

  saveConfiguration() {
    if (!this.configurationName || !this.configurationKey) {
      return;
    }
    // If there is no change in the configuration, return back
    if (
      (!this.addedConfigList || !this.addedConfigList.length) &&
      (!this.removedConfigList || !this.removedConfigList.length)
    ) {
      // show notification
      this.snackBar.open("No change in configuration.", "Close", {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["success-snackbar"],
      });
      this.dialogRef.close(false);
      return;
    }
    this.finalConfigurationList = this.getCurrentConfigurations(
      this.removedConfigList,
      this.addedConfigList
    );

    const configName: string = this.configurationName;
    const key: string = this.configurationKey;
    const configuration = {
      config: configName,
      rangeKey: key,
    };
    configuration[configName] = this.finalConfigurationList;
    this.configService.putConfiguration(configuration).subscribe(
      (result) => {
        // notify user of save action
        if (result) {
          // show notification
          this.snackBar.open("Configuration added successfully.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });
          this.dialogRef.close(false);
        } else {
          // show notification
          this.snackBar.open("Error occured", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });
        }
        this.data.getConfigurationsFn();
        this.dialogRef.close(false);
      },
      (error) => {},
      () => {
        /** Callback */
      }
    );
  }

  cancel() {
    this.dialogRef.close(false);
    this.data.getConfigurationsFn();
  }
}
