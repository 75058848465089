/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastHelperService } from "../../shared/utility/toast-helper.service";
import { UserService } from "./users.service";
import { User } from "../../core/models/users/user.model";
import { finalize } from "rxjs/operators";
import { Feature } from "../../core/models/features/feature.model";
import { environment } from "../../../environments/environment";
import { findIndex } from "lodash";

export interface Scope {
  name: string;
  subscopes: SubScope[];
  allComplete: boolean;
}

export interface SubScope {
  name: string;
  allow: boolean;
}
@Component({
  selector: "edit-user",
  templateUrl: "./edit-user.component.html",
})
export class EditUserComponent implements OnInit {
  public model: User = new User();
  public isEditing: boolean;
  public submitting: boolean;
  public allscopes: Scope[] = [];
  private _features: Array<Feature> = environment.feature_to_scope_map;
  private errorMessage: string;

  constructor(
    private titleService: Title,
    private userService: UserService,
    private toastHelper: ToastHelperService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    for (let feature of this._features) {
      const scope: Scope = {
        name: feature.featureName,
        subscopes: [],
        allComplete: false,
      };
      for (let subscope of feature.scopes) {
        scope.subscopes.push({ name: subscope, allow: false });
      }
      this.allscopes.push(scope);
    }
    console.log(this.allscopes);
  }

  public ngOnInit() {
    let id = this.route.snapshot.params["id"];
    if (id) {
      this.userService.get(id).subscribe((data) => this.setModel(data));
    }
    this.isEditing = !!id;
    this.titleService.setTitle("Edit User");
  }

  public setModel(data: User) {
    this.model = data;
    for (let scopestring of this.model.scopes) {
      for (let scope of this.allscopes) {
        const index = findIndex(scope.subscopes, (s) => s.name === scopestring);
        if (index > -1) {
          scope.subscopes[index].allow = true;
        }
      }
    }
    for (let scope of this.allscopes) {
      this.updateAllComplete(scope);
    }
  }

  public submit() {
    this.submitting = true;
    if (this.isEditing) {
      this.updateUser();
    } else {
      this.createUser();
    }
  }

  public delete() {
    this.userService.delete(this.model).subscribe(
      () => this.onSuccess("The user has been deleted successfully", ["../.."]),
      (error) => this.onError(error, "There was an error deleting the user")
    );
  }

  public updateAllComplete(scope: Scope) {
    console.log(`Scope: ${scope.name}`);
    scope.allComplete =
      scope.subscopes != null && scope.subscopes.every((t) => t.allow);
  }

  public setAll(completed: boolean, scope: Scope) {
    scope.allComplete = completed;
    if (scope == null) {
      return;
    }
    scope.subscopes.forEach((t) => (t.allow = completed));
  }

  public someComplete(scope: Scope): boolean {
    if (scope.subscopes == null) {
      return false;
    }
    return (
      scope.subscopes.filter((t) => t.allow).length > 0 && !scope.allComplete
    );
  }

  private updateUser() {
    const allScopesString: string[] = [];
    for (let scope of this.allscopes) {
      for (let subscope of scope.subscopes) {
        if (subscope.allow) {
          allScopesString.push(subscope.name);
        }
      }
    }
    this.model.scopes = allScopesString;

    this.userService
      .update(this.model)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe((data) => {
        if (data && data.errorMessage) {
          this.onError(data.errorMessage, "There was an error saving the user");
        } else {
          this.onSuccess("The user has been saved successfully");
        }
      });
  }

  private createUser() {
    this.userService
      .create(this.model)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe((data) => {
        if (data && data.errorMessage) {
          this.onError(
            data.errorMessage,
            "There was an error creating the user"
          );
        } else {
          this.onSuccess("The user has been created successfully", [".."]);
        }
      });
  }

  private onSuccess(message: string, route: any[] = [".."]) {
    this.router.navigate(route, { relativeTo: this.route });
    this.toastHelper.showSuccess(message);
  }

  private onError(error: string, message: string) {
    this.toastHelper.showError(error, message);
    this.errorMessage = error;
  }
}
