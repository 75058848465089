/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import {
  MatPaginatorModule,
  MatDialogModule,
  MatSnackBarModule,
  MatChipsModule,
  MatSortModule,
  MatSlideToggleModule,
} from "@angular/material";
import { SharedModule } from "../../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";

import { CmsComponent } from "./cms.component";
import {
  CmsLabelsComponent,
  CmsFileUploaderDialogComponent,
  CmsPublisherDialogComponent,
} from "./labels/labels.component";
import { CmsEditLabelComponent } from "./labels/edit-label.component";
import { CmsEditProductComponent } from "./products/edit-product.component";
import { CmsCopyProductComponent } from "./products/copy-product.component";
import {
  CmsProductComponent,
  CmsProductAddSkuDialogComponent,
} from "./products/products.component";
import { CmsDynamicContentComponent } from "./dynamic-content/dynamic.component";
import { LabelsService } from "./labels/labels.service";
import { DynamicContentService } from "./dynamic-content/dynamic-content.service";
import { CmsEditDynamicContentComponent } from "./dynamic-content/edit-content.component";
import {
  CmsTagsComponent,
  CmsAddTagDialogComponent,
  CmsDeleteTagDialogComponent,
} from "./dynamic-content/tags/tags.component";
import { TagService } from "./dynamic-content/tags/tags.service";
import { TabsService } from "./products/tabs/tabs.service";
import { DetailsComponent } from "./products/tabs/details.component";
import { SpecsComponent } from "./products/tabs/specs.components";
import {
  DocumentsComponent,
  CmsProductDocumentDialogComponent,
  CmsProductDeleteDocumentDialogComponent,
} from "./products/tabs/documents.component";
import {
  PartsComponent,
  CmsAddEntityDialogComponent,
} from "./products/tabs/parts.component";
import { EcommerceComponent } from "./products/tabs/ecommerce.component";
import { AttributesComponent } from "./products/tabs/attributes.component";
import { BhiProductsService } from "./products/bhi_products.service";
import { BISSELLStartCasePipe } from "./start-case-pipe";
import { CmsTermsComponent } from "./terms/terms.component";
import { TermsService } from "./terms/terms.service";
import { CmsEditTermsComponent } from "./terms/edit/edit-terms.component";
import { EditTermsService } from "./terms/edit/edit-terms.service";
import { CmsCriteriaFieldsComponent } from "./criteria-fields/criteria-field.component";
import { CmsEditFieldsComponent } from "./criteria-fields/edit-field.component";
import { CriteriaFieldsService } from "./criteria-fields/criteria-field.service";
import { CmsTemplateFieldsComponent } from "./template-fields/template-field.component";
import { CmsEditTemplateFieldsComponent } from "./template-fields/edit-template-field.component";
import { TemplateFieldsService } from "./template-fields/template-field.service";
import { CmsNotificationTemplateComponent } from "./notification-template/notification-template.component";
import { CmsEditNotificationTemplateComponent } from "./notification-template/edit-notification-template.component";
import { NotificationTemplateService } from "./notification-template/notification-template.service";

import { CmsMobileFeaturesComponent } from "./mobilefeatures/mfeature.component";
import { CmsEditMobileFeature } from "./mobilefeatures/edit/edit-mfeature.component";
import { EditMobileFeatureService } from "./mobilefeatures/edit/edit-mfeature.service";
import { CmsCopyMobileFeature } from "./mobilefeatures/copyfrom/copy-mfeature.component";
import { CopyMobileFeatureService } from "./mobilefeatures/copyfrom/copy-mfeature.service";

import {
  CmsLanguagesComponent,
  CmsDeleteLngDialogComponent,
} from "./languages/languages.component";
import { CmsEditLanguages } from "./languages/edit/edit-languages.component";
import { LanguagesService } from "./languages/languages.service";
import {
  CmsConfigComponent,
  CmsEditConfigComponent,
  CmsAddConfigComponent,
  CmsConfigDiffComponent,
} from "./config/config.component";

import {
  BaseProductDefinitionComponent,
  AddBaseSkuDialogComponent,
} from "./base-product-definitions/base-product-definitions.component";

import { BaseProductDefinitionService } from "./base-product-definitions/base-product-definitions.service";

import { CountriesService } from "./countries.service";
import { MasterDataService } from "./masterdata.service";
// editor
import { CKEditorModule } from "ng2-ckeditor";

import { ActiveTabService } from "./products/active-tab.service";
import { CmsAvailableCountriesComponent } from "./available-countries/available-countries.component";
import { CmsAddAvailableCountriesComponent } from "./available-countries/add/add-available-countries.component";
import {
  UsageComponent,
  CmsProductNewUsageDialogComponent,
} from "./products/tabs/usage.component";
import {
  Capability,
  CmsProductNewCapabilityDialogComponent,
} from "./products/tabs/capability.component";
import { CmsOutageComponent } from "./outage/outage.component";
import { OutageService } from "./outage/outage.service";
import { CmsEditOutageComponent } from "./outage/edit/edit-outage.component";

@NgModule({
  declarations: [
    CmsComponent,
    CmsFileUploaderDialogComponent,
    CmsPublisherDialogComponent,
    CmsLabelsComponent,
    CmsEditLabelComponent,
    CmsProductComponent,
    CmsProductAddSkuDialogComponent,
    CmsDynamicContentComponent,
    CmsEditProductComponent,
    CmsCopyProductComponent,
    CmsEditDynamicContentComponent,
    CmsTagsComponent,
    CmsAddTagDialogComponent,
    CmsDeleteTagDialogComponent,
    DetailsComponent,
    SpecsComponent,
    DocumentsComponent,
    CmsProductDocumentDialogComponent,
    CmsProductDeleteDocumentDialogComponent,
    PartsComponent,
    CmsAddEntityDialogComponent,
    EcommerceComponent,
    AttributesComponent,
    BISSELLStartCasePipe,
    CmsTermsComponent,
    CmsEditTermsComponent,
    CmsMobileFeaturesComponent,
    CmsEditMobileFeature,
    CmsLanguagesComponent,
    CmsDeleteLngDialogComponent,
    CmsEditLanguages,
    CmsCopyMobileFeature,
    CmsCriteriaFieldsComponent,
    CmsEditFieldsComponent,
    CmsTemplateFieldsComponent,
    CmsEditTemplateFieldsComponent,
    CmsNotificationTemplateComponent,
    CmsEditNotificationTemplateComponent,
    CmsAvailableCountriesComponent,
    CmsAddAvailableCountriesComponent,
    UsageComponent,
    CmsProductNewUsageDialogComponent,
    Capability,
    CmsProductNewCapabilityDialogComponent,
    BaseProductDefinitionComponent,
    AddBaseSkuDialogComponent,
    CmsConfigComponent,
    CmsEditConfigComponent,
    CmsAddConfigComponent,
    CmsConfigDiffComponent,
    CmsOutageComponent,
    CmsEditOutageComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSnackBarModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    MatSortModule,
    MatSlideToggleModule,
  ],
  exports: [],
  providers: [
    Title,
    LabelsService,
    DynamicContentService,
    TagService,
    TabsService,
    BhiProductsService,
    TermsService,
    EditTermsService,
    EditMobileFeatureService,
    CopyMobileFeatureService,
    CriteriaFieldsService,
    TemplateFieldsService,
    NotificationTemplateService,
    ActiveTabService,
    CountriesService,
    LanguagesService,
    MasterDataService,
    BaseProductDefinitionService,
    OutageService,
  ],
  entryComponents: [
    CmsFileUploaderDialogComponent,
    CmsPublisherDialogComponent,
    CmsAddTagDialogComponent,
    CmsAddEntityDialogComponent,
    CmsDeleteTagDialogComponent,
    CmsProductDocumentDialogComponent,
    CmsProductDeleteDocumentDialogComponent,
    CmsProductAddSkuDialogComponent,
    CmsProductNewUsageDialogComponent,
    CmsProductNewCapabilityDialogComponent,
    CmsDeleteLngDialogComponent,
  ],
})
export class CmsModule {}
