/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "public-layout",
  templateUrl: "./public-layout.component.html",
})
export class PublicLayoutComponent implements OnInit {
  constructor() {
    //
  }

  public ngOnInit() {
    //
  }
}
