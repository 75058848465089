/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatDialogConfig,
  MatSnackBar,
  ErrorStateMatcher,
  MatSort,
} from "@angular/material";

import { BaseProductDefinitionService } from "./base-product-definitions.service";
import { CountriesService } from "../countries.service";

import { reduce } from "lodash";

export interface DialogData {
  refreshGridFn: any;
}

@Component({
  selector: "app-cms-base-product-definitions",
  templateUrl: "./base-product-definitions.html",
})
export class BaseProductDefinitionComponent implements OnInit {
  public model: any = {};

  displayColumns: string[];
  dataSource: any;
  loadedData: any[];
  regions: string[];
  selectedRegion: string;

  constructor(
    private baseProductDefinitionService: BaseProductDefinitionService,
    public countriesService: CountriesService,
    public dialog: MatDialog,
    public router: Router,
    public activeRoute: ActivatedRoute
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    // set table columns
    this.displayColumns = ["baseSku"];

    // get data from server
    this.getData();
    this.getRegions();
  }

  getRegions() {
    this.countriesService.getCountries().then((countries) => {
      // generate correct data structure
      this.regions = reduce(
        countries,
        function (arr, elm: any) {
          arr.push(elm.name);
          return arr;
        },
        []
      );
    });
  }

  getData() {
    let errors;

    /*this.baseProductDefinitionService.getProductsByRegion()
    .subscribe(
      result => {
        // Handle result
        const items: any = (result as any).items.Items;
        this.dataSource = new MatTableDataSource(items);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error => {
        errors = error;
        console.log('There was an error: ' + error.message);
      },
      () => {/** Callback }*/
    //);
  }

  updateFieldData() {
    //TODO: Get products based on region
    /*this.dataSource = this.product.details && this.product.details[this.selectedRegion] ?
      this.product.details[this.selectedRegion] :
      {};*/
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  add() {
    const dialogRef = this.dialog.open(AddBaseSkuDialogComponent, {
      width: "500px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}

// new baseSku
@Component({
  selector: "app-cms-product-add-base-sku-dialog",
  templateUrl: "product-add-base-sku-dialog.html",
})
export class AddBaseSkuDialogComponent {
  product = {
    baseSku: "",
  };

  constructor(
    public dialogRef: MatDialogRef<AddBaseSkuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public baseProductDefinitionService: BaseProductDefinitionService,
    private snackBar: MatSnackBar,
    public router: Router
  ) {}

  add() {
    let errors;

    // make sure a file was selected
    if (!this.product.baseSku) {
      console.error("Base SKU required.");
      return;
    }

    /*this.baseProductDefinitionService.putBhiProduct(this.product, true)
      .subscribe(
        result => {
          // Handle result
          if (result.status === 'Error') {
            console.error('There was an error: ' + result.reason);
          } else {
            // go to the new page
            console.log('go to the new page');
            this.router.navigate(['dashboard/cms/products/' + this.product.sku]);
          }
        },
        error => {
          errors = error;
          console.log('There was an error: ' + error.message);
        },
        () => { }
      );*/

    // close modal
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
