/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatDialogConfig,
  MatSnackBar,
  ErrorStateMatcher,
  MatSort,
} from "@angular/material";
import { CmsCopyProductComponent } from "./copy-product.component";
import { BhiProductsService } from "./bhi_products.service";
import { ProductCatsAndSubcats } from "../product-categories";
import { find, map as _map } from "lodash";

export interface DialogData {
  refreshGridFn: any;
}

@Component({
  selector: "app-cms-products",
  templateUrl: "./products.component.html",
})
export class CmsProductComponent implements OnInit {
  public model: any = {};

  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  constructor(
    public bhiProductsService: BhiProductsService,
    public dialog: MatDialog
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public ngOnInit() {
    // set table columns
    this.displayColumns = ["sku", "baseSku", "name", "actions"];

    // get data from server
    this.getData();
  }

  getData() {
    let errors;

    this.bhiProductsService.getBhiProducts().subscribe(
      (result) => {
        // Handle result

        const items: any = result as any;
        this.dataSource = new MatTableDataSource(items);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  add() {
    const dialogRef = this.dialog.open(CmsProductAddSkuDialogComponent, {
      width: "500px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  copy(sourceSku) {
    const dialogRef = this.dialog.open(CmsCopyProductComponent, {
      width: "500px",
      data: { sourceSku: sourceSku },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  // return the name of the product to be displayed in the table view.
  // by default english name is displayed
  getProductName(element) {
    if (element.details) {
      return element.details.name;
    }
    return "";
  }
}

// new sku
@Component({
  selector: "app-cms-product-add-sku-dialog",
  templateUrl: "product-add-sku-dialog.component.html",
})
export class CmsProductAddSkuDialogComponent {
  product = {
    sku: "",
    baseSku: "",
    category: "",
  };
  productCategories = _map(ProductCatsAndSubcats, "category").sort();
  constructor(
    public dialogRef: MatDialogRef<CmsProductAddSkuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public bhiProductsService: BhiProductsService,
    private snackBar: MatSnackBar,
    public router: Router
  ) {}

  selectedCategories(event) {
    this.product.category = event.value;
  }

  add() {
    let errors;

    // make sure a file was selected
    if (!this.product.sku) {
      console.error("SKU required.");
      return;
    }

    this.bhiProductsService.putBhiProduct(this.product, true).subscribe(
      (result) => {
        // Handle result
        if (result.status === "Error") {
          console.error("There was an error: " + result.reason);
        } else {
          // go to the new page
          console.log("go to the new page");
          this.router.navigate(["dashboard/cms/products/" + this.product.sku]);
        }
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );

    // close modal
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
