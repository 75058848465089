/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-consumercare",
  templateUrl: "./consumercare.component.html",
})
export class ConsumerCareComponent implements OnInit {
  public model: any = {};

  constructor(private titleService: Title) {
    //
  }

  public ngOnInit() {
    this.titleService.setTitle("Consumer Care");
  }

  public submit() {
    //
  }
}
