/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { UserService } from "./users.service";
import { User } from "../../core/models/users/user.model";
import { Team } from "../../core/models/teams/team.model";
import { ConfirmRemoveTeamDialogComponent } from "../../shared/confirm-remove-team/confirm-remove-team.dialog";
import { SelectTeamDialogComponent } from "../../shared/select-team/select-team.dialog";
import { ProductImageMap } from "../../shared/product/product-image-map";
import { UserLogsDialogComponent } from "./logs/user-logs.dialog";
import * as FileSaver from "file-saver";
import { TermsService } from "../cms/terms/terms.service";
import { DeleteConfirmDialogComponent } from "../../shared/dialogs/delete-confirm.dialog";
import { DevicesService } from "../devices/devices.service";
import { forkJoin, Observable } from "rxjs";

@Component({
  selector: "user-details",
  templateUrl: "./user-details.component.html",
})

// UserDetailsComponent showing the details and durables of user
export class UserDetailsComponent implements OnInit {
  public user: User;
  private id: string;
  private numErrors: number;
  public logFilesList: string[];
  public fileLogs: string;
  public resetMessage: string = "";
  public resetPasswordStatus: boolean = false;
  public showResetMessage: boolean = false;
  public showEditButton: boolean = false;
  public prouctImageMap;
  public fileNamePrefix: string;
  private termsId: string;

  constructor(
    private titleService: Title,
    private userService: UserService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private termsService: TermsService,
    private devicesService: DevicesService
  ) {
    this.prouctImageMap = new ProductImageMap();
  }

  public ngOnInit() {
    this.numErrors = 0;
    this.id = this.route.snapshot.params["id"];
    this.getUser();
    this.getUserLogFiles();
    this.titleService.setTitle("User Details");
  }

  // public removeFromTeam(team: Team) {
  //   const dialogRef = this.dialog.open(ConfirmRemoveTeamDialogComponent, {
  //     data: {
  //       entity: this.user.userId,
  //       teamId: team.teamId,
  //       removeFunction: "removeUser",
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       this.getUser();
  //     }
  //   });
  // }

  public openTeamsDialog() {
    let dialogRef = this.dialog.open(SelectTeamDialogComponent, {
      data: {
        entity: this.user.userId,
        saveFunction: "addUser",
        selectedTeams: this.user.teams,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getUser();
      }
    });
  }

  public removeAllDevices() {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {
        message:
          "Are you sure you want to delete this device? This action cannot be undone.",
        title: "Confirm deletion",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._onConfirmDeleteAll();
      }
    });
  }

  /**
   * Finalizes the delete action for all devices.
   */
  private _onConfirmDeleteAll() {
    const observables = [];
    for (const device of this.user.devices) {
      observables.push(this.devicesService.deregister(device.thingName));
    }
    forkJoin(observables).subscribe(
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.getUser();
      }
    );
  }

  private getUser() {
    this.userService.get(this.id).subscribe(
      (data) => {
        this.user = data;
        this.getTerms();
      },
      (error) => {
        // try again if failed
        this.numErrors = this.numErrors + 1;
        console.log("got an error: ");
        console.log(error);
        if (this.numErrors <= 5) {
          // only try 5 times
          console.log("Trying again...");
          setTimeout(function () {
            this.getUser();
          }, Math.pow(2000, this.numErrors)); // exponential backoff wait
        }
      }
    );
  }

  private getTerms() {
    this.termsService.geTermsById(this.user.acceptedTermsId).subscribe(
      (data) => {
        this.termsId = data.region.toUpperCase();
      },
      (error) => {
        // try again if failed
        this.numErrors = this.numErrors + 1;
        console.log("got an error: ");
        console.log(error);
        if (this.numErrors <= 5) {
          // only try 5 times
          console.log("Trying again...");
          setTimeout(function () {
            this.getUser();
          }, Math.pow(2000, this.numErrors)); // exponential backoff wait
        }
      }
    );
  }

  private getUserLogFiles() {
    this.userService.getUserLogFiles(this.id).subscribe(
      (data) => {
        this.fileNamePrefix = `mobile_log/${this.id}/`;
        this.logFilesList = data
          .filter((file) => file.includes("mobile_log_"))
          .map((item) => item.slice(this.fileNamePrefix.length));
      },
      (error) => {
        // error
      }
    );
  }

  private getFileLogs(fileName) {
    const s3FileName = this.fileNamePrefix + fileName;
    this.userService.getFileLogs(this.id, s3FileName).subscribe(
      (result) => {
        this.fileLogs = result;
        const dialogRef = this.dialog.open(UserLogsDialogComponent, {
          data: {
            fileLog: this.fileLogs,
            title: fileName,
          },
        });

        dialogRef.afterClosed().subscribe((file) => {
          // do nothing on dialog close
          console.log("in afterClose(): ", file);
          if (file) {
            this.downloadFile(file);
          }
        });
      },
      (error) => {
        // error
      }
    );
  }

  private hideResetMessageWithDelay() {
    setTimeout(
      function () {
        this.showResetMessage = false;
      }.bind(this),
      4000
    );
  }

  public resetpassword() {
    this.userService.resetPasswordRequest({ email: this.user.email }).subscribe(
      (response) => {
        this.resetMessage = "Email sent to user with link to reset password";
        this.resetPasswordStatus = true;
        this.showResetMessage = true;
        this.hideResetMessageWithDelay();
      },
      (error) => {
        this.resetMessage = "Error sending password reset email for user";
        this.resetPasswordStatus = false;
        this.showResetMessage = true;
        this.hideResetMessageWithDelay();
      }
    );
  }

  private downloadFile(fileName) {
    const s3FileName = this.fileNamePrefix + fileName;
    this.userService
      .getFileLogs(this.id, s3FileName)
      .subscribe((fileContents) => {
        let blob: Blob = new Blob([fileContents], { type: "text/plain" });
        if (!fileName.includes(".txt")) {
          fileName = `${fileName}.txt`;
        }
        FileSaver(blob, fileName);
      });
  }
}
