/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatDialog,
} from "@angular/material";
import { ToastHelperService } from "../../../shared/utility/toast-helper.service";
import { PairingError } from "../../../core/models/pairing/pairing-error.model";
import { PairingErrorsService } from "./pairingerrors.service";
import { ViewPairingErrorComponent } from "./view-error-dialog/view-error.dialog";

export interface DialogData {
  refreshGridFn: any;
  sku: string;
}

@Component({
  selector: "app-pairing-errors",
  templateUrl: "./pairingerrors.component.html",
  providers: [PairingErrorsService],
  styleUrls: ["./pairingerror.scss"],
})
export class ConsumerCarePairingErrorComponent implements OnInit {
  public pairingErrors: PairingError[] = [];
  public pairingErrorsSource: MatTableDataSource<PairingError>;
  public columnsToDisplay: string[] = [
    "serialNumber",
    "macAddress",
    "userEmail",
    "date",
    "errorMessage",
    "actions",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public lastEvaluatedKey: string;
  public defaultPageSize: string = "50";
  public searchString: string;

  constructor(
    private dialog: MatDialog,
    private toastHelper: ToastHelperService,
    private pairingErrorService: PairingErrorsService
  ) {}

  public ngOnInit() {
    this._reloadDeviceWhitelist();
  }

  public applyFilter(filterValue: string) {
    this.pairingErrorsSource.filter = filterValue.toLowerCase();
    if (this.pairingErrorsSource.paginator) {
      this.pairingErrorsSource.paginator.firstPage();
    }
  }

  public clearFilter() {
    this.pairingErrorsSource.filter = null;
  }

  /**
   * Reloads the list of whitelist devices and resets the filter.
   */
  private _reloadDeviceWhitelist() {
    this.pairingErrorService
      .getAll(this.lastEvaluatedKey, this.defaultPageSize, null)
      .subscribe((result) => {
        this.pairingErrors = result.response;
        this.lastEvaluatedKey = result.lastEvaluatedKey;
        this._resetDataSource();
      });
  }

  /**
   * Resets the whitelist data source to its original state using a current list of devices.
   * @param whitelistDevices Devices to display in the table
   */
  private _resetDataSource() {
    this.pairingErrorsSource = new MatTableDataSource(this.pairingErrors);
    this.pairingErrorsSource.paginator = this.paginator;
    this.pairingErrorsSource.sort = this.sort;

    const sortState: any = { active: "date", direction: "desc" };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  public loadMoreResults() {
    this._reloadDeviceWhitelist();
  }
  public resetResults() {
    this.lastEvaluatedKey = null;
    this.searchString = "";
    this._reloadDeviceWhitelist();
  }

  public fetchedFilteredData(filterValue: string) {
    this.searchString = filterValue;
    this.pairingErrorService
      .getAll(null, null, this.searchString)
      .subscribe((result) => {
        this.pairingErrors = result.response;
        this.lastEvaluatedKey = result.lastEvaluatedKey;
        this._resetDataSource();
      });
  }

  public viewDetails(errorRecord: PairingError) {
    const dialogRef = this.dialog.open(ViewPairingErrorComponent, {
      data: {
        errorRecord: errorRecord,
      },
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
