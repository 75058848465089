/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ReleaseListModel } from "../../core/models/buildmatrix/release-list.model";
import { Release } from "../../core/models/buildmatrix/release.model";
import { AppConfigService } from "../../app.config.service";

const uuid = require("uuid");

@Injectable()
export class ReleaseService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  /**
   * Gets all existing Release manifests.
   */
  public getAll(): Observable<ReleaseListModel> {
    return this.http.get<ReleaseListModel>(
      `${
        this.appConfig.getConfig().portalApiURL + "/releases"
      }/get-all-releases`
    );
  }

  public getRelease(id: string): Observable<Release> {
    return this.http.get<Release>(
      `${
        this.appConfig.getConfig().portalApiURL + "/releases"
      }/get-release/${id}`
    );
  }

  public addRelease(content): Observable<any> {
    content.id = content.id ? content.id : uuid();
    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/releases"}/add-release`,
      content
    );
  }
}
