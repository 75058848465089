/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { ProductType, DetailsType } from "./models";
import { ActivatedRoute, Router } from "@angular/router";
import { BhiProductsService } from "../bhi_products.service";
import { MatSnackBar } from "@angular/material";
import { set } from "lodash";
import { LanguagesService } from "../../languages/languages.service";
import { ActiveTabService } from "../active-tab.service";

@Component({
  selector: "app-product-details-tab",
  templateUrl: "./details.component.html",
  styleUrls: ["./product-tabs.scss"],
  providers: [BhiProductsService],
})
export class DetailsComponent implements OnInit {
  // global variables
  product: ProductType;
  details: DetailsType;

  // default to English
  selectedLanguage: string;

  // populate language dropdown
  languages: string[];

  constructor(
    private bhiProductsService: BhiProductsService,
    public languagesService: LanguagesService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private activeTabService: ActiveTabService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      const sku = params["sku"];
      this.selectedLanguage = "en-us";

      // get languages
      this.getLanguages();

      // load product info
      this.getData(sku);
    });
    this.activeTabService.setTab(this);
  }

  getLanguages() {
    let errors;

    this.languagesService.getAllLanguages().subscribe(
      (result) => {
        // populate languages dropdown
        this.languages = result as any;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      }
    );
  }

  getData(sku) {
    let errors;

    this.bhiProductsService.getBhiProduct(sku, this.selectedLanguage).subscribe(
      (result) => {
        this.product = result;
        if (this.product.details) {
          this.details = this.product.details;
        } else {
          this.details = {};
        }
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );
  }

  // update the fields based off the language selection
  updateFieldData() {
    this.details =
      this.product.details && this.product.details[this.selectedLanguage]
        ? this.product.details[this.selectedLanguage]
        : {};
  }

  cancel() {
    this.router.navigate(["dashboard/cms/products"]);
  }

  save() {
    // save language details - Sets the value at path of object. If a portion of path doesn't exist, it's created
    const selectedLanguage: any = ["details", [this.selectedLanguage]];
    // commented below line as part of bug-29497, to avoid 'TypeError: Converting circular structure to JSON
    // set(this.product, selectedLanguage, this.details);
    this.product.hazards = {
      codes: [],
      msds: [],
    };
    // save the product
    this.bhiProductsService
      .putBhiProduct(this.product, false)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          // show notification
          this.snackBar.open("Product updated successfully.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });
        } else {
          // show notification
          const msg =
            "An error occurred while attempting to save: " + this.product.sku;
          this.snackBar.open(msg, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to save: " + data.reason.message
          );
        }
      });
  }
}
