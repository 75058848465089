/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CountryOption } from "./countries";
import { AppConfigService } from "../../app.config.service";
// this service fetches the master country list from platform s3 file
@Injectable()
export class MasterDataService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public async getMasterCountriesList() {
    return new Promise((resolve) => {
      this.http
        .get<CountryOption[]>(
          `${this.appConfig.getConfig().portalApiURL}/master/countries`
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            resolve([]);
          }
        );
    });
  }

  public async getMasterLanguagesList() {
    return new Promise((resolve) => {
      this.http
        .get<any>(`${this.appConfig.getConfig().portalApiURL}/master/languages`)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            resolve([]);
          }
        );
    });
  }
}
