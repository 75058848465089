/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { DeviceListItem } from "../../core/models/devices/device-list-item.model";
import { DevicesService } from "./devices.service";
import { SearchTerm } from "../../core/models/search/search-term.model";
import { User } from "../../core/models/users/user.model";
import { UserListModel } from "../../core/models/users/user-list.model";
import { UserService } from "../users/users.service";
import { DeviceList } from "../../core/models/devices/device-list.model";
import { BhiProductsService } from "../cms/products/bhi_products.service";

@Component({
  selector: "devices-component",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.scss"],
})
export class DevicesComponent implements OnInit {
  public devices: DeviceListItem[];
  public usersDataSource: User[] = [];
  public productSkuFamilyList;

  public userSearchAttribute = {
    displayName: "Name",
    name: "fullName",
  };
  public deviceSearchAttribute = {
    displayName: "Name",
    name: "thingName",
  };

  public devicePartialSearch: boolean;
  public usersListExist: boolean;
  public lastEvaluatedKey: string;
  private lastSearchTerms: string;

  constructor(
    public devicesService: DevicesService,
    private bhiProductsService: BhiProductsService,
    private userService: UserService
  ) {
    this.productSkuFamilyList = new Map();
  }

  // populatet the sku-product family map only once.
  private populateProductFamilyMap() {
    // get the device family
    this.bhiProductsService.getBhiProducts().subscribe(
      (result) => {
        result.forEach((item) => {
          if (item.sku) {
            const family = item.family ? item.family : "Unable to fetch family";
            this.productSkuFamilyList.set(item.sku, family);
          }
        });
      },
      (error) => {},
      () => {
        /** Callback */
      }
    );
  }

  public initSearch(
    searchTerms: SearchTerm[],
    forcePartialSearch?: boolean,
    lastEvaluatedKey?: string
  ) {
    //don't show up users not exist initially
    this.usersListExist = true;

    this.lastSearchTerms = undefined;
    // get the device details
    this.devicesService
      .getDevices(searchTerms, forcePartialSearch, lastEvaluatedKey)
      .subscribe((devicesList: DeviceList) => {
        if (
          devicesList &&
          (this.devices === undefined || this.devices === null)
        ) {
          this.devices = devicesList.items;
        } else {
          // then add each item to device list if user clicks 'load more'
          if (this.devicePartialSearch || devicesList.items) {
            for (let item of devicesList.items) {
              this.devices.push(item);
            }
          }
        }
        this.lastEvaluatedKey = devicesList.lastEvaluatedKey;
        this.devicePartialSearch = devicesList.isPartialSearch;
      });
  }

  public ngOnInit() {
    this.populateProductFamilyMap();
    this.initSearch([], false, undefined);
  }

  public onRemoveSearchTerm(searchTerms: SearchTerm[]) {
    if (!searchTerms.length) {
      this.devices = undefined;
    }
  }

  public loadMoreResults() {
    if (this.lastSearchTerms === null || this.lastSearchTerms === undefined) {
      this.initSearch([], false, this.lastEvaluatedKey); // search with lastEvaluatedKey in mind
    } else {
      this.search(this.lastSearchTerms);
    }
  }

  public search(queryToSearch: string) {
    let userSearchTerms: SearchTerm[] = [];
    let deviceSearchTerms: SearchTerm[] = [];
    userSearchTerms.push(
      new SearchTerm(this.userSearchAttribute, queryToSearch)
    ); // This is required to get accepted by user service
    deviceSearchTerms.push(
      new SearchTerm(this.deviceSearchAttribute, queryToSearch)
    ); // This is required to get accepted by user service

    this.devices = undefined;
    this.usersDataSource = undefined;
    this.lastSearchTerms = queryToSearch;

    // get device list
    this.devicesService
      .getDevicesBySearchTerm(deviceSearchTerms)
      .subscribe((devicesList) => {
        this.devices = devicesList.items;
        this.devicePartialSearch = devicesList.isPartialSearch;
      });

    // display the users profile
    this.userService.searchByTerms(userSearchTerms).subscribe((usersList) => {
      if (usersList.items && usersList.items.length) {
        this.usersDataSource = usersList.items;
        this.usersListExist = true;
      } else {
        this.usersListExist = false;
      }
    });
  }

  /**
   * Capture the Debug information and paste it in the Clipboard for the user to
   * place in CRM for a service ticket.
   */
  public copyConsumerCareData() {} // end of public copyConsumerCareData()
}
