/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { DevicesService } from "../devices.service";
import { Device } from "../../../core/models/devices/device.model";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "device-detail-copy",
  templateUrl: "./device-detail-copy.html",
})
export class DeviceDetailsCopyComponent implements OnInit {
  public device: Device;

  constructor(
    public _clipboardService: ClipboardService,
    private devicesService: DevicesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    let thingName: string = this.route.snapshot.params["thingName"];
    this.devicesService.get(thingName).subscribe((data) => {
      this.device = data;
    });
  }

  callServiceToCopy() {
    this._clipboardService.copyFromContent(JSON.stringify(this.device));
  }

  onCopyFailure() {
    alert("Failed to copy to clipboard user ");
  }
}
