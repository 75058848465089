/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ToastHelperService {
  constructor(private toastyService: ToastrService) {}

  public showSuccess(message: string) {
    const toastOptions = {
      closeButton: true,
      timeout: 3000,
      positionClass: "toast-bottom-center",
    };
    this.toastyService.success(message, "Success", toastOptions);
  }

  public showError(error: string, message: string) {
    const toastOptions = {
      closeButton: true,
      timeout: 5000,
      positionClass: "toast-bottom-center",
    };
    this.toastyService.error(message, "Error", toastOptions);
  }
}
