/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DeviceListItem } from "../../../core/models/devices/device-list-item.model";

@Component({
  selector: "devices-list",
  templateUrl: "./devices-list.component.html",
})
export class DevicesListComponent {
  @Input() public devices: DeviceListItem[];
  @Input() public productSkuFamilyList;

  constructor(private router: Router) {}

  public getFamily(sku: string): string {
    return this.productSkuFamilyList.get(sku);
  }
}
