/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { IntnlCountryRecord } from "../../../core/models/support/IntnlCountryRecord";

@Pipe({
  name: "countryFilter",
  pure: false,
})
export class CountryFilterPipe implements PipeTransform {
  transform(items: IntnlCountryRecord[], filterString: string): any {
    if (!items || !filterString) {
      return items;
    }
    return items.filter((item) =>
      item.country.toLowerCase().includes(filterString.toLowerCase())
    );
  }
}
