/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { NotificationTemplateService } from "./notification-template.service";
import { mapKeys, zipObject, lowerFirst } from "lodash";
import { MatPaginator, MatTableDataSource, MatSort } from "@angular/material";

@Component({
  selector: "app-cms-notification-template",
  templateUrl: "./notification-template.component.html",
})
export class CmsNotificationTemplateComponent implements OnInit {
  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  constructor(public contentService: NotificationTemplateService) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    // set table columns
    this.displayColumns = [
      "approved",
      "title",
      "sku",
      "languageCode",
      "countryAvailability",
      "callToActionText",
      "callToActionURL",
      "actions",
    ];
    // get data from server
    this.getData();
  }

  getData() {
    this.contentService.getAllNotificatonTemplates().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.item.Items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
