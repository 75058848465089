/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ReleaseListModel } from "../../core/models/buildmatrix/release-list.model";
import { Observable } from "rxjs";
import { AppConfigService } from "../../app.config.service";

@Injectable()
export class BuildMatrixService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getReleases(): Observable<ReleaseListModel> {
    return this.http.get<ReleaseListModel>(
      this.appConfig.getConfig().portalApiURL + "/releases"
    );
  }
}
