/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

import { BaseProductDefinition } from "../../../core/models/products/base-product-definition.model";
import { AppConfigService } from "../../../app.config.service";

@Injectable()
export class BaseProductDefinitionService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getProductsByRegion(region): Observable<any> {
    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL}/products/${region}`
    );
  }

  public submitBaseProductDefinition(
    definition: BaseProductDefinition
  ): Observable<BaseProductDefinition> {
    return this.http.post<BaseProductDefinition>(
      this.appConfig.getConfig().portalApiURL + "/products",
      definition
    );
  }
}
