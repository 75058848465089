/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { BrowserModule, Title } from "@angular/platform-browser";
import { ClipboardModule } from "ngx-clipboard";

import { NgModule, ApplicationRef, APP_INITIALIZER } from "@angular/core";
import {
  removeNgStyles,
  createNewHosts,
  createInputTransfer,
} from "@angularclass/hmr";
import { MatNativeDateModule } from "@angular/material";
import { RouterModule, PreloadAllModules } from "@angular/router";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ROUTES } from "./app.routes";

import { AppComponent } from "./app.component";
import { NoContentComponent } from "./no-content.component";
import { APP_RESOLVER_PROVIDERS } from "./app.resolver";
import { AppState, InternalStateType } from "./app.service";
import { AppConfigService } from "./app.config.service";

import { StoreModule } from "@ngrx/store";
import * as AppStore from "../store/index";

import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { PublicModule } from "./public/public.module";
import { DashboardModule } from "./dashboard/dashboard.module";

import "../styles/styles.scss";

type StoreType = {
  state: InternalStateType;
  restoreInputValues: () => void;
  disposeOldHosts: () => void;
};

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};
/**
 * `AppModule` is the main entry point into Angular2's bootstrapping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, NoContentComponent],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, {
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: PreloadAllModules,
    }),
    StoreModule.forRoot(AppStore.REDUCER_TOKEN),
    MatNativeDateModule,
    CoreModule,
    SharedModule,
    PublicModule,
    DashboardModule,
    ClipboardModule,
  ],
  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    ...APP_RESOLVER_PROVIDERS,
    AppState,
    AppStore.reducerProvider,
    Title,
  ],
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) {}

  public hmrOnInit(store: StoreType) {
    if (!store || !store.state) {
      return;
    }
    /**
     * Set state
     */
    this.appState._state = store.state;
    /**
     * Set input values
     */
    if ("restoreInputValues" in store) {
      let restoreInputValues = store.restoreInputValues;
      setTimeout(restoreInputValues);
    }

    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }

  public hmrOnDestroy(store: StoreType) {
    const cmpLocation = this.appRef.components.map(
      (cmp) => cmp.location.nativeElement
    );
    /**
     * Save state
     */
    const state = this.appState._state;
    store.state = state;
    /**
     * Recreate root elements
     */
    store.disposeOldHosts = createNewHosts(cmpLocation);
    /**
     * Save input values
     */
    store.restoreInputValues = createInputTransfer();
    /**
     * Remove styles
     */
    removeNgStyles();
  }

  public hmrAfterDestroy(store: StoreType) {
    /**
     * Display new elements
     */
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
