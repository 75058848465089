/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Injectable, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { LoadingState } from "../../../store/modules/loading/loading.model";

@Component({
  selector: "loading-spinner",
  templateUrl: "./loading-spinner.component.html",
})
@Injectable()
export class LoadingSpinnerComponent implements OnInit {
  public loadingState: Observable<number>;

  @Input() public isAutomatic: boolean = true;
  @Input() public isLoading: boolean;
  @Input() public useProgressBar: boolean;

  @Input() public diameter: number = 100;
  @Input() public strokeWidth: number = 10;

  constructor(private store: Store<LoadingState>) {}

  public ngOnInit() {
    this.loadingState = this.store.select((state) => state.loading);
  }
}
