/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Label } from "../../../core/models/labels/labels.model";
import { Observable } from "rxjs";
import { LabelElement } from "./labels.component";
import { omit } from "lodash";
import { AppConfigService } from "../../../app.config.service";

const uuid = require("uuid");
const XLSX = require("xlsx");

@Injectable()
export class LabelsService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public getLabels(): Observable<Label> {
    return this.http.get<any>(
      `${this.appConfig.getConfig().portalApiURL + "/cms"}/labels`
    );
  }

  public getLabel(labelToken): Observable<Label> {
    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/cms"
      }/get-label/${labelToken}`
    );
  }

  public addLabel(label: LabelElement, isNew: boolean): Observable<Label> {
    // format label
    const formattedLabel = this.formatLabel(label);

    // save
    const body = {
      Item: formattedLabel,
      isNew: isNew,
    };

    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/cms"}/add-label`,
      body
    );
  }

  public formatLabel(label: LabelElement) {
    /* Expected format
    {
      Id: ,
      Token: ,
      LanguageCode: ,
      en-us: ,
      es-mx: ,
    }
    */

    // Generate UUID
    if (!label.id) {
      label.id = uuid();
    }

    // create expected format
    if (label.languageCode !== "en-us") {
      // set the langauge code property (will replace nonEnglishStrinVal)
      label[label.languageCode] = label.nonEnglishStringValue;
    }

    // remove the nonEnglishStringValue & language code
    label = omit(label, [
      "nonEnglishStringValue",
      "languageCode",
    ]) as LabelElement;

    return label;
  }

  formatImportedLabel(label: any) {
    /* Expected return format
    {
      Id: ,
      Token: ,
      LanguageCode: ,
      dr-dr: ,
      en-us:
    }
    */

    // set the langauge code property (will replace stringValue)
    label[label.languageCode] = label.stringValue;

    // remove the nonEnglishStringValue & language code
    label = omit(label, ["languageCode", "stringValue"]);

    return label;
  }

  public batchAddLabel(labels: LabelElement[]): Observable<Label> {
    const formattedLabels = [];
    // format each label element
    labels.forEach((label) => {
      formattedLabels.push(this.formatImportedLabel(label));
    });

    // do a batch save
    return this.http.put<any>(
      `${this.appConfig.getConfig().portalApiURL + "/cms"}/add-labels`,
      formattedLabels
    );
  }

  public readExcelFile(data) {
    const workbook = XLSX.read(data, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const result = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
      header: 1,
    });
    return result;
  }

  public publishLabelsToS3(languageCode) {
    return this.http.get<any>(
      `${
        this.appConfig.getConfig().portalApiURL + "/cms"
      }/export-labels/${languageCode}`
    );
  }
}
