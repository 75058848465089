/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, Input } from "@angular/core";
import { BhiProductsService } from "../../cms/products/bhi_products.service";
import { Device } from "../../../core/models/devices/device.model";
import { DevicesService } from "../devices.service";

@Component({
  selector: "device-documents",
  templateUrl: "./device-documents.component.html",
})
export class DeviceDocumentsComponent implements OnInit {
  @Input() public device: Device;

  public documentsDataSource: any = [];
  public videoDataSource: any = [];

  constructor(
    private devicesService: DevicesService,
    private bhiProductsService: BhiProductsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    let sku: string = this.devicesService.getSku();
    this.bhiProductsService.getBhiProductDocuments(sku, "").subscribe(
      (result) => {
        result.filter((item) => {
          if (item.type === "video") this.videoDataSource.push(item);
          if (item.type === "document") this.documentsDataSource.push(item);
        });
      },
      (error) => {
        // todo: There should be a way to log the error in some logging service
      },
      () => {
        /** Callback */
      }
    );
  }
}
