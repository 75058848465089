/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { DevicesService } from "../../dashboard/devices/devices.service";
import { SearchTerm } from "../../core/models/search/search-term.model";
import { Device } from "../../core/models/devices/device.model";
import * as _ from "lodash";

@Component({
  selector: "device-picker-multiple",
  templateUrl: "./device-picker-multiple.component.html",
})
export class DevicePickerMultipleComponent implements OnInit {
  @Input() public deviceSelection: Device[];
  @Output() public deviceChange: EventEmitter<Device> =
    new EventEmitter<Device>();

  public deviceFilter: string;
  public devices: Observable<Device[]>;

  private searchDevicesDebounced: any;
  private searchAttributes: any = {
    thingName: { displayName: "Name", name: "thingName" },
  };

  constructor(private deviceService: DevicesService) {}

  public ngOnInit() {
    this.searchDevicesDebounced = _.debounce(this.searchDevices, 300);
  }

  public onKeyUp(event: any) {
    if (this.deviceFilter && this.deviceFilter.length >= 3) {
      this.searchDevicesDebounced();
    }
  }

  public setDevice(device: Device) {
    this.deviceSelection = this.deviceSelection ? this.deviceSelection : [];
    this.deviceSelection.push(device);
    this.deviceChange.emit(device);
  }

  public removeDevice(device: Device) {
    // remove device from list of devices
    this.deviceSelection.splice(this.deviceSelection.indexOf(device), 1);
    this.deviceChange.emit(device);
  }

  private searchDevices() {
    const thingNameTerm = new SearchTerm(
      this.searchAttributes.thingName,
      this.deviceFilter
    );
    const searchTerms = [thingNameTerm];
    this.devices = this.deviceService
      .search(searchTerms, true)
      .map((data) => data.items);
  }
}
