/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

export class Job {
  public jobArn: string;
  public jobId: string;
  public thingGroupId: string;
  public targetSelection: string;
  public status: string;
  public createdAt: Date;
  public lastUpdatedAt: Date;
  public completedAt: Date;
  public jobProcessDetails: any;
}
