/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ProductType } from "./models";

const uuid = require("uuid");

@Injectable()
export class TabsService {
  private url = "";

  constructor(private http: HttpClient) {}

  public getProductDetails() {
    return [];
  }

  public getProduct(sku): Observable<ProductType> {
    return this.http.get<any>(`${this.url}/bhi/products/${sku}`);
  }
}
