/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

export interface CriteriaOption {
  viewValue: string;
  value: string;
}

export const CriteriaOptions: CriteriaOption[] = [
  { value: "UserSignUpDate", viewValue: "User Signup Date" },
  { value: "LastLogin", viewValue: "Last Login" },
  { value: "CompletedProfile", viewValue: "Completed Profile" },
  { value: "UponPairing", viewValue: "Upon Pairing" },
  { value: "FirstUse", viewValue: "First Use" },
  { value: "FifthUse", viewValue: "Fifth Use" },
  { value: "TenthUse", viewValue: "Tenth Use" },
];
