/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Routes } from "@angular/router";

import { CmsComponent } from "./cms.component";
import { CmsLabelsComponent } from "./labels/labels.component";
import { CmsEditLabelComponent } from "./labels/edit-label.component";
import { CmsEditProductComponent } from "./products/edit-product.component";
import { CmsCopyProductComponent } from "./products/copy-product.component";
import { CmsProductComponent } from "./products/products.component";
import { CmsDynamicContentComponent } from "./dynamic-content/dynamic.component";
import { CmsEditDynamicContentComponent } from "./dynamic-content/edit-content.component";
import { CmsTagsComponent } from "./dynamic-content/tags/tags.component";
import { CmsTermsComponent } from "./terms/terms.component";
import { CmsEditTermsComponent } from "./terms/edit/edit-terms.component";
import { CmsCriteriaFieldsComponent } from "./criteria-fields/criteria-field.component";
import { CmsEditFieldsComponent } from "./criteria-fields/edit-field.component";
import { CmsTemplateFieldsComponent } from "./template-fields/template-field.component";
import { CmsEditTemplateFieldsComponent } from "./template-fields/edit-template-field.component";
import { CmsNotificationTemplateComponent } from "./notification-template/notification-template.component";
import { CmsEditNotificationTemplateComponent } from "./notification-template/edit-notification-template.component";
import { CmsMobileFeaturesComponent } from "./mobilefeatures/mfeature.component";
import { CmsLanguagesComponent } from "./languages/languages.component";
import { CmsEditLanguages } from "./languages/edit/edit-languages.component";
import { CmsEditMobileFeature } from "./mobilefeatures/edit/edit-mfeature.component";
import { CmsCopyMobileFeature } from "./mobilefeatures/copyfrom/copy-mfeature.component";
import { CmsAvailableCountriesComponent } from "./available-countries/available-countries.component";
import { CmsAddAvailableCountriesComponent } from "./available-countries/add/add-available-countries.component";
import { BaseProductDefinitionComponent } from "./base-product-definitions/base-product-definitions.component";
import {
  CmsConfigComponent,
  CmsEditConfigComponent,
  CmsAddConfigComponent,
  CmsConfigDiffComponent,
} from "./config/config.component";
import { CmsOutageComponent } from "./outage/outage.component";
import { CmsEditOutageComponent } from "./outage/edit/edit-outage.component";
export const CmsRoutes: Routes = [
  {
    path: "cms",
    component: CmsComponent,
    children: [
      {
        path: "base-product-definitions",
        component: BaseProductDefinitionComponent,
      },
      { path: "labels", component: CmsLabelsComponent },
      { path: "labels/:language/add", component: CmsEditLabelComponent },
      {
        path: "labels/:language/:token/edit",
        component: CmsEditLabelComponent,
      },
      { path: "products", component: CmsProductComponent },
      { path: "products/:sku", component: CmsEditProductComponent },
      { path: "products/:sku/copy", component: CmsCopyProductComponent },
      { path: "dynamic-content", component: CmsDynamicContentComponent },
      {
        path: "dynamic-content/add",
        component: CmsEditDynamicContentComponent,
      },
      {
        path: "dynamic-content/:id/edit",
        component: CmsEditDynamicContentComponent,
      },
      { path: "tags", component: CmsTagsComponent },
      { path: "terms", component: CmsTermsComponent },
      { path: "terms/add", component: CmsEditTermsComponent },
      { path: "terms/:id/edit", component: CmsEditTermsComponent },
      { path: "criteria-fields", component: CmsCriteriaFieldsComponent },
      { path: "criteria-fields/add", component: CmsEditFieldsComponent },
      {
        path: "criteria-fields/:fieldId/edit",
        component: CmsEditFieldsComponent,
      },
      { path: "template-fields", component: CmsTemplateFieldsComponent },
      {
        path: "template-fields/add",
        component: CmsEditTemplateFieldsComponent,
      },
      {
        path: "template-fields/:id/edit",
        component: CmsEditTemplateFieldsComponent,
      },
      {
        path: "notification-template",
        component: CmsNotificationTemplateComponent,
      },
      {
        path: "notification-template/add",
        component: CmsEditNotificationTemplateComponent,
      },
      {
        path: "notification-template/:id/edit/:op",
        component: CmsEditNotificationTemplateComponent,
      },
      { path: "mobilefeatures", component: CmsMobileFeaturesComponent },
      { path: "mobilefeatures/add", component: CmsEditMobileFeature },
      { path: "mobilefeatures/:id/edit", component: CmsEditMobileFeature },
      { path: "languages", component: CmsLanguagesComponent },
      { path: "languages/add", component: CmsEditLanguages },
      {
        path: "languages/:contentLanguageCode/edit",
        component: CmsEditLanguages,
      },
      { path: "mobilefeatures/copyfrom", component: CmsCopyMobileFeature },
      {
        path: "available-countries",
        component: CmsAvailableCountriesComponent,
      },
      {
        path: "available-countries/add",
        component: CmsAddAvailableCountriesComponent,
      },
      { path: "config", component: CmsConfigComponent },
      { path: "config/edit", component: CmsEditConfigComponent },
      { path: "config/add", component: CmsAddConfigComponent },
      { path: "config/diff", component: CmsConfigDiffComponent },
      { path: "outage", component: CmsOutageComponent },
      { path: "outage/add", component: CmsEditOutageComponent },
      { path: "outage/:id/edit", component: CmsEditOutageComponent },
    ],
  },
];
