/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../core/auth/auth.service";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  public submitting: boolean;

  constructor(public router: Router, public authService: AuthService) {}

  public async ngOnInit() {
    const query = window.location.search;
    if (query.includes("code=") && query.includes("state=")) {
      this.submitting = true;
      const auth0 = await this.authService.getAuth0Client();

      // Process the login state
      await auth0.handleRedirectCallback();
      await this.authService.getToken();

      this.authService
        .onLogin()
        .finally(() => (this.submitting = false))
        .subscribe(() => this.router.navigate(["/dashboard"]));
    }
  }

  public async onLogin() {
    this.submitting = true;

    const auth0 = await this.authService.getAuth0Client();

    // Redirects to the current page and appends code and state as query parameters
    // (see processing in the ngOnInit function)
    await auth0.loginWithRedirect({
      redirect_uri: window.location.origin,
    });
  }
}
