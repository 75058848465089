/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { CriteriaFieldsService } from "./criteria-field.service";
import { FieldElement } from "./criteria-field.component";
import { MatPaginator, MatTableDataSource, MatSort } from "@angular/material";

export interface FieldElement {
  fieldId: string;
  fieldDisplayName: string;
  table: string;
  field: string;
  key: string;
  type: string;
}

@Component({
  selector: "app-cms-criteria-fields",
  templateUrl: "./criteria-field.component.html",
})
export class CmsCriteriaFieldsComponent implements OnInit {
  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  constructor(public criteriaFieldsService: CriteriaFieldsService) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    // set table columns
    this.displayColumns = [
      "fieldDisplayName",
      "table",
      "field",
      "key",
      "type",
      "actions",
    ];

    // get data from server
    this.getData();
  }

  getData() {
    this.criteriaFieldsService.getFields().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.item.Items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
