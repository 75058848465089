/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { AuthService } from "./core/auth/auth.service";

@Component({
  selector: "app",
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    "./app.component.css",
    "../../node_modules/ngx-toastr/toastr.css",
  ],
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  public url = "https://www.ostusa.com/";

  constructor(public authService: AuthService) {}

  public ngOnInit() {
    this.authService.isAuthenticated().subscribe();
  }
}
