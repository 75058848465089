/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { BhiProductsService } from "../bhi_products.service";
import { EcommerceType } from "./models";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { find } from "lodash";
import { ActiveTabService } from "../active-tab.service";
import { CountriesService } from "../../countries.service";

@Component({
  selector: "app-product-ecommerce-tab",
  templateUrl: "./ecommerce.component.html",
  styleUrls: ["./product-tabs.scss"],
  providers: [BhiProductsService],
})
export class EcommerceComponent implements OnInit {
  ecommerce: EcommerceType;
  selectedRegion: string;
  sku: string;

  // populate language dropdown
  regions: any;

  constructor(
    private bhiProductsService: BhiProductsService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private activeTabService: ActiveTabService,
    private countriesService: CountriesService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      this.sku = params["sku"];

      // default to us
      this.selectedRegion = "us";

      // load product info
      this.getData(this.sku, this.selectedRegion);
    });

    this.activeTabService.setTab(this);

    // get Region List
    this.countriesService.getCountries().then((countries) => {
      this.regions = countries;
    });
  }

  getData(sku: string, region: string) {
    let errors;

    this.bhiProductsService.getBhiProductEcommerce(sku, region).subscribe(
      (result) => {
        this.ecommerce = result;
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );
  }

  cancel() {
    this.router.navigate(["dashboard/cms/products"]);
  }

  save() {
    // set sku
    this.ecommerce.sku = this.sku;
    this.ecommerce.region = this.selectedRegion;

    this.bhiProductsService
      .putBhiProductEcommerce(this.ecommerce)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          // show notification
          this.snackBar.open("Product updated successfully.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });
        } else {
          // show notification
          this.snackBar.open(data.reason.message, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });

          console.error(
            "An error occurred while attempting to save: " + this.ecommerce.sku
          );
        }
      });
  }

  updateFieldData() {
    // REQUERY BASED ON REGION CODE AND SKU
    this.getData(this.sku, this.selectedRegion);
  }

  onAvailabilityChange() {
    this.ecommerce.available = !this.ecommerce.available;
  }
}
