/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

export class ProductImageMap {
  public imageMap: any;

  constructor() {
    // created entry for each SKU since,
    //  1. base Sku is not part of the user thing data
    //  2. There may be separate image for each SKU ( color ring etc)

    this.imageMap = {};
    this.imageMap["2291"] = "/assets/img/iconpet.png";
    this.imageMap["2291A"] = "/assets/img/iconpet.png";
    this.imageMap["2291B"] = "/assets/img/iconpet.png";
    this.imageMap["2294"] = "/assets/img/iconpet.png";
    this.imageMap["22912"] = "/assets/img/iconpet.png";
    this.imageMap["2291K"] = "/assets/img/iconpet.png";
    this.imageMap["2291D"] = "/assets/img/iconpet.png";
    this.imageMap["2581C"] = "/assets/img/iconpet.png";
    this.imageMap["2581F"] = "/assets/img/iconpet.png";
    this.imageMap["2581K"] = "/assets/img/iconpet.png";
    this.imageMap["2581N"] = "/assets/img/iconpet.png";
    this.imageMap["2581T"] = "/assets/img/iconpet.png";

    // CrossWave
    this.imageMap["2554"] = "/assets/img/crosswave.png";
    this.imageMap["2554B"] = "/assets/img/crosswave.png";
    this.imageMap["25959"] = "/assets/img/crosswave.png";
    this.imageMap["2554A"] = "/assets/img/crosswave.png";
    this.imageMap["2595T"] = "/assets/img/crosswave.png";
    this.imageMap["25542"] = "/assets/img/crosswave.png";
    this.imageMap["1785G"] = "/assets/img/crosswave.png";
    this.imageMap["25952"] = "/assets/img/crosswave.png";
    this.imageMap["2596"] = "/assets/img/crosswave.png";
    this.imageMap["2596Y"] = "/assets/img/crosswave.png";
    this.imageMap["25548"] = "/assets/img/crosswave.png";
    this.imageMap["2593B"] = "/assets/img/crosswave.png";
    this.imageMap["2554D"] = "/assets/img/crosswave.png";
    this.imageMap["2597"] = "/assets/img/crosswave.png";
    this.imageMap["2590"] = "/assets/img/crosswave.png";
    this.imageMap["2595"] = "/assets/img/crosswave.png";
    this.imageMap["2593K"] = "/assets/img/crosswave.png";
    this.imageMap["2921"] = "/assets/img/crosswave.png";
    this.imageMap["2593"] = "/assets/img/crosswave.png";

    //CleanView

    this.imageMap["2933"] = "/assets/img/cleanview.png";
    this.imageMap["29339"] = "/assets/img/cleanview.png";
    this.imageMap["2908N"] = "/assets/img/cleanview.png";
    this.imageMap["3076"] = "/assets/img/cleanview.png";
    this.imageMap["3076W"] = "/assets/img/cleanview.png";
    this.imageMap["29331"] = "/assets/img/cleanview.png";
    this.imageMap["2933C"] = "/assets/img/cleanview.png";

    // SpinWave
    this.imageMap["2859"] = "/assets/img/spinwave.png";
    this.imageMap["28599"] = "/assets/img/spinwave.png";
    this.imageMap["28597"] = "/assets/img/spinwave.png";
    this.imageMap["28596"] = "/assets/img/spinwave.png";
    this.imageMap["2859T"] = "/assets/img/spinwave.png";
    this.imageMap["2859W"] = "/assets/img/spinwave.png";
    this.imageMap["2859C"] = "/assets/img/spinwave.png";
    this.imageMap["2931N"] = "/assets/img/spinwave.png";
    this.imageMap["3115"] = "/assets/img/spinwave.png";

    //AirPurifier

    this.imageMap["2847A"] = "/assets/img/aircare.png";
    this.imageMap["2847"] = "/assets/img/aircare.png";
  }

  public getImage(sku) {
    const path = this.imageMap[sku];
    if (!path) {
      // if no image found return default.
      return "/assets/img/logo.png";
    }
    return path;
  }
}
