/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { UserDevice } from "./user-device.model";
import { Team } from "../teams/team.model";

export class User {
  public userId: string;
  public fullName?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public errorMessage: string;
  public phone?: string;
  public addressOne?: string;
  public addressTwo?: string;
  public city?: string;
  public state?: string;
  public zipCode?: string;
  public devices?: UserDevice[];
  public teams?: Team[];
  public scopes?: string[];
  public acceptedTermsId?: string;
}
