/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DevicesService } from "./devices.service";
import { UserService } from "../users/users.service";
import { Device } from "../../core/models/devices/device.model";
import { User } from "../../core/models/users/user.model";
import { tap, flatMap } from "rxjs/operators";

@Component({
  selector: "device-details",
  templateUrl: "./device-details.component.html",
  styleUrls: ["./device-details.component.scss"],
})
export class DeviceDetailsComponent implements OnInit {
  public device: Device;
  public deviceUser: Object;

  constructor(
    private devicesService: DevicesService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    let thingName: string = this.route.snapshot.params["thingName"];
    this.devicesService
      .get(thingName)
      .pipe(
        tap((data) => {
          this.device = data;
          this.devicesService.setSku(data.sku);
        }),
        flatMap((data) => this.userService.get(data.userId))
      )
      .subscribe((user) => (this.deviceUser = user));
  }
}
