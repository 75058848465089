/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

export const ProductCatsAndSubcats: {
  category: string;
  subcategories: string[];
}[] = [
  {
    category: "attended_wash",
    subcategories: ["Multisurface"],
  },
  {
    category: "air",
    subcategories: [""],
  },
  {
    category: "robot",
    subcategories: ["Robots"],
  },

  // consumable categories and subcategories:
  {
    category: "filter",
    subcategories: [""],
  },
  {
    category: "brushroll",
    subcategories: [""],
  },
  {
    category: "brush",
    subcategories: [""],
  },
  {
    category: "formula",
    subcategories: [""],
  },
  {
    category: "wetpad",
    subcategories: [""],
  },
  {
    category: "sidebrush",
    subcategories: [""],
  },

  /*
don't have below categories in asset library as part of device types
  {
    category: "Carpet Cleaners",
    subcategories: [
      "Upright Carpet Cleaner",
      "Portable Carpet Cleaner",
      "Canister Carpet Cleaner",
    ],
  },
  {
    category: "Vacuums",
    subcategories: [
      "Upright Vacuum",
      "Cordless Vacuum",
      "Robotic Vacuum",
      "Canister Vacuum",
      "Handheld Vacuum",
    ],
  },
  {
    category: "Steam and Hard Floor Cleaners",
    subcategories: [
      "Steam Mop",
      "Wet Dry Vac",
      "Stick Vac",
      "Handheld Steam Cleaner",
    ],
  },
  {
    category: "Sweepers",
    subcategories: ["Manual Sweeper", "Cordless Sweeper"],
  },
  {
    category: "Carpet and Floor Cleaning Formulas",
    subcategories: [
      "Upright Carpet Shampoo",
      "Portable Carpet Shampoo",
      "Bare Floor Cleaning Solution",
      "Carpet Stain Remover",
      "Woolite Cleaning Products",
    ],
  },
  {
    category: "Fabreze Freshness",
    subcategories: [
      "Carpet Cleaning Formulas",
      "Powders Pads Sprays and Foams",
    ],
  },
  {
    category: "Pet Products and Supplies",
    subcategories: ["Dog Grooming", "Pet Supplies"],
  },
  {
    category: "Mops Brooms and Air Quality",
    subcategories: [
      "Brooms",
      "Mops",
      "Dusters",
      "Brushes",
      "Squeegees",
      "Air Purifier",
    ],
  },*/
];
