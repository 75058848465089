/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth/auth.service";
import "rxjs/Rx";

@Injectable()
export class PrivateGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let observable = new Observable<boolean>((observer) => {
      this.authService.isAuthenticated().subscribe((isLoggedIn) => {
        if (!isLoggedIn) {
          this.router.navigate(["/login"]);
        }
        observer.next(isLoggedIn);
        observer.complete();
      });
    });
    return observable;
  }
}
