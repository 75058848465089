/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { TermsService } from "./terms.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MAT_DIALOG_DATA,
  MatPaginator,
  MatTableDataSource,
  MatSort,
} from "@angular/material";

export interface DialogData {
  refreshGridFn: any;
  sku: string;
}

@Component({
  selector: "app-terms-and-condtions",
  templateUrl: "./terms.component.html",
  providers: [TermsService],
})
export class CmsTermsComponent implements OnInit {
  entity;

  displayColumns: string[];
  dataSource: any;
  loadedData: any[];

  constructor(
    private termsService: TermsService,
    public router: Router,
    public activeRoute: ActivatedRoute
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    // set table columns
    this.displayColumns = [
      "title",
      "terms",
      "region",
      "languageCode",
      "startDate",
      "endDate",
      "defaultTerm",
      "actions",
    ];

    // get data from server
    this.getData();
  }

  getData() {
    let errors;

    this.termsService.getAllTermsAndConditions().subscribe(
      (result) => {
        // Handle result
        const items: any = result as any;
        this.dataSource = new MatTableDataSource(items);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        const sortState: any = { active: "region", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      },
      (error) => {
        errors = error;
        console.log("There was an error: " + error.message);
      },
      () => {
        /** Callback */
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
