/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, HostListener, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "force-confirm-dialog",
  templateUrl: "./force-confirm.dialog.html",
})
export class ForceConfirmDialogComponent {
  public force: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      confirmButtonText: string;
      message: string;
      title: string;
    },
    private matDialogRef: MatDialogRef<ForceConfirmDialogComponent>
  ) {}

  public onCancel() {
    this.close();
  }

  public onConfirm() {
    this.close({
      force: this.force,
    });
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.close();
  }

  private close(result?: any) {
    this.matDialogRef.close(result);
  }
}
