/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { FirmwareService } from "./firmware.service";
import { Firmware } from "../../core/models/firmware/firmware.model";
import { SearchTerm } from "../../core/models/search/search-term.model";
import { MatTableDataSource, MatSort } from "@angular/material";
import { ToastHelperService } from "../../shared/utility/toast-helper.service";

@Component({
  selector: "firmware",
  templateUrl: "./firmware.component.html",
  styleUrls: ["./firmware.component.scss"],
})
export class FirmwareComponent implements OnInit {
  public firmware: Firmware[] = [];
  dataSource: any;
  displayColumns: string[];

  public searchableAttributes = [
    { displayName: "Firmware Name", name: "name" },
    { displayName: "Product SKU", name: "productId" },
  ];
  public lastEvaluatedKey: string;

  constructor(
    private firmwareService: FirmwareService,
    private toastHelper: ToastHelperService,
    private titleService: Title
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  public ngOnInit() {
    // set table columns
    this.displayColumns = [
      "code",
      "version",
      "type",
      "location",
      "released",
      "manufacturable",
      "actions",
    ];
    this.search([], undefined);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public loadMoreResults() {
    this.search([], this.lastEvaluatedKey); // search with lastEvaluatedKey in mind
  }

  public onRemoveSearchTerm(searchTerms: SearchTerm[]) {
    if (!searchTerms.length) {
      this.firmware = [];
    }
  }

  public validate() {
    console.log("Validate");
  }

  public clean() {
    const obs = this.firmwareService.clean();
    obs.subscribe(() => {});
  }

  public search(searchTerms: SearchTerm[], lastEvaluatedKey?: string) {
    const isGenericSearch = !searchTerms || searchTerms.length < 1;

    let obs;
    if (!isGenericSearch)
      obs = this.firmwareService.search(searchTerms, lastEvaluatedKey);
    else obs = this.firmwareService.getAll();

    this.firmware = [];
    obs.subscribe(
      (res) => {
        if (res && res.items && res.items.length > 0) {
          this.firmware = this.firmware
            .concat(res.items)
            .filter((item) => item.name);

          this.dataSource = new MatTableDataSource(this.firmware);
          this.dataSource.sort = this.sort;
        }
        this.firmware.forEach((firmware) => {
          if (firmware.name) {
            const fwDataObs = this.firmwareService.get(firmware.name);
            fwDataObs.subscribe((fwData) => {
              if (fwData) {
                let baseSKUArray = [];
                fwData.products.forEach((product) => {
                  if (product.baseSku) {
                    baseSKUArray.push(product.baseSku);
                  }
                });
                firmware.baseSku = baseSKUArray.join(",");
              }
            });
          }
        });
        this.lastEvaluatedKey = res.lastEvaluatedKey;
      },
      (err) => {
        this.toastHelper.showError("Error", err.statusText);
      }
    );
  }
}
