/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import {
  Component,
  OnInit,
  Input,
  AfterViewChecked,
  OnChanges,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DevicesService } from "../devices.service";
import { HighlightService } from "../../../shared/utility/highlight.service";

@Component({
  selector: "shadow",
  templateUrl: "./shadow.component.html",
})
export class ShadowComponent implements OnInit, AfterViewChecked, OnChanges {
  public shadow: any;

  constructor(
    private devicesService: DevicesService,
    private el: ElementRef,
    private highlightService: HighlightService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    let thingName = this.route.parent.snapshot.params["thingName"];
    this.devicesService
      .getShadow(thingName)
      .subscribe((data) => (this.shadow = data));
  }

  public ngAfterViewChecked() {
    this.highlightService.highlight(this.el, ".json");
  }

  public ngOnChanges(changes: any) {
    // might be necessary when the API works
    if (!changes.shadow.firstChange) {
      this.highlightService.highlight(this.el, ".json");
    }
  }
}
