/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { SafeStyle, SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Injectable()
export class SanitizationService {
  constructor(private sanitizer: DomSanitizer) {}

  public sanitizeStyleUrl(url): SafeStyle {
    return url
      ? this.sanitizer.bypassSecurityTrustStyle("url(" + url + ")")
      : "";
  }

  public sanitizeUrl(url): SafeUrl {
    return url ? this.sanitizer.bypassSecurityTrustUrl(url) : "";
  }
}
