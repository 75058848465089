/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { MatSnackBar, MatDialogRef } from "@angular/material";

import { CountriesService } from "../../countries.service";

@Component({
  selector: "app-edit-available-countries",
  templateUrl: "./add-available-countries.component.html",
  styleUrls: ["./add-available-countries.component.scss"],
})
export class CmsAddAvailableCountriesComponent implements OnInit {
  entity: any;

  constructor(
    public service: CountriesService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CmsAddAvailableCountriesComponent>
  ) {}

  ngOnInit(): void {
    this.entity = {};
  }

  isValid(entity) {
    return entity.name && entity.code;
  }

  save() {
    if (!this.isValid(this.entity)) {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });
      return;
    }
    this.entity.code = this.entity.code.toLowerCase();

    this.service.saveCountry(this.entity).subscribe((data: any) => {
      // notify user of save action
      if (data.status === "Success") {
        // show notification
        this.snackBar.open("Country record were succesfully saved.", "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["success-snackbar"],
        });
        this.dialogRef.close(true);
      } else {
        // show notification
        this.snackBar.open(data.reason, "Close", {
          duration: 4000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ["error-snackbar"],
        });

        console.error(
          "An error occurred while attempting to save: " + data.reason.message
        );
      }
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
