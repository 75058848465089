/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ToastrModule } from "ngx-toastr";
import { CodemirrorModule } from "ng2-codemirror";

import {
  MatMenuModule,
  MatToolbarModule,
  MatButtonModule,
  MatDialogModule,
  MatInputModule,
  MatIconModule,
  MatTabsModule,
  MatTooltipModule,
  MatCardModule,
  MatSelectModule,
  MatListModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatTableModule,
  MatRadioModule,
  MatSortModule,
  MatCheckboxModule,
  MatDatepickerModule,
} from "@angular/material";

import { SanitizationService } from "./utility/sanitization.service";
import { ToastHelperService } from "./utility/toast-helper.service";
import { HighlightService } from "./utility/highlight.service";

import { FooterComponent } from "./footer/footer.component";
import { SearchComponent } from "./search/search.component";
import { LogMessageDialogComponent } from "./dialogs/log-message.dialog";
import { DeleteConfirmDialogComponent } from "./dialogs/delete-confirm.dialog";
import { UnsavedChangesConfirmDialogComponent } from "./dialogs/unsaved-changes-confirm.dialog";
import { ForceConfirmDialogComponent } from "./dialogs/force-confirm.dialog";
import { UserPickerComponent } from "./user-picker/user-picker.component";
import { UserPickerMultipleComponent } from "./user-picker/user-picker-multiple.component";
import { DevicePickerMultipleComponent } from "./device-picker/device-picker-multiple.component";
import { DevicePickerComponent } from "./device-picker/device-picker.component";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { SelectTeamDialogComponent } from "./select-team/select-team.dialog";
import { ConfirmRemoveTeamDialogComponent } from "./confirm-remove-team/confirm-remove-team.dialog";
import { ShowIfHasFeatureComponent } from "./show-if-has-feature/show-if-has-feature.component";
import { BISSELLPreviewPipe } from "./utility/preview-pipe";

@NgModule({
  declarations: [
    FooterComponent,
    SearchComponent,
    LogMessageDialogComponent,
    DeleteConfirmDialogComponent,
    UnsavedChangesConfirmDialogComponent,
    ForceConfirmDialogComponent,
    UserPickerComponent,
    UserPickerMultipleComponent,
    DevicePickerComponent,
    DevicePickerMultipleComponent,
    LoadingSpinnerComponent,
    SelectTeamDialogComponent,
    ConfirmRemoveTeamDialogComponent,
    ShowIfHasFeatureComponent,
    BISSELLPreviewPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,

    // material
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatTooltipModule,
    MatCardModule,
    MatSelectModule,
    MatListModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTableModule,
    MatRadioModule,
    MatSortModule,
    MatCheckboxModule,

    FlexLayoutModule,

    ToastrModule.forRoot(),

    CodemirrorModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,

    // material
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatTooltipModule,
    MatCardModule,
    MatSelectModule,
    MatListModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTableModule,
    MatRadioModule,
    MatSortModule,
    MatCheckboxModule,
    MatDatepickerModule,

    FlexLayoutModule,

    ToastrModule,
    CodemirrorModule,

    // components
    FooterComponent,
    SearchComponent,
    UserPickerComponent,
    UserPickerMultipleComponent,
    DevicePickerComponent,
    DevicePickerMultipleComponent,
    LoadingSpinnerComponent,
    SelectTeamDialogComponent,
    ConfirmRemoveTeamDialogComponent,
    ShowIfHasFeatureComponent,
    BISSELLPreviewPipe,
  ],
  providers: [SanitizationService, ToastHelperService, HighlightService],
  entryComponents: [
    LogMessageDialogComponent,
    DeleteConfirmDialogComponent,
    UnsavedChangesConfirmDialogComponent,
    ForceConfirmDialogComponent,
    SelectTeamDialogComponent,
    ConfirmRemoveTeamDialogComponent,
  ],
})
export class SharedModule {}
