/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { OutageService } from "../outage.service";
import { MatSnackBar } from "@angular/material";

/* CONSTANTS */
const SECONDS_IN_HOUR: number = 3600;
const SECONDS_IN_MINUTE: number = 60;

export interface IncindentStatusTypeOption {
  value: string;
  viewValue: string;
}

export const IncindentStatusTypeOptions: IncindentStatusTypeOption[] = [
  { value: "Active", viewValue: "Active" },
  { value: "Resolved", viewValue: "Resolved" },
];

@Component({
  selector: "app-cms-edit-outage",
  templateUrl: "./edit-outage.component.html",
})
export class CmsEditOutageComponent implements OnInit {
  isNew: boolean;
  entity: any;
  placeholderLabel: string;
  loadedLabelData: any;
  outageResolvedDate: any;
  outageResolvedTime: any;
  incidentStatusTypes: IncindentStatusTypeOption[] = IncindentStatusTypeOptions;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public outageService: OutageService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      // determine if creating a new label or editing an existing one
      const id = params["id"];
      this.isNew = id ? false : true;

      if (this.isNew) {
        this.entity = {};
      } else {
        this.loadNotification(id);
      }
    });
  }

  loadNotification(id: string) {
    this.outageService.getOutageNotificationById(id).subscribe((data: any) => {
      // getting the date & time to display from outageResolvedDate
      this.outageResolvedDate = new Date(data.outageResolvedDate);
      const hours = ("0" + new Date(data.outageResolvedDate).getHours()).slice(
        -2
      );
      const minutes = (
        "0" + new Date(data.outageResolvedDate).getMinutes()
      ).slice(-2);
      this.outageResolvedTime = [hours, minutes].join(":");
      this.entity = data;
    });
  }

  cancel() {
    this.router.navigate(["dashboard/cms/outage"]);
  }

  isValid(entity) {
    // required fields
    return (
      entity.description &&
      entity.cCMessaging &&
      entity.impact &&
      entity.relatedTasks &&
      entity.incidentStatus &&
      entity.outageDate
    );
  }

  save() {
    // validate the body first
    if (!this.isValid(this.entity)) {
      this.snackBar.open("Not all required fields were provided.", "Close", {
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ["error-snackbar"],
      });
      return;
    }
    const [outageResolvedHour, outageResolvedMinute] = this.outageResolvedTime
      ? this.outageResolvedTime.split(":")
      : [0, 0];
    // get epoch for outage resolved
    let outageResolvedEpoch =
      (outageResolvedHour * SECONDS_IN_HOUR +
        outageResolvedMinute * SECONDS_IN_MINUTE) *
      1000;
    const resolvedDate = new Date(this.outageResolvedDate).toDateString();
    outageResolvedEpoch += new Date(resolvedDate).getTime();
    this.entity.outageResolvedDate = outageResolvedEpoch;

    // Service call to persist data
    this.outageService
      .putOutageNotification(this.entity)
      .subscribe((data: any) => {
        // notify user of save action
        if (data.status === "Success") {
          // show notification
          this.snackBar.open("Notification were succesfully saved.", "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["success-snackbar"],
          });
          // return to search screen
          this.router.navigate(["dashboard/cms/outage"]);
        } else {
          // show notification
          this.snackBar.open(data.reason, "Close", {
            duration: 4000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ["error-snackbar"],
          });
        }
      });
  }
}
