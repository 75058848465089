/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { SharedModule } from "../../shared/shared.module";

import { FirmwareService } from "./firmware.service";

import { FirmwareComponent } from "./firmware.component";
import { EditFirmwareComponent } from "./edit-firmware.component";
import { CreateFirmwareComponent } from "./create-firmware.component";

@NgModule({
  declarations: [
    FirmwareComponent,
    EditFirmwareComponent,
    CreateFirmwareComponent,
  ],
  imports: [BrowserModule, SharedModule],
  exports: [],
  providers: [Title, FirmwareService],
  entryComponents: [],
})
export class FirmwareModule {}
