/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { DevicesService } from "../devices.service";
import { Device } from "../../../core/models/devices/device.model";
import { ConfirmCommandDialogComponent } from "./confirm-command.dialog";
import { SanitizationService } from "../../../shared/utility/sanitization.service";
import { LogMessage } from "../../../core/models/log/log-message.model";
import { SelectTeamDialogComponent } from "../../../shared/select-team/select-team.dialog";
import { Team } from "../../../core/models/teams/team.model";
import { ConfirmRemoveTeamDialogComponent } from "../../../shared/confirm-remove-team/confirm-remove-team.dialog";

@Component({
  selector: "device-overview",
  templateUrl: "./device-overview.component.html",
  styleUrls: ["../device-details.component.scss"],
})
export class DeviceOverviewComponent implements OnInit {
  public device: Device;
  public deviceControlOpen: boolean;
  public deviceStatus: boolean;

  public get deviceHasCandC() {
    return this.device && this.device.productFamilyKey !== "CrossWave";
  }

  public commands: LogMessage[] = [];
  public errors: LogMessage[] = [];
  public runs: LogMessage[] = [];
  public telemetry: LogMessage[] = [];

  constructor(
    private devicesService: DevicesService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: SanitizationService
  ) {}

  public ngOnInit() {
    let thingName: string = this.route.parent.snapshot.params["thingName"];
    this.devicesService.get(thingName).subscribe((data) => {
      let connectionCheck: boolean = false;
      let stateCheck: boolean = false;
      // check for the device status to highlight the status section in html
      data.status.forEach((element) => {
        if (element.name.toLowerCase() === "connected") {
          connectionCheck =
            element.value.toLowerCase() === "yes" ? true : false;
        }
        if (element.name.toLowerCase() === "state") {
          stateCheck = element.value.toLowerCase() === "docked" ? true : false;
        }
      });
      this.deviceStatus = connectionCheck && stateCheck;
      this.device = data;
    });

    this.devicesService
      .getLogs(thingName, "commands")
      .subscribe((data) => (this.commands = data));
    this.devicesService
      .getLogs(thingName, "errors")
      .subscribe((data) => (this.errors = data));
    this.devicesService
      .getLogs(thingName, "runs")
      .subscribe((data) => (this.runs = data));
    this.devicesService
      .getLogs(thingName, "telemetry")
      .subscribe((data) => (this.telemetry = data));
  }

  public issueCommand(command: string) {
    const dialogRef = this.dialog.open(ConfirmCommandDialogComponent, {
      data: {
        id: this.device.thingName,
        command,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.devicesService
          .getLogs(this.device.thingName, "commands")
          .subscribe((data) => (this.commands = data));
      }
    });
  }

  public toggleDeviceControl($event: any) {
    $event.stopPropagation();
    this.deviceControlOpen = !this.deviceControlOpen;
  }

  public getMapsUrl() {
    if (!this.device.location) {
      return null;
    }

    let key = "AIzaSyCT6bZnD4y7fKB04NA4NDer2pLdCNmKtE0"; // temporary

    let url = "https://maps.googleapis.com/maps/api/staticmap";
    url += `?key=${key}`;
    url += `&center=${this.device.location.latitude},${this.device.location.longitude}`;
    url += `&size=400x400`;
    url += `&zoom=12`;
    url += `&markers=color:red|${this.device.location.latitude},${this.device.location.longitude}`;
    return this.sanitizer.sanitizeUrl(url);
  }

  public getMapLink() {
    if (!this.device.location) {
      return null;
    }

    let url = "https://www.google.com/maps/search/";
    url += `${this.device.location.latitude},${this.device.location.longitude}`;
    return this.sanitizer.sanitizeUrl(url);
  }

  public openTeamsDialog() {
    let dialogRef = this.dialog.open(SelectTeamDialogComponent, {
      data: {
        entity: this.device.thingName,
        saveFunction: "addDevice",
        selectedTeams: this.device.teams,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!this.device.teams || this.device.teams.length === 0) {
          this.device.teams = [];
        }

        this.device.teams.push(result);
      }
    });
  }

  public removeFromTeam(team: Team) {
    const dialogRef = this.dialog.open(ConfirmRemoveTeamDialogComponent, {
      data: {
        entity: this.device.thingName,
        teamId: team.teamId,
        removeFunction: "removeDevice",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.device.teams = this.device.teams.filter((iTeam) => {
          return result !== iTeam.teamId;
        });
      }
    });
  }
}
