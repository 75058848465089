/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { MatDialog } from "@angular/material";
import { MqttSubscription } from "../../../core/models/mqtt/mqtt-subscription.model";
import { MqttService } from "../../mqtt/mqtt.service";
import { LogMessageDialogComponent } from "../../../shared/dialogs/log-message.dialog";
import { LogMessage } from "../../../core/models/log/log-message.model";

@Component({
  selector: "device-mqtt",
  templateUrl: "./device-mqtt.component.html",
})
export class DeviceMqttComponent implements OnInit, OnDestroy {
  public topic: string;
  public publishMessage: string;

  public subscriptions: Map<string, MqttSubscription> = new Map<
    string,
    MqttSubscription
  >();
  public tabs: string[] = [];

  private _subscription: Subscription;

  constructor(private mqttService: MqttService, public matDialog: MatDialog) {}

  public ngOnInit() {
    this._subscription = this.mqttService
      .getObservable()
      .flatMap((observable) => observable)
      .subscribe((data) =>
        this.subscriptions[data.topic].messages.unshift(data)
      );
  }

  public subscribe(topic: string) {
    let isNew: boolean = this.tabs.indexOf(topic) === -1; // avoid duplicates
    if (topic && isNew) {
      let subscription: MqttSubscription = new MqttSubscription();
      subscription.topic = topic;

      this.tabs.unshift(topic); // for the tabs
      this.subscriptions[topic] = subscription; // for pushing messages

      this.mqttService.subscribe(topic);
    }
  }

  public publish(topic: string, message: string) {
    if (topic) {
      this.mqttService.publish(topic, message);
    }
  }

  public unsubscribe(topic: string) {
    if (topic) {
      this.mqttService.unsubscribe(topic); // tell Paho to stop listening
      let index = this.tabs.indexOf(topic);
      this.tabs.splice(index, 1); // remove the tab
      delete this.subscriptions[topic]; // clean up the subscription object
    }
  }

  public showDetails(message: any) {
    let logMessage: LogMessage = new LogMessage();
    logMessage.payload = message.payloadString;
    this.matDialog.open(LogMessageDialogComponent, {
      data: {
        message: logMessage,
      },
    });
  }

  public ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
