/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

import { JobsResult } from "../../core/models/jobs/jobs-result.model";
import { AppConfigService } from "../../app.config.service";

@Injectable()
export class JobsService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  /**
   * Cancels a job from AWS, with optional force.
   */
  public cancelJob(jobId: string, force: boolean = false): Observable<any> {
    return this.http.put<any>(
      this.appConfig.getConfig().portalApiURL + "/jobs" + `/${jobId}`,
      {
        operation: force ? "forceCancel" : "cancel",
      }
    );
  }

  /**
   * Delete a job from AWS, with optional force.
   */
  public deleteJob(jobId: string, force: boolean = false): Observable<any> {
    return this.http.delete<any>(
      this.appConfig.getConfig().portalApiURL +
        "/jobs" +
        `/${jobId}?force=${force}`
    );
  }

  /**
   * Gets all IoT jobs from AWS
   */
  public getAll(nextToken: string = null): Observable<JobsResult> {
    let url = this.appConfig.getConfig().portalApiURL + "/jobs";

    if (nextToken) {
      const urlEncodedToken = encodeURIComponent(nextToken);
      url += `?nextToken=${urlEncodedToken}`;
    }

    return this.http.get<JobsResult>(url);
  }

  /**
   * Creates a Continuous Job
   */
  public postContinuousJob(
    firmwareId: string,
    sku: string
  ): Observable<string> {
    return this.http.post<string>(
      this.appConfig.getConfig().portalApiURL + "/jobs",
      {
        firmwareId,
        jobType: "continuous",
        skus: [sku],
      }
    );
  }

  /**
   * Creates a Snapshot Job
   */
  public postSnapshotJob(
    firmwareId: string,
    skus: string[],
    thingNames: string[]
  ): Observable<string> {
    return this.http.post<string>(
      this.appConfig.getConfig().portalApiURL + "/jobs",
      {
        firmwareId,
        jobType: "snapshot",
        skus,
        thingNames,
      }
    );
  }
}
