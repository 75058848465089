/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatDialog,
} from "@angular/material";

import { CountriesService } from "../countries.service";
import { CmsAddAvailableCountriesComponent } from "./add/add-available-countries.component";

@Component({
  selector: "app-available-countries",
  templateUrl: "./available-countries.component.html",
  styleUrls: ["./available-countries.component.scss"],
})
export class CmsAvailableCountriesComponent implements OnInit {
  entity;
  displayColumns: string[];
  dataSource: any;

  constructor(
    private countriesService: CountriesService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    // set table columns
    this.displayColumns = ["code", "name"];

    // get country data from server
    this.getCountryData();
  }

  getCountryData() {
    this.countriesService.getCountries().then((countries: any[]) => {
      this.dataSource = new MatTableDataSource(countries);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  add() {
    const dialogRef = this.dialog.open(CmsAddAvailableCountriesComponent, {
      width: "500px",
      data: {
        refreshGridFn: this.getCountryData.bind(this), // refresh data
        tag: null,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getCountryData();
      }
    });
  }
}
