/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { ToastHelperService } from "../../shared/utility/toast-helper.service";
import { DevicesService } from "./devices.service";
import { UserService } from "../users/users.service";
import { Device } from "../../core/models/devices/device.model";
import { User } from "../../core/models/users/user.model";
import { DownloadCertsDialogComponent } from "./components/download-certs.dialog";
import { finalize, tap, flatMap } from "rxjs/operators";

@Component({
  selector: "edit-device",
  templateUrl: "./edit-device.component.html",
})
export class EditDeviceComponent implements OnInit {
  public model: Device = new Device();
  public deviceUser: Object;
  public isEditing: boolean;
  public submitting: boolean;

  private errorMessage;

  constructor(
    private deviceService: DevicesService,
    private userService: UserService,
    private toastHelper: ToastHelperService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    let thingName = this.route.snapshot.params["thingName"];
    if (thingName) {
      this.deviceService
        .get(thingName)
        .pipe(
          tap((data) => (this.model = data)),
          flatMap((data) => this.userService.get(data.userId))
        )
        .subscribe((user) => (this.deviceUser = user));
    }
    this.isEditing = !!thingName;
  }

  public submit() {
    this.submitting = true;
    if (this.isEditing) {
      this.updateDevice();
    } else {
      this.createDevice();
    }
  }

  public deregister() {
    this.deviceService.deregister(this.model.thingName).subscribe(
      () =>
        this.onSuccess("The device has been deregistered successfully", [
          "../..",
        ]),
      (error) =>
        this.onError(error, "There was an error deregistering the device")
    );
  }

  private updateDevice() {
    this.deviceService
      .update(this.model)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe(
        () => this.onSuccess("The device has been saved successfully"),
        (error) => this.onError(error, "There was an error saving the device")
      );
  }

  private createDevice() {
    this.deviceService
      .register(this.model)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe(
        (data: any) => {
          this.dialog.open(DownloadCertsDialogComponent, {
            data: {
              thingName: this.model.thingName,
              certificateData: data.certificateData,
            },
          });
          this.onSuccess("The device has been registered successfully", [
            "..",
            this.model.thingName,
          ]);
        },
        (error) => this.onError(error, this.getErrorMessage(error))
      );
  }

  private onSuccess(message: string, route: any[] = [".."]) {
    this.router.navigate(route, { relativeTo: this.route });
    this.toastHelper.showSuccess(message);
  }

  private onError(error: any, message: string) {
    this.toastHelper.showError(error, message);
    this.errorMessage = <any>error;
  }

  private getErrorMessage(error: any) {
    if (error.status === 409) {
      return `A device named '${this.model.thingName}' already exists`;
    }
    return "There was an error registering the device";
  }
}
