/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Routes } from "@angular/router";

import { DevicesComponent } from "./devices.component";
import { DeviceDetailsComponent } from "./device-details.component";
import { EditDeviceComponent } from "./edit-device.component";
import { DeviceOverviewComponent } from "./components/device-overview.component";
import { ShadowEditorComponent } from "./components/shadow-editor.component";
import { ShadowComponent } from "./components/shadow.component";
import { DeviceMqttComponent } from "./components/device-mqtt.component";
import { EditTeamComponent } from "../teams/edit-team.component";
import { UserDetailsComponent } from "../users/user-details.component";
import { DeviceDocumentsComponent } from "./components/device-documents.component";

export const DevicesRoutes: Routes = [
  { path: "devices", component: DevicesComponent },
  { path: "devices/register", component: EditDeviceComponent },
  {
    path: "devices/:thingName",
    component: DeviceDetailsComponent,
    children: [
      { path: "", component: DeviceOverviewComponent },
      { path: "shadow", component: ShadowComponent },
      { path: "shadow/edit", component: ShadowEditorComponent },
      { path: "mqtt", component: DeviceMqttComponent },
      { path: "documents", component: DeviceDocumentsComponent },
      { path: "teams/register", component: EditTeamComponent },
    ],
  },
  { path: "devices/:thingName/edit", component: EditDeviceComponent },
];
