/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { SharedModule } from "../../shared/shared.module";

import { BuildMatrixService } from "./buildmatrix.service";

import { BuildmatrixComponent } from "./buildmatrix.component";

@NgModule({
  declarations: [BuildmatrixComponent],
  imports: [BrowserModule, SharedModule],
  exports: [],
  providers: [Title, BuildMatrixService],
  entryComponents: [],
})
export class BuildmatrixModule {}
