/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { WhitelistDeviceListModel } from "../../core/models/whitelist-devices/whitelist-device-list.model";
import { WhitelistDevice } from "../../core/models/whitelist-devices/whitelist-device.model";
import { AppConfigService } from "../../app.config.service";

@Injectable()
export class WhitelistDeviceService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  public delete(duid: string): Observable<any> {
    if (!duid) {
      throw "Cannot delete a device without a duid.";
    }

    const encodedDuid = encodeURIComponent(duid);
    return this.http.delete(
      this.appConfig.getConfig().portalApiURL + "/whitelist" + `/${encodedDuid}`
    );
  }

  public edit(whitelistDevice: WhitelistDevice): Observable<any> {
    if (!whitelistDevice) {
      throw "Cannot update a null device.";
    }

    if (!whitelistDevice.duid || !whitelistDevice.sku) {
      throw "DUID and SKU are required.";
    }

    whitelistDevice.serialNumber = whitelistDevice.serialNumber.trim();
    whitelistDevice.macAddress = whitelistDevice.macAddress.trim();

    const encodedMac = encodeURIComponent(whitelistDevice.macAddress);
    return this.http.put(
      this.appConfig.getConfig().portalApiURL +
        "/whitelist" +
        "/" +
        whitelistDevice.duid,
      whitelistDevice
    );
  }

  public getAll(
    lastEvaluatedKey: string,
    pagesize: string,
    searchString: string
  ): Observable<WhitelistDeviceListModel> {
    let params = new HttpParams();
    if (lastEvaluatedKey) {
      params = params.append("lastEvaluatedKey", lastEvaluatedKey);
    }
    if (pagesize) {
      params = params.append("pageSize", pagesize);
    }
    if (searchString) {
      params = params.append("searchString", searchString);
    }
    return this.http.get<WhitelistDeviceListModel>(
      this.appConfig.getConfig().portalApiURL + "/whitelist",
      { params }
    );
  }
}
