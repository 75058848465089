/**
 * @copyright Copyright 2021, BISSELL Homecare, Inc.
 * All Rights Reserved.
 *
 * This is UNPUBLISHED PROPRIETARY SOURCE CODE of BISSELL Homecare, Inc.
 * the contents of this file may not be disclosed to third parties, copied
 * or duplicated in any form, in whole or in part, without the prior
 * written permission of BISSELL Homecare, Inc.
 */

import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BhiProductsService } from "./bhi_products.service";
import {
  MatTabGroup,
  MatTabHeader,
  MatTab,
  MatDialog,
} from "@angular/material";
import { UnsavedChangesConfirmDialogComponent } from "../../../shared/dialogs/unsaved-changes-confirm.dialog";
import { ActiveTabService } from "./active-tab.service";

@Component({
  selector: "app-cms-edit-products",
  templateUrl: "./edit-product.component.html",
})
export class CmsEditProductComponent implements AfterViewInit, OnInit {
  sku: string;
  product: any;
  loadedData: any[];
  @ViewChild("productEditTabs") productEditTabs: MatTabGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public bhiProductsService: BhiProductsService,
    private dialog: MatDialog,
    private activeTabService: ActiveTabService
  ) {}

  public ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      // determine if creating a new label or editing an existing one
      this.sku = params["sku"];

      // get data from server
      this.product = this.sku || "<NEW SKU>";
    });
  }

  ngAfterViewInit() {
    this.productEditTabs._handleClick = this.interceptTabChange.bind(this);
  }

  interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    const dialogRef = this.dialog.open(UnsavedChangesConfirmDialogComponent, {
      data: {
        message:
          "Any unsaved changes to this tab will be lost. Are you sure you want to load new tab?",
        title: "Confirm Tab Change",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "save") {
        if (this.activeTabService.save()) {
          return MatTabGroup.prototype._handleClick.apply(
            this.productEditTabs,
            [tab, tabHeader, idx]
          );
        }
        return false;
      } else if (result === "discard") {
        return MatTabGroup.prototype._handleClick.apply(this.productEditTabs, [
          tab,
          tabHeader,
          idx,
        ]);
      }
      return false;
    });
  }

  public cancel() {
    this.router.navigate(["dashboard/cms/products"]);
  }
}
